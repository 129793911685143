import filter   from 'lodash/filter';
import map      from 'lodash/map';
import ObjectId from 'objectid';
import { Map } from 'immutable';

const initialState = {
	keyword      : '',
	newFollowups : [],
	newAttachments : [],
	data : Map({
		byTray: Map(),
		byTask: Map()
	}),
	loading : Map(),
	initialized : Map()
};

export default (state = initialState, action) => {
	let { type, payload = {} } = action;
	let { projectID, searchKey, type: detailType, data, followupID, followup, keyword, issueRef, attachments } = payload;
	var timestamp;
	let dataType = detailType == 'task' ? 'byTask' : 'byTray';
	switch (type) {
	case 'GET_DETAIL':
		return { ...state, loading: state.loading.setIn([projectID, dataType, searchKey], true) };
	case 'GET_DETAIL_SUCCESS':
		return {
			...state,
			loading: state.loading.setIn([projectID, dataType, searchKey], false),
			initialized: state.initialized.setIn([projectID, dataType, searchKey], true),
			data: state.data.setIn([projectID, dataType, searchKey], data)
		};
	case 'GET_DETAIL_FAILURE':
		return { ...state, loading: state.loading.setIn([projectID, dataType, searchKey], false) };
	case 'UPDATE_FOLLOWUP_KEYWORD':
		return { ...state, keyword : keyword };
	case 'CREATE_FOLLOWUP':
		var newFollowupID = ObjectId().toString();
		timestamp = new Date(parseInt(newFollowupID.substring(0, 8), 16) * 1000);
		return { ...state, newFollowups : [...state.newFollowups, {
			_id : newFollowupID,
			followupTo : issueRef,
			createdAt : timestamp,
			status : 'await_inspect',
			approved : false
		}]};
	case 'CREATE_ATTACHMENTS':
		var newAttachmentID = ObjectId().toString();
		timestamp = new Date(parseInt(newAttachmentID.substring(0, 8), 16) * 1000);
		return {
			...state,
			newAttachments : [
				...state.newAttachments,
				...map(attachments, attachment => ({
					_id : newAttachmentID,
					createdAt : timestamp,
					data : attachment.data,
					mimeType : attachment.type,
					name : attachment.name,
					followupID : followupID
				}))
			]
		};
	case 'UPDATE_FOLLOWUP':
		return { ...state, newFollowups : map(state.newFollowups, newFollowup => {
			if (newFollowup._id != followup._id)
				return newFollowup;
			return followup;
		})};
	case 'SUBMIT_FOLLOWUP':
		return {
			...state,
			newFollowups : filter(state.newFollowups, { _id : followupID }),
			newAttachments: filter(state.newAttachments, { followupID }),
			initialized: state.initialized.setIn([projectID, dataType, searchKey], true),
			data: state.data.updateIn([projectID, dataType, searchKey], data => ({
				...data,
				issues: data.issues.map(doc => {
					if (doc.issueRef == followup.followupTo)
						doc.followups.unshift(followup);
					return doc;
				})
			}))
		};
	default:
		return state;
	}
};
