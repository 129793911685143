import {
	blueGrey700, grey200, blueGrey100
} from 'material-ui/styles/colors';

export default {
	chipStyle : {
		margin          : '4px',
		fontSize        : '13px',
		backgroundColor : grey200,
		alignItems      : 'center',
		padding         : '2px'
	},
	textCtn : {
		overflow     : 'hidden',
		textOverflow : 'ellipsis',
		lineHeight   : '24px',
		maxWidth     : '390px',
		margin       : 0,
		color        : blueGrey700
	},
	wrapperStyle : {
		display    : 'flex',
		alignItems : 'center',
		flexWrap   : 'wrap',
		width      : 'max-content',
		maxWidth   : '900px'
	},
	objectValueCtn : {
		display        : 'flex',
		justifyContent : 'center',
		flexDirection  : 'column'
	},
	titleText : {
		fontSize   : '12px',
		lineHeight : '16px'
	},
	subContent : {
		lineHeight : '24px',
		marginLeft : '8px',
	},
	collectionHeader : {
		backgroundColor : blueGrey100,
		color    : blueGrey700,
		position : 'relative',
		border   : '1px solid #dddddd',
		fontSize : '12px',
		padding  : '2px 12px',
		margin   : '0'
	},
	collectionCell : {
		position : 'relative',
		border   : '1px solid #dddddd',
		fontSize : '12px',
		padding  : '2px 12px',
		margin   : '0'
	},
	tableWrapperStyle : {
		position  : 'relative',
		overflowX : 'auto',
		margin   : '2px -8px'
	},
	tableStyle : {
		tableLayout : 'auto',
		width       : '100%',
		borderColor : blueGrey700,
		overflowX   : 'auto',
	},
	tableBodyStyle : {
		overflowX : 'auto'
	},

};
