import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Loading from './loadingIndicator';

export default Component => {

	class LoadableCompoment extends PureComponent {
		static propTypes = {
			initialized : PropTypes.bool.isRequired,
			loading : PropTypes.bool.isRequired,
			projectID : PropTypes.string.isRequired,
			getData : PropTypes.func.isRequired
		}
		static displayName = `Loadable(${Component.displayName})`

		constructor(props) {
			super(props);
			this.props.getData(this.props);
		}

		componentWillReceiveProps(nextProps) {
			let { initialized } = nextProps;
			if (!initialized && !nextProps.loading)
				this.props.getData(nextProps);
		}

		render() {
			let { loading, initialized, ...otherProps } = this.props;
			return loading || !initialized
				? <Loading />
				: <Component { ...otherProps} />;
		}
	}

	return LoadableCompoment;
};
