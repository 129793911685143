import React from 'react';
import PropTypes from 'prop-types';
import { teal700 } from 'material-ui/styles/colors';

const styles = {
	top : props => ({ position : 'absolute', top : 0, left : 0, width : props.width, height : 3, backgroundColor : teal700 }),
	bottom : props => ({ position : 'absolute', bottom : 0, left : 0, width : props.width, height : 3, backgroundColor : teal700 }),
	left : props => ({ position : 'absolute', top : 0, left : 0, width : 3, height : props.height, backgroundColor : teal700 }),
	right : props => ({ position : 'absolute', top : 0, right : 0, width : 3, height : props.height, backgroundColor : teal700 })
};

export default function SelectedIndicator(props) {
	return <div style={styles[props.position](props)} />;
}

SelectedIndicator.propTypes = {
	height : PropTypes.number.isRequired,
	width : PropTypes.number.isRequired,
	position : PropTypes.oneOf(['top', 'bottom', 'right', 'left']).isRequired
};
