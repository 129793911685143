import { put, call, takeLatest, cancelled } from 'redux-saga/effects';
import callAPI        from './callAPI';

function* getNotification(action) {
	let { projectID, dateLimit } = action.payload;
	try {
		let urlQuery = dateLimit ? { dateLimit } : {};
		let url = `/api/projects/${projectID}/summary/notification`;
		let { data } = yield call(callAPI, 'GET', url, urlQuery);

		yield put(new action.SUCCESS({ projectID, data, dateLimit }));
	} catch(err) {
		yield put(new action.FAILURE({ projectID, dateLimit, err }));
		throw err;
	} finally {
		if (yield cancelled()) {
			yield put(new action.FAILURE({ projectID, dateLimit }));
		}
	}
}

export default function* notificationSaga() {
	yield takeLatest('GET_NOTIFICATION', getNotification);
}
