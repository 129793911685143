import { combineReducers } from 'redux';

import path              from './path';
import user              from './user';
import currProject       from './currProject';
import importState       from './importState';
import projectSummary    from './projectSummary';
import projectSummaryNew from './projectSummaryNew';
import notification      from './notification';
import taskDetail        from './taskDetail';
import detail            from './detail';

import scenes  from './scenes';

export default combineReducers({
	path,
	currProject,
	importState,
	user,
	scenes,
	projectSummary,
	projectSummaryNew,
	taskDetail,
	detail,
	notification
});
