import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import immutableSelector from 'utils/immutableSelector';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import queryString from 'query-string';
import { push } from 'react-router-redux';

import {
	SELECT_PROJECT, TOGGLE_NAVBAR, GET_DATA, LOGOUT
} from '../actions';

// MUI IconButton bug hack
const dummy = () => {};
const BREAK_POINT = 996;

const mapStateToProps = immutableSelector(
	state => state.app.currProject,
	state => state.ui.general.width,
	state => state.data.getIn(['adminDB', 'projects']).filter(project => project.get('deleted') != true),
	(currProject, width, projects) => ({currProject, width, projects : projects.sortBy(project => new Date(project.get('createdAt'))).toList().toJS()})
);
const mapDispatchToProps = (dispatch, { location }) => ({
	selectProject : projectID => {
		dispatch(new SELECT_PROJECT({ projectID }));
		dispatch(push({
			pathname : location.pathname,
			search : '?' + queryString.stringify({ projectID })
		}));
	},
	toggleNavBar  : () => dispatch(new TOGGLE_NAVBAR()),
	getProjects   : () => dispatch(new GET_DATA({ model : 'projects' })),
	logout        : () => dispatch(new LOGOUT())
});

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class ToolBar extends PureComponent {
	static propTypes = {
		width          : PropTypes.number,
		projects       : PropTypes.array.isRequired,
		currProject    : PropTypes.string.isRequired,
		location       : PropTypes.object,
		selectProject  : PropTypes.func.isRequired,
		toggleNavBar   : PropTypes.func.isRequired,
		getProjects    : PropTypes.func.isRequired,
		logout         : PropTypes.func.isRequired,
	}

	componentWillMount() {
		this.props.getProjects();
		let { projectID } = queryString.parse(this.props.location.search);
		if (projectID && projectID != this.props.currProject)
			this.props.selectProject(projectID);
	}

	renderProjectList() {
		return map(this.props.projects, project => {
			return (
				<MenuItem key={`projOpt:${project._id}`} value={project._id} primaryText={project.name}/>
			);
		});
	}

	render() {
		return (
			<Toolbar>
				<ToolbarGroup firstChild={true}>
					{
						this.props.width > BREAK_POINT
							? null
							: (
								<IconButton
									onTouchTap={this.props.toggleNavBar}
									onTouchStart={dummy}>
									<FontIcon
										color="white"
										className="material-icons">
									menu
									</FontIcon>
								</IconButton>
							)
					}
					<DropDownMenu
						labelStyle={{color : 'white'}}
						value={this.props.currProject}
						onChange={(e, i, v) => this.props.selectProject(v)}>
						<MenuItem value={''} primaryText="Pick a Project"/>
						{ this.renderProjectList() }
					</DropDownMenu>
				</ToolbarGroup>
				<ToolbarGroup lastChild={true}>
					<FlatButton
						label="登出"
						labelStyle={{ color : 'white' }}
						onTouchTap={this.props.logout}
						icon={<FontIcon color="#FFFFFF" className="material-icons">exit_to_app</FontIcon>}
					/>
				</ToolbarGroup>
			</Toolbar>
		);
	}
}
