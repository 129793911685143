import some from 'lodash/some';
import { put, call, apply } from 'redux-saga/effects';
import fetch from 'isomorphic-fetch';
import configs from '../../configs';
import {
	GET_TOKEN, LOGOUT
} from '../actions';

export default function* getToken() {
	try {
		let token = localStorage.getItem('token');
		if (!token)
			throw new Error('請先登入');
		let response = yield call(fetch, `${configs.URL_ROOT}/token`, {
			method : 'GET',
			headers : {
				Authorization : `Bearer ${token}`
			}
		});

		let payload = yield apply(response, response.json);
		if (!response.ok)
			throw payload;

		localStorage.setItem('token', payload.token);

		let user = payload.user;

		let isAdmin = some(user.roles, role => {
			return role.role.name == 'system_admin';
		});

		let isManager = some(user.roles, role => {
			return role.role.name == 'manager';
		});

		let isMonitor = some(user.roles, role => {
			return role.role.name == 'monitor';
		});

		if (!isManager && !isAdmin && !isMonitor)
			throw new Error('您沒有權限使用監控平台');

		yield put(new GET_TOKEN.SUCCESS({ user, token, isAdmin }));

	} catch(err) {
		yield put(new LOGOUT());
		//yield put(new SHOW_ERROR({ errMsg : err.message || err.name || 'UNEXPECTED ERROR', cb : () => window.location.assign('http://localhost:4321/login') }));
	}
}
