import React from 'react';
import styles from './styles';

import CollectionTable from '../collectionTable';
import {
	Card, CardTitle, CardText
} from 'material-ui/Card';

export default function FollowupManagement() {
	return (
		<Card style={styles.main} containerStyle={styles.mainContainer}>
			<CardTitle
				title="事故跟進"
				subtitle="Issue Follow-ups"
			/>
			<CardText style={styles.table}>
				<CollectionTable
					sceneName="followups"
					model="followups"
					projectSpecific={true}
				/>
			</CardText>
		</Card>
	);
}
