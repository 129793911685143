
export default {
	container : {
		display       : 'flex',
		flex          : 1,
		flexDirection : 'column',
		alignItems    : 'center',
		marginTop     : '20px',
		overflowY     : 'scroll'
	},
	headerCtn : {
		display       : 'flex',
		flexDirection : 'column',
		alignItems    : 'stretch',
		maxWidth      : '800px',
		width         : '100%'
	},
	header : {

	},
	main : {
		display       : 'flex',
		flexDirection : 'column',
		alignItems    : 'stretch',
		maxWidth      : '800px',
		width         : '100%'
	},
	menu : {
		display       : 'flex',
		flexDirection : 'column',
		alignItems    : 'stretch',
	},
	menuItem : {
		// display       : 'flex',
		// flexDirection : 'column',
		// // maxWidth      : '800px',
		margin        : '12px 0',
		padding       : '8px',
		// width         : '100%'
	},
	menuItemHeader : {
		display : 'flex',
		padding         : '8px',
		borderRadius    : '4px',
		flexDirection   : 'row',
		alignItems      : 'center',
		flex            : 1
	},
	menuItemBody : {
		display : 'flex',
		padding : '4px',
	},
	menuHeaderLabel : {
		fontSize : '22px',
		fontWeight : '400',
		color    : 'rgba(0,0,0,.87)'
	},
	menuItemCtrl : {
		display : 'flex',
		flexDirection : 'row',
		marginLeft : '8px'
	},
	menuItemPlaceholder : {
		flex : 1
	},
	filenameCtn : {
		display : 'flex',
		padding         : '8px',
		borderRadius    : '4px',
		//marginVertical  : '0.4rem',
		flexDirection   : 'row',
		alignItems      : 'center',
		flex            : 1
	},
	extraFieldsCtn : {
		backgroundColor : 'rgba(0,0,0,.03)',
		borderRadius : '4px'
	},
	extraFieldCtn : {
		display : 'flex',
		padding         : '8px',
		borderRadius    : '4px',
		//marginVertical  : '0.4rem',
		flexDirection   : 'row',
		alignItems      : 'center',
		flex            : 1
	},
	menuItemLabel : {
		fontSize : '22px'
	},
	loading : {
		alignItems     : 'center',
		justifyContent : 'center',
		padding        : '8px',
		height         : '80px'
	},
	buttonLabel : {
		maxWidth : '110px'
	},
	button : {
		marginLeft : '3px',
		elevation  : 0
	},
	textInput : {
		width : '170px',
		fontSize : '18px',
		textAlign : 'center'
	}

};
