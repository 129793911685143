const initState = {
	loading : false,
	// success : false,
	// loaded  : false
};

export default (state = initState, action) => {
	let { type } = action;
	switch (type) {
	case 'UPLOAD_CSV':
		return { ...state, loading : true };
	case 'UPLOAD_CSV_SUCCESS':
		return { ...state, loading : false };
	case 'UPLOAD_CSV_FAILURE':
		return { ...state, loading : false };
	default:
		return state;
	}
};
