export default {
	importBtn : {
		cursor   : 'pointer',
		position : 'absolute',
		top      : 0,
		bottom   : 0,
		right    : 0,
		left     : 0,
		width    : '100%',
		opacity  : 0
	},
	main : {
		margin : '16px',
		display : 'flex',
		flexDirection : 'column',
		flex : 1,
	},
	mainContainer : {
		flex : 1,
		display : 'flex',
		flexDirection : 'column'
	},
	table : {
		flex : 1,
		display : 'flex',
		flexDirection : 'column'
	}
};
