import Home              from './modules/home';
import UserTable         from './modules/tables/users';
import ProjectTable      from './modules/tables/projects';
import RoleTable         from './modules/tables/roles';
// import ModuleTable       from './modules/tables/modules';
import MemberTable       from './modules/tables/members';
import TaskTable         from './modules/tables/tasks';
import WordTable         from './modules/tables/words';
// import ReportTable       from './modules/tables/reports';
import IssueTable        from './modules/tables/issues';
import FollowupTable     from './modules/tables/followups';
import AttachmentTable   from './modules/tables/attachments';
import TemplateTable     from './modules/tables/templates';
import ProjectSummary    from './modules/projectSummary';
import NewProjectSummary from './modules/projectSummary.new';
import TaskDetail        from './modules/taskDetail';
import Detail            from './modules/Detail';
import Notification      from './modules/notification';
import NewNotification   from './modules/notification.new';
import ReportExport      from './modules/reportExport';

import NotFound          from './modules/notFound';

export const routes = [{
	path      : '/home',
	labelName : '主頁',
	iconName  : 'home',
	component : Home
}, {
	path       : '/notification',
	link       : ({projectID}) => projectID ? `/notification?projectID=${projectID}` : '/notification',
	labelName  : '通知',
	iconName   : 'notifications',
	allowRoles : ['system_admin', 'manager', 'monitor'],
	component  : Notification
}, {
	path       : '/newNotification',
	link       : ({projectID}) => projectID ? `/newNotification?projectID=${projectID}` : '/newNotification',
	labelName  : '通知(new)',
	iconName   : 'notifications',
	allowRoles : ['system_admin', 'manager', 'monitor'],
	component  : NewNotification
}, {
	path      : '/projectSummary',
	link      : ({projectID}) => projectID ? `/projectSummary?projectID=${projectID}` : '/projectSummary',
	labelName : '項目監察',
	iconName  : 'dashboard',
	component : ProjectSummary,
	allowRoles : ['system_admin', 'manager', 'monitor']
}, {
	path      : '/newProjectSummary',
	link      : ({projectID}) => projectID ? `/newProjectSummary?projectID=${projectID}` : '/newProjectSummary',
	labelName : '項目監察(new)',
	iconName  : 'dashboard',
	component : NewProjectSummary,
	allowRoles : ['system_admin', 'manager', 'monitor']
}, {
	path      : '/reportExport',
	link      : ({projectID}) => projectID ? `/reportExport?projectID=${projectID}` : '/reportExport',
	labelName : '匯出報告',
	iconName  : 'cloud_download',
	component : ReportExport,
	allowRoles : ['system_admin', 'manager', 'monitor']
}, {
	path      : '/users',
	labelName : '使用者管理',
	iconName  : 'account_box',
	component : UserTable,
	allowRoles : ['system_admin']
}, {
	path      : '/roles',
	labelName : '用戶組管理',
	iconName  : 'verified_user',
	component : RoleTable,
	allowRoles : ['system_admin']
}, {
	path      : '/projects',
	labelName : '項目管理',
	iconName  : 'group_work',
	component : ProjectTable,
	allowRoles : ['system_admin']
}, {
	path      : '/members',
	link      : ({projectID}) => projectID ? `/members?projectID=${projectID}` : '/members',
	labelName : '成員管理',
	iconName  : 'group',
	component : MemberTable,
	allowRoles : ['system_admin', 'manager']
}, {
	path      : '/tasks',
	link      : ({projectID}) => projectID ? `/tasks?projectID=${projectID}` : '/tasks',
	labelName : '工作管理',
	iconName  : 'work',
	component : TaskTable,
	allowRoles : ['system_admin', 'manager']
}, {
	path      : '/words',
	link      : ({projectID}) => projectID ? `/words?projectID=${projectID}` : '/words',
	labelName : '字庫管理',
	iconName  : 'translate',
	component : WordTable,
	allowRoles : ['system_admin', 'manager']
}, {
	path      : '/issues',
	link      : ({projectID}) => projectID ? `/issues?projectID=${projectID}` : '/issues',
	labelName : '事故列表',
	iconName  : 'list',
	component : IssueTable,
	allowRoles : ['system_admin']
}, {
	path      : '/followups',
	link      : ({projectID}) => projectID ? `/followups?projectID=${projectID}` : '/followups',
	labelName : '事故跟進',
	iconName  : 'build',
	component : FollowupTable,
	allowRoles : ['system_admin']
}, {
	path      : '/attachments',
	link      : ({projectID}) => projectID ? `/attachments?projectID=${projectID}` : '/attachments',
	labelName : '附件管理',
	iconName  : 'attachment',
	component : AttachmentTable,
	allowRoles : ['system_admin']
}, {
	path      : '/templates',
	link      : ({projectID}) => projectID ? `/templates?projectID=${projectID}` : '/templates',
	labelName : '模板管理',
	iconName  : 'receipt',
	component : TemplateTable,
	allowRoles : ['system_admin', 'manager']
}];

export default [ ...routes,  {
	path : '/taskDetail/projects/:projectID/tasks/:taskID',
	component : TaskDetail
}, {
	path: '/detail/projects/:projectID/:type/:searchKey',
	component: Detail
}, {
	path      : '*',
	component : NotFound
}];
