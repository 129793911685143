import { blueGrey900 } from 'material-ui/styles/colors';

export default {
	container : {
		display : 'flex',
		flexDirection : 'column',
		alignItems : 'stretch',
		maxWidth : '600px',
		margin : '8px',
		backgroundColor : blueGrey900,
	},
	title : {
		fontSize : '24px',
		fontWeight : 'bold',
		color : 'rgba(255,255,255,.87)'
	},
	subTitle : {
		color : 'rgba(255,255,255,.69)'
	},
	rolesCtn : {
		display : 'flex',
		flexDirection : 'row',
		flexWrap : 'wrap'
	},
	roleCtn : {
		display : 'flex',
		backgroundColor : 'rgba(255,255,255,.87)',
		padding : '8px',
		margin : '3px',
		flexGrow : 1,
		borderRadius : '12px',
		color : 'rgba(0,0,0,.63)'
	},
	placeholder : {
		height : 1,
		flex : 99
	}

};
