import ObjectId from 'objectid';

import { NUMBER_GETTER } from 'commons/updatable/getters';
import { FilePresenter } from 'commons/updatable/presenters';
import { NUMBER_SETTER } from 'commons/updatable/setters';
import { FileEditor } from 'commons/updatable/editors';

import store  from '../../../dashboard/store';

export default {
	paths : [{
		key : '_id',
		name : 'ID',
		type : 'string',
		show : true,
		initializer : () => ObjectId().toString()
	}, {
		key  : 'name',
		type : 'string',
		name : '名稱',
		editable : true
	}, {
		key  : 'mimeType',
		type : 'string',
		name : '類型',
		creatable : true,
		editable : false
	}, {
		key  : 'size',
		type : 'number',
		name : '大小',
		setter : NUMBER_SETTER,
		getter : NUMBER_GETTER,
		editable : false
	}, {
		key  : 'file',
		type : 'file',
		name : '下載附件',
		editable : false,
		setter : (v, record) => {
			return {...record, data : v.data, name : v.name, mimeType : v.type };
		},
		getter : (v, record) => {
			let state = store.getState();
			// let urlparts = (record.uri || '').split('/');
			return {
				url : record.uri,
				name : record.name,
				auth : {
					Authorization : 'Bearer '+ state.app.user.token
				},
				mimeType : record.mimeType
			};
		},
		presenter : FilePresenter,
		editor : FileEditor,
		// filenameField : 'name',
		// fileDataField : 'data',
		// fileTypeField : 'mimeType',
		// fileUriField : 'uri',
		creatable : true,
		// getFileName : record => `${record.name}`,
		// getToken : user => user.token,
	}, {
		key  : 'updatedAt',
		type : 'string',
		name : '上次修改',
		editable : false
	}],
	keyPath : '_id'
};
