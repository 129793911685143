const initState = {
	loading  : true,
	token    : '',
	username : '',
	name     : '',
	roles    : [],
	isAdmin  : false,
	imageToken : ''
};

export default (state = initState, {type, payload}) => {
	switch (type) {
	case 'GET_TOKEN_SUCCESS':
		return { ...state, ...payload.user, token : payload.token, isAdmin : payload.isAdmin, loading : false };
	default:
		return state;
	}
};
