import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import TextField from 'material-ui/TextField';

import { ENTER_FILENAME } from '../actions';
import styles from './styles';

const mapStateToProps = createSelector(
	(state, { projectID, templateID }) => state.ui.reportExport.getIn([projectID, templateID, 'filename']) || '',
	(filename) => ({ filename })
);

const mapDispatchToProps = (dispatch, { projectID, templateID }) => ({
	enterFilename : ({ target : { value : filename }}) => dispatch(new ENTER_FILENAME({ projectID, templateID, filename }))
});


@connect(mapStateToProps, mapDispatchToProps)
export default class FilenameInput extends PureComponent {
	static propTypes = {
		templateID    : PropTypes.string.isRequired,
		projectID     : PropTypes.string.isRequired,
		enterFilename : PropTypes.func.isRequired,
		filename      : PropTypes.string.isRequired
	}

	render() {
		return (
			<div style={styles.filenameCtn}>
				<div style={styles.menuItemLabel}>檔案名稱</div>
				<div style={styles.menuItemPlaceholder}></div>
				<div style={styles.menuItemCtrl}>
					<TextField
						id={new Date().getTime().toString()}
						style={styles.textInput}
						value={this.props.filename}
						onChange={this.props.enterFilename}
					/>
				</div>
			</div>
		);
	}
}
