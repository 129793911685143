import {
	blueGrey700
} from 'material-ui/styles/colors';

export default {
	container : {
		backgroundColor : blueGrey700,
		color : 'rgba(255,255,255,.68)'
	}
};
