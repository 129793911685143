import ActionCreatorBuilder from 'utils/actionBuilder';

const formatGetDataPayload = payload => {
	payload.models = payload.models || [{ model : payload.model, projectID : payload.projectID }];
	return { payload };
};

export const UPDATE_WIDTH       = new ActionCreatorBuilder().type('UPDATE_WIDTH').build();

export const GO_TO              = new ActionCreatorBuilder().type('GO_TO').build();
export const LOGOUT             = new ActionCreatorBuilder().type('LOGOUT').build();
export const TOGGLE_NAVBAR      = new ActionCreatorBuilder().type('TOGGLE_NAVBAR').build();

export const SELECT_PROJECT     = new ActionCreatorBuilder().type('SELECT_PROJECT').build();
export const UPDATE_GROUP       = new ActionCreatorBuilder().type('UPDATE_GROUP').build();

export const HIDE_ERROR         = new ActionCreatorBuilder().type('HIDE_ERROR').build();
export const SHOW_ERROR         = new ActionCreatorBuilder().type('SHOW_ERROR').build();

export const GET_TOKEN          = new ActionCreatorBuilder().type('GET_TOKEN').isAsync().build();

export const INIT_DATABASE      = new ActionCreatorBuilder().type('INIT_DATABASE').isAsync().build();

export const INIT_DATA          = new ActionCreatorBuilder().type('INIT_WORDS').isAsync().build();
export const GET_DATA           = new ActionCreatorBuilder().type('GET_DATA').isAsync().factory(formatGetDataPayload).build();
export const UPDATE_DATA        = new ActionCreatorBuilder().type('UPDATE_DATA').isAsync().build();
export const CREATE_DATA        = new ActionCreatorBuilder().type('CREATE_DATA').isAsync().build();
export const REMOVE_DATA        = new ActionCreatorBuilder().type('REMOVE_DATA').isAsync().build();

export const GET_SUMMARY        = new ActionCreatorBuilder().type('GET_SUMMARY').isAsync().build();
export const GET_NOTIFICATION   = new ActionCreatorBuilder().type('GET_NOTIFICATION').isAsync().build();
export const GET_DETAIL         = new ActionCreatorBuilder().type('GET_DETAIL').isAsync().build();

export const UPDATE_FOLLOWUP_KEYWORD = new ActionCreatorBuilder().type('UPDATE_FOLLOWUP_KEYWORD').build();
export const CREATE_FOLLOWUP    = new ActionCreatorBuilder().type('CREATE_FOLLOWUP').build();
export const UPDATE_FOLLOWUP    = new ActionCreatorBuilder().type('UPDATE_FOLLOWUP').build();
export const SUBMIT_FOLLOWUP    = new ActionCreatorBuilder().type('SUBMIT_FOLLOWUP').build();
export const CREATE_ATTACHMENTS = new ActionCreatorBuilder().type('CREATE_ATTACHMENTS').build();


export const IMPORT_VIEW        = new ActionCreatorBuilder().type('IMPORT_VIEW').build();
export const IMPORT_VIEW_ASYNC  = new ActionCreatorBuilder().type('IMPORT_VIEW_ASYNC').isAsync().build();

export const UPLOAD_CSV         = new ActionCreatorBuilder().type('UPLOAD_CSV').isAsync().build();

export const CHANGE_DATE        = new ActionCreatorBuilder().type('CHANGE_DATE').build();

export const START_EXPORT_REPORT = new ActionCreatorBuilder().type('START_EXPORT_REPORT').build();
export const EXPAND_TEMPLATE     = new ActionCreatorBuilder().type('EXPAND_TEMPLATE').build();
export const CLOSE_TEMPLATE      = new ActionCreatorBuilder().type('CLOSE_TEMPLATE').build();
export const UPDATE_EXTRA_FIELD  = new ActionCreatorBuilder().type('UPDATE_EXTRA_FIELD').build();
export const EXPORT_REPORT       = new ActionCreatorBuilder().type('EXPORT_REPORT').isAsync().build();
export const ENTER_FILENAME      = new ActionCreatorBuilder().type('ENTER_FILENAME').build();

export const UPDATE_NOTI_LIMIT   = new ActionCreatorBuilder().type('UPDATE_NOTI_LIMIT').build();
