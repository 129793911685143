import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import forEach            from 'lodash/forEach';
import map                from 'lodash/map';
import filter             from 'lodash/filter';
import some               from 'lodash/some';
import indexOf            from 'lodash/indexOf';
import keys               from 'lodash/keys';
import _                  from 'lodash';
import FontIcon           from 'material-ui/FontIcon';
import IconButton         from 'material-ui/IconButton';
import {
	Card,
	CardTitle,
	CardText,
} from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import TextField  from 'material-ui/TextField';
import { ListItem, List } from 'material-ui/List';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import {
	GET_DETAIL,
	UPDATE_DATA,
	CREATE_DATA,
	UPDATE_FOLLOWUP,
	CREATE_FOLLOWUP,
	SUBMIT_FOLLOWUP,
	CREATE_ATTACHMENTS,
	UPDATE_FOLLOWUP_KEYWORD
} from '../actions';

import immutableSelector from 'utils/immutableSelector';
import Loadable        from 'commons/loadable';

import styles          from './Detail.styles';

import { readPromise } from 'utils/fileUploadHelper';

const statusLabels = {
	await_repair : '未執修',
	await_inspect : '已執修&待檢查',
	await_accept : '已檢查&待接受',
	accepted : '接受'
};

const statuses = {
	'await_repair' : ['manager', 'inspector'],
	'await_inspect' : ['manager', 'contractor'],
	'await_accept' : ['manager', 'inspector'],
	'accepted' : ['manager', 'property_owner']
};

const dataType = {
	tray : 'byTray',
	task : 'byTask'
};

const mapLoadableState = createSelector(
	(state, { match: { params: { projectID, type, searchKey } } }) => state.app.detail.loading.getIn([projectID, dataType[type], searchKey]),
	(state, { match: { params: { projectID, type, searchKey } } }) => state.app.detail.initialized.getIn([projectID, dataType[type], searchKey]),
	(state, { match : { params : { projectID }}}) => projectID,
	(state, { match: { params: { type } } }) => type,
	(state, { match: { params: { searchKey } } }) => searchKey,
	(loading = false, initialized = false, projectID, type, searchKey) => ({ loading, initialized, projectID, type, searchKey })
);

const mapDispatchToGetData = dispatch => ({
	getData : ({ projectID, type, searchKey }) => dispatch(
		new GET_DETAIL({ projectID, type, searchKey })
	),
});

const mapStateToProps = immutableSelector(
	state              => state.app.user._id,
	state              => state.app.user.imageToken,
	state              => state.app.user.roles,
	(state, { match }) => match.params.projectID,
	(state, { match }) => match.params.searchKey,
	(state, { match }) => match.params.type,
	(state, { match: { params: { projectID, searchKey, type = 'task' } } }) => state.app.detail.data.getIn([
		projectID,
		type == 'task'
			? 'byTask'
			: 'byTray',
		searchKey
	]),
	state => state.app.detail.keyword,
	state => state.app.detail.newFollowups,
	state => state.app.detail.newAttachments,
	(
		userID = '', imageToken = '', roles = [], projectID, searchKey, type,
		task, keyword, newFollowups, newAttachments
	) => {
		let availableStatus = filter(keys(statusLabels), status => some(roles, ({ role, scope }) => {
			return role.name == 'system_admin' || (~indexOf(statuses[status], role.name) && scope.projectID == projectID);
		}));

		return {
			task,
			issues: _.sortBy(_.filter(task.issues, issue => {
				return new RegExp(keyword, 'g').test(issue.issueSentence) ||
					(type == 'tray' && new RegExp(keyword, 'g').test(issue.addressTo.name));
			}), type == 'tray' ? 'addressTo.name' : 'createdAt'),
			projectID,
			searchKey,
			availableStatus,
			userID,
			imageToken,
			keyword,
			newFollowups,
			newAttachments
		};
	}
);

const mapDispatchToProps = (dispatch, { match: { params: { projectID, type, searchKey }} }) => ({
	dispatch       : dispatch,
	updateFollowup : followup => dispatch(new UPDATE_DATA({ followup, projectID, model : 'followups', docName : 'followup' })),
	updateNewFollowup : followup => dispatch(new UPDATE_FOLLOWUP({ followup })),
	createFollowup : issueRef => dispatch(new CREATE_FOLLOWUP({ issueRef })),
	createAttachments : (attachments, followupID) => dispatch(new CREATE_ATTACHMENTS({ attachments, followupID })),
	updateKeyword : keyword => dispatch(new UPDATE_FOLLOWUP_KEYWORD({ keyword })),
	uploadFollowup : (followup, newAttachments) => {
		let attachments = filter(newAttachments, { followupID : followup._id });
		forEach(attachments, attachment => dispatch(new CREATE_DATA({ record : attachment, projectID, model : 'attachments'})));
		dispatch(new CREATE_DATA({ record : { ...followup, attachments : map(attachments, '_id') }, projectID, model : 'followups' }));
		dispatch(new SUBMIT_FOLLOWUP({ followup, projectID, type, searchKey }));
	}
});

// @ProjectsEnsured
@connect(mapLoadableState, mapDispatchToGetData)
@Loadable
@connect(mapStateToProps, mapDispatchToProps)
export default class Detail extends PureComponent {
	static propTypes = {
		// roles : PropTypes.arrayOf(PropTypes.object).isRequired,
		availableStatus : PropTypes.arrayOf(PropTypes.string).isRequired,
		keyword : PropTypes.string.isRequired,
		imageToken : PropTypes.string.isRequired,
		userID : PropTypes.string.isRequired,
		projectID : PropTypes.string.isRequired,
		newFollowups : PropTypes.arrayOf(PropTypes.object).isRequired,
		newAttachments : PropTypes.arrayOf(PropTypes.object).isRequired,
		task  : PropTypes.object.isRequired,
		issues : PropTypes.array.isRequired,
		updateFollowup : PropTypes.func.isRequired,
		updateNewFollowup : PropTypes.func.isRequired,
		createFollowup : PropTypes.func.isRequired,
		uploadFollowup : PropTypes.func.isRequired,
		createAttachments : PropTypes.func.isRequired,
		updateKeyword : PropTypes.func.isRequired,
		type: PropTypes.string.isRequired,
		searchKey: PropTypes.string.isRequired,
	}

	renderFileUpload(newFollowup) {
		return <div style={styles.itemCtn}>
			{
				map(filter(this.props.newAttachments, { followupID : newFollowup._id }), attachment => {
					return <div style={styles.attachmentCtn}>
						<img style={styles.image} src={`data:${attachment.mimeType};base64,${attachment.data}`}/>
						<div>{ attachment.name }</div>
					</div>;
				})
			}
			<FlatButton
				style={styles.fileUploadCtn}
				labelPosition="before"
				icon={<FontIcon className="material-icons" style={styles.ctrlStyle}>photo</FontIcon>}>
				<input style={styles.fileInput} type="file" onChange={async e => {
					let files = e.target.files;
					let attachments = [];
					for (let i = 0; i < files.length; i++) {
						attachments.push(await readPromise(files[i]));
					}
					this.props.createAttachments(attachments, newFollowup._id);
				}} multiple/>
			</FlatButton>
		</div>;
	}

	renderAttachments(attachments = []) {
		return attachments.length
			? <ListItem disabled={true} key={'attachments'}>
				{
					map(attachments, (attachment, i) => attachment ? <img
						key={i}
						style={styles.image}
						src={`/projects/${this.props.projectID}/attachments/${attachment._id}?imageToken=${this.props.imageToken}&userID=${this.props.userID}`}
					/> : null)
				}
			</ListItem>
			: null;
	}

	renderIssue(issue, i) {
		let nestedItems = [];
		if (issue.remark)
			nestedItems.push(
				<ListItem key={'remark'} style={styles.remark}>
					<div>備註</div>
					<div>{ issue.remark }</div>
				</ListItem>
			);
		let attachments = this.renderAttachments(issue.attachments);
		if (attachments)
			nestedItems.push(attachments);
		let followups = this.renderFollowups(issue.followups);
		let newFollowups = this.renderNewFollowups(filter(this.props.newFollowups, { followupTo : issue.issueRef }));
		if (followups && followups.length)
			nestedItems = nestedItems.concat(followups);
		if (newFollowups && newFollowups.length)
			nestedItems = nestedItems.concat(newFollowups);
		nestedItems.push(
			<ListItem
				style={styles.addListItem}
				key={'addNewFollowup'}
				leftIcon={
					<FontIcon className="material-icons">note_add</FontIcon>
				}
				primaryText="新增跟進"
				onTouchTap={() => this.props.createFollowup(issue.issueRef)}
			/>
		);
		let backgroundColor;
		switch (issue.followups && issue.followups.length ? issue.followups[0].status : issue.status) {
		case 'await_repair':
			backgroundColor = '#EF5350';
			break;
		case 'await_inspect':
			backgroundColor = '#F9A825';
			break;
		case 'await_accept':
			backgroundColor = '#2196F3';
			break;
		case 'accepted':
			backgroundColor = '#4BC0C0';
			break;
		}
		return <ListItem
			key={i}
			style={{
				backgroundColor,
				color : 'rgba(255,255,255,0.7)'
			}}
			nestedItems={nestedItems}
			disabled={true}>
			<div style={styles.itemCtn}>
				<div style={styles.columnCtn}>{ issue._id }</div>
				{
					this.props.type == 'tray'
						? <div style={styles.columnCtn}>
							<div>{issue.addressTo.name}</div>
						</div>
						: null
				}
				<div style={styles.columnCtn}>
					<div>{ issue.issueSentence }</div>
				</div>
				<div style={styles.placeholder}/>
				<div style={styles.columnCtn}>{ new Date(issue.createdAt).toISOString().substring(0, 10) }</div>
			</div>
		</ListItem>;
	}

	renderNewFollowups(newFollowups) {
		return map(newFollowups, this.renderNewFollowup);
	}

	renderFollowups(followups) {
		return map(followups, this.renderFollowup);
	}

	renderNewFollowup = (newFollowup, i) => {
		return <ListItem
			style={styles.subListItem}
			key={'newFollowup'+i}
			disabled={true}
			rightIconButton={
				<IconButton
					style={styles.saveBtn}
					onTouchTap={() => this.props.uploadFollowup(newFollowup, this.props.newAttachments)}>
					<FontIcon className="material-icons">save</FontIcon>
				</IconButton>
			}>
			<div style={styles.itemCtn}>
				<div style={styles.columnCtn}>{ newFollowup._id }</div>
				<div style={styles.textFieldCtn}>
					<TextField
						id={new Date().getTime().toString()}
						fullWidth={true}
						multiLine={true}
						value={newFollowup.remark ? newFollowup.remark : ''}
						onChange={(e, v) => this.props.updateNewFollowup({ ...newFollowup, remark : v })}
					/>
				</div>
				<div style={styles.columnCtn}>
					<SelectField
						value={newFollowup.status}
						onChange={(e, k, v) => this.props.updateNewFollowup({ ...newFollowup, status : v })}
					>
						{
							this.props.availableStatus.map((status, i) => (
								<MenuItem key={i} value={status} primaryText={statusLabels[status]} />
							))
						}
					</SelectField>
				</div>
				<div style={styles.columnCtn}>{ new Date(newFollowup.createdAt).toISOString().substring(0, 10) }</div>
			</div>
			<div>
				<div style={styles.columnCtn}>{ this.renderFileUpload(newFollowup) }</div>
			</div>
		</ListItem>;
	}

	renderFollowup = (followup, i) => {

		return <ListItem
			style={styles.subListItem}
			key={'followup'+i}
			disabled={true}>

			<div>
				<div style={styles.itemCtn}>
					<div style={styles.columnCtn}>{ followup._id }</div>
					<div style={styles.columnCtn}>{ followup.remark }</div>
					<div style={styles.placeholder}/>
					<div style={styles.columnCtn}>{ new Date(followup.createdAt).toISOString().substring(0, 10) }</div>
					<div style={styles.statusCtn}>{ statusLabels[followup.status] }</div>
				</div>
				<div>
					{this.renderAttachments(followup.attachments)}
				</div>
			</div>
		</ListItem>;
	}

	renderIssues() {
		return map(this.props.issues || [], (issue, i) => {
			return this.renderIssue(issue, i);
		});
	}

	render() {
		const { type = 'task', searchKey, task, updateKeyword, keyword } = this.props;
		return <Card style={styles.main} containerStyle={styles.mainContainer}>
			<CardTitle title={type == 'task' ? task.name : searchKey }/>
			{ type == 'task' ? this.renderAttachments(task.attachments) : null }
			<CardText>
				<TextField
					id={new Date().getTime().toString()}
					fullWidth={true}
					hintText="輸入搜尋字"
					value={keyword}
					onChange={(e, v) => updateKeyword(v)}
				/>
			</CardText>
			<CardText style={styles.table}>
				<List>
					{ this.renderIssues() }
				</List>
			</CardText>
		</Card>;
	}
}
