import { select, apply } from 'redux-saga/effects';
import configs     from '../../configs';
import queryString from 'query-string';

export default function* callAPI(method, resource, query, data, headers) {
	let state = yield select();
	let { token } = state.app.user;

	let options = {
		method,
		headers : {
			'Authorization' : `Bearer ${token}`,
			'Content-Type' : 'application/json',
			...headers
		}
	};

	query = query ? `?${queryString.stringify(query)}` : '';

	let url = `${configs.URL_ROOT}${resource}${query}`;

	if (data && options.method != 'GET' && options.method != 'DELETE')
		options.body = JSON.stringify(data);

	let response = yield fetch(url, options);

	if (!response.ok)
		throw Error(response.status);

	let contentType = response.headers.get('content-type');

	if (contentType && contentType.indexOf('application/json') !== -1) {
		return yield apply(response, response.json);
	}

	if (contentType && contentType.indexOf('application/octet-stream') !== -1) {
		return yield apply(response, response.arrayBuffer);
	}

	return yield apply(response, response.text);

}
