import { combineReducers } from 'redux';

import general from './general';
import navBar  from './navBar';
import reportExport from './reportExport';
import notificationDate from './notification';

export default combineReducers({
	general,
	navBar,
	reportExport,
	notificationDate
});
