import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {
	teal500, teal700, teal300,
	grey100, grey300, grey400, grey500, grey600, blueGrey900,
	white, darkBlack, fullBlack,
} from 'material-ui/styles/colors';
import { fade } from 'material-ui/utils/colorManipulator';

export default getMuiTheme({
	spacing: {
		iconSize: 24,
		desktopGutter: 24,
		desktopGutterMore: 32,
		desktopGutterLess: 16,
		desktopGutterMini: 8,
		desktopKeylineIncrement: 64,
		desktopDropDownMenuItemHeight: 32,
		desktopDropDownMenuFontSize: 15,
		desktopDrawerMenuItemHeight: 48,
		desktopSubheaderHeight: 48,
		desktopToolbarHeight: 56,
	},
	fontFamily: 'Roboto Mono, Microsoft JhengHei, monospace',
	palette: {
		primary1Color: teal500,
		primary2Color: teal700,
		primary3Color: grey400,
		accent1Color: grey500,
		accent2Color: grey100,
		accent3Color: grey500,
		textColor: grey600,
		alternateTextColor: white,
		canvasColor: white,
		borderColor: grey300,
		disabledColor: fade(darkBlack, 0.3),
		pickerHeaderColor: grey500,
		clockCircleColor: fade(darkBlack, 0.07),
		shadowColor: fullBlack,
	},
	drawer : {
		color: blueGrey900
	},
	toolbar : {
		backgroundColor: teal300
	},
	tableRow : {
		height : 40
	},
	tableRowColumn : {
		height : 40,
		spacing : 0
	}
});
