//import schema from 'js-schema';
import { red500, purple600, indigo500, lightBlue600, lightGreen500 } from 'material-ui/styles/colors';
import ObjectId from 'objectid';

import { ARRAY_TO_JSON_GETTER } from 'commons/updatable/getters';
import { JSONPresenter } from 'commons/updatable/presenters';
import { OBJECT_SETTER } from 'commons/updatable/setters';
import { JSONEditor } from 'commons/updatable/editors';

export default {
	paths : [{
		key         : '_id',
		name        : 'ID',
		type        : 'string',
		show        : false,
		initializer : () => ObjectId().toString()
	}, {
		key         : 'name',
		type        : 'string',
		name        : '用戶組',
		editable    : true
	}, {
		key : 'label',
		name : '標籤'
	}, {
		key        : 'privileges',
		type       : ['string'],
		name       : '權限',
		editable   : true,
		presenter  : JSONPresenter,
		editor     : JSONEditor,
		getter     : ARRAY_TO_JSON_GETTER,
		setter     : OBJECT_SETTER,
		highlights : [
			{
				regex    : /(DELETE)/g,
				color    : red500
			}, {
				regex    : /(GET)/g,
				color    : purple600
			}, {
				regex    : /(POST)/g,
				color    : indigo500
			}, {
				regex    : /(PUT)/g,
				color    : lightBlue600
			}, {
				regex    : /({{\s*[\w.]+\s*}})/g,
				color    : lightGreen500
			}
		]
	}, {
		key         : 'updatedAt',
		type        : 'string',
		name        : '上次修改',
		editable    : false
	}],
	keyPath : '_id'
};
