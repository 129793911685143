const initState = {
	open : true
};

export default (state = initState, action) => {
	switch (action.type) {
	case 'TOGGLE_NAVBAR':
		return { open : !state.open };
	default:
		return state;
	}
};
