import { Map, fromJS } from 'immutable';

const initState = {
	loading : new Map(),
	initialized : new Map(),
	dateLimit : 14,
	data : new Map()
};

export default (state = initState, action) => {
	let { type, payload = {} } = action;
	let { projectID, data, dateLimit } = payload;
	switch (type) {
	case 'UPDATE_NOTI_LIMIT':
		return { ...state, dateLimit };
	case 'GET_NOTIFICATION':
		return { ...state, loading : state.loading.setIn([projectID, dateLimit], true) };
	case 'GET_NOTIFICATION_SUCCESS':
		return {
			...state,
			data : state.data.setIn([projectID, dateLimit], fromJS(data)),
			loading : state.loading.setIn([projectID, dateLimit], false),
			initialized : state.initialized.setIn([projectID, dateLimit], true),
		};
	case 'GET_NOTIFICATION_FAILURE':
		return { ...state, loading : state.loading.setIn([projectID, dateLimit], false) };
	default:
		return state;
	}
};
