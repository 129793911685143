import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import forEach            from 'lodash/forEach';
import map                from 'lodash/map';
import filter             from 'lodash/filter';
import some               from 'lodash/some';
import indexOf            from 'lodash/indexOf';
import keys               from 'lodash/keys';
import _                  from 'lodash';
import { Map, List as ImmutableList } from 'immutable';
import FontIcon           from 'material-ui/FontIcon';
import IconButton         from 'material-ui/IconButton';
import {
	Card,
	CardTitle,
	CardText,
} from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import TextField  from 'material-ui/TextField';
import { ListItem, List } from 'material-ui/List';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import {
	GET_DATA,
	UPDATE_DATA,
	CREATE_DATA,
	UPDATE_FOLLOWUP,
	CREATE_FOLLOWUP,
	SUBMIT_FOLLOWUP,
	CREATE_ATTACHMENTS,
	UPDATE_FOLLOWUP_KEYWORD
} from '../actions';

import immutableSelector from 'utils/immutableSelector';
import Loadable        from 'commons/loadable';

import styles          from './Detail.styles';

import { readPromise } from 'utils/fileUploadHelper';

const statusLabels = {
	await_repair : '未執修',
	await_inspect : '已執修&待檢查',
	await_accept : '已檢查&待接受',
	accepted : '接受'
};

const selectLoading = createSelector(
	(state, { match }) => !!state.data.getIn(['projectDB', 'loading', 'tasks', match.params.projectID]),
	(state, { match }) => !!state.data.getIn(['projectDB', 'loading', 'issues', match.params.projectID]),
	(state, { match }) => !!state.data.getIn(['projectDB', 'loading', 'followups', match.params.projectID]),
	(state, { match }) => !!state.data.getIn(['projectDB', 'loading', 'words', match.params.projectID]),
	(state, { match }) => !!state.data.getIn(['projectDB', 'loading', 'attachments', match.params.projectID]),
	(...loading) => _.some(loading, Boolean)
);

const selectInitized = createSelector(
	(state, { match }) => !!state.data.getIn(['projectDB', 'initialized', 'tasks', match.params.projectID]),
	(state, { match }) => !!state.data.getIn(['projectDB', 'initialized', 'issues', match.params.projectID]),
	(state, { match }) => !!state.data.getIn(['projectDB', 'initialized', 'followups', match.params.projectID]),
	(state, { match }) => !!state.data.getIn(['projectDB', 'initialized', 'words', match.params.projectID]),
	(state, { match }) => !!state.data.getIn(['projectDB', 'initialized', 'attachments', match.params.projectID]),
	(...initialized) => _.every(initialized, Boolean)
);

const mapLoadableState = createSelector(
	selectLoading,
	selectInitized,
	(state, { match }) => match.params.projectID,
	(loading, initialized, projectID) => ({ loading, initialized, projectID })
);

const mapDispatchToGetData = dispatch => ({
	getData : ({ projectID }) => dispatch(
		new GET_DATA({
			models : ['words', 'issues', 'followups', 'tasks', 'attachments'].reduce((result, model) => ([...result, { model, projectID}]), [])
		})
	),
});

const mapStateToProps = immutableSelector(
	state              => state.app.user._id,
	state              => state.app.user.imageToken,
	state              => state.app.user.roles,
	(state, { match }) => match.params.projectID,
	(state, { match }) => match.params.taskID,
	(state, { match }) => state.data.getIn(['projectDB', 'tasks', match.params.projectID]),
	(state, { match }) => state.data.getIn(['projectDB', 'issues', match.params.projectID]),
	(state, { match }) => state.data.getIn(['projectDB', 'followups', match.params.projectID]),
	(state, { match }) => state.data.getIn(['projectDB', 'words', match.params.projectID]),
	(state, { match }) => state.data.getIn(['projectDB', 'attachments', match.params.projectID]),
	state              => state.app.taskDetail.keyword,
	state              => state.app.taskDetail.newFollowups,
	state              => state.app.taskDetail.newAttachments,
	(
		userID = '', imageToken = '', roles = [], projectID, taskID,
		tasks, issues, followups, words, attachments, keyword,
		newFollowups, newAttachments,
	) => {

		let availableStatus = filter(keys(statusLabels), status => some(roles, ({ role, scope }) => {
			return role.name == 'system_admin' || (~indexOf(statuses[status], role.name) && scope.projectID == projectID);
		}));
		let taskIssues = issues
			.filter(issue => issue.get('addressTo') == taskID && issue.get('deleted') != true)
			.groupBy(issue => issue.get('issueRef'))
			.map(issueVersions => issueVersions.sortBy(issue => -(new Date(issue.get('createdAt')).getTime())).toList().get(0))
			.filter(issue => issue.get('dropped') != true)
			.map(issue => {
				let sentence = '';
				let content = issue.get('content').map(content => {
					let word = words.get(content.get('word'));
					let count = content.get('count');
					let label = word.get('label');
					let prefix = word.get('prefix');
					let suffix = word.get('suffix');
					let unit = word.get('unit');
					let hidden = word.get('hidden');
					sentence = sentence + (
						hidden
							? ''
							: unit
								? count
									? count + unit
									: '幾' + unit
								: ''
						+ prefix + label + suffix
					);
					return content.merge({ word });
				});
				let taskFollowups = followups
					.filter(followup => followup.get('followupTo') == issue.get('issueRef') && followup.get('deleted') != true)
					.sortBy(followup => -(new Date(followup.get('createdAt')).getTime()))
					.map(followup => followup.update('attachments', list => list.map(attachmentID => attachments.get(attachmentID))))
					.toList();
				let status = taskFollowups.size == 0
					? 'await_repair'
					: taskFollowups.getIn([0, 'status']);
				return issue.merge({
					content,
					sentence,
					followups : taskFollowups,
					attachments : issue.get('attachments').map(attachmentID => attachments.get(attachmentID)),
					status
				});
			})
			.filter(issue => issue.get('content').some(content => new RegExp(keyword, 'g').test(content.getIn(['word', 'label'])) ))
			.sortBy(issue => -(new Date(issue.get('createdAt')).getTime()))
			.toList().toJS();
		let task = (tasks.get(taskID) || new Map());
		let statuses = {
			'await_repair' : ['manager', 'inspector'],
			'await_inspect' : ['manager', 'contractor'],
			'await_accept' : ['manager', 'inspector'],
			'accepted' : ['manager', 'property_owner']
		};

		return {
			roles,
			attachments : (task.get('attachments') || new ImmutableList()).map(attachmentID => attachments.get(attachmentID) || new Map()).toJS(),
			issues : taskIssues,
			availableStatus,
			userID,
			imageToken,
			keyword,
			newFollowups,
			newAttachments,
			task
		};
	}
);

const mapDispatchToProps = (dispatch, { match }) => ({
	dispatch       : dispatch,
	getData        : ({ match : { params : { projectID }} }) => dispatch(
		new GET_DATA({
			models : ['words', 'issues', 'followups', 'tasks', 'attachments'].reduce((result, model) => ([...result, { model, projectID}]), [])
		})
	),
	updateFollowup : followup => dispatch(new UPDATE_DATA({ followup, projectID : match.params.projectID, model : 'followups', docName : 'followup' })),
	updateNewFollowup : followup => dispatch(new UPDATE_FOLLOWUP({ followup })),
	createFollowup : issueRef => dispatch(new CREATE_FOLLOWUP({ issueRef })),
	createAttachments : (attachments, followupID) => dispatch(new CREATE_ATTACHMENTS({ attachments, followupID })),
	updateKeyword : keyword => dispatch(new UPDATE_FOLLOWUP_KEYWORD({ keyword }))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
	...stateProps,
	...dispatchProps,
	...ownProps,
	uploadFollowup : followup => {
		let { newAttachments } = stateProps;
		let { dispatch } = dispatchProps;
		let { match } = ownProps;
		let attachments = filter(newAttachments, { followupID : followup._id });
		forEach(attachments, attachment => dispatch(new CREATE_DATA({ record : attachment, projectID : match.params.projectID, model : 'attachments'})));
		dispatch(new CREATE_DATA({ record : { ...followup, attachments : map(attachments, '_id') }, projectID : match.params.projectID, model : 'followups' }));
		dispatch(new SUBMIT_FOLLOWUP({ followup }));
	}
});

// @ProjectsEnsured
@connect(mapLoadableState, mapDispatchToGetData)
@Loadable
@connect(mapStateToProps, mapDispatchToProps, mergeProps)
export default class TaskDetail extends PureComponent {
	static propTypes = {
		roles : PropTypes.arrayOf(PropTypes.object).isRequired,
		availableStatus : PropTypes.arrayOf(PropTypes.string).isRequired,
		keyword : PropTypes.string.isRequired,
		imageToken : PropTypes.string.isRequired,
		userID : PropTypes.string.isRequired,
		newFollowups : PropTypes.arrayOf(PropTypes.object).isRequired,
		newAttachments : PropTypes.arrayOf(PropTypes.object).isRequired,
		match : PropTypes.object.isRequired,
		task  : PropTypes.object.isRequired,
		issues : PropTypes.array.isRequired,
		attachments : PropTypes.array.isRequired,
		getData : PropTypes.func.isRequired,
		updateFollowup : PropTypes.func.isRequired,
		updateNewFollowup : PropTypes.func.isRequired,
		createFollowup : PropTypes.func.isRequired,
		uploadFollowup : PropTypes.func.isRequired,
		createAttachments : PropTypes.func.isRequired,
		updateKeyword : PropTypes.func.isRequired
	}

	renderFileUpload(newFollowup) {
		return <div style={styles.itemCtn}>
			{
				map(filter(this.props.newAttachments, { followupID : newFollowup._id }), attachment => {
					return <div style={styles.attachmentCtn}>
						<img style={styles.image} src={`data:${attachment.mimeType};base64,${attachment.data}`}/>
						<div>{ attachment.name }</div>
					</div>;
				})
			}
			<FlatButton
				style={styles.fileUploadCtn}
				labelPosition="before"
				icon={<FontIcon className="material-icons" style={styles.ctrlStyle}>photo</FontIcon>}>
				<input style={styles.fileInput} type="file" onChange={async e => {
					let files = e.target.files;
					let attachments = [];
					for (let i = 0; i < files.length; i++) {
						attachments.push(await readPromise(files[i]));
					}
					this.props.createAttachments(attachments, newFollowup._id);
				}} multiple/>
			</FlatButton>
		</div>;
	}

	renderAttachments(attachments = []) {
		return attachments.length
			? <ListItem disabled={true} key={'attachments'}>
				{
					map(attachments, (attachment, i) => <img
						key={i}
						style={styles.image}
						src={`/projects/${this.props.match.params.projectID}/attachments/${attachment._id}?imageToken=${this.props.imageToken}&userID=${this.props.userID}`}
					/>)
				}
			</ListItem>
			: null;
	}

	renderIssue(issue, i) {
		let nestedItems = [];
		if (issue.remark)
			nestedItems.push(
				<ListItem key={'remark'} style={styles.remark}>
					<div>備註</div>
					<div>{ issue.remark }</div>
				</ListItem>
			);
		let attachments = this.renderAttachments(issue.attachments);
		if (attachments)
			nestedItems.push(attachments);
		let followups = this.renderFollowups(issue.followups);
		let newFollowups = this.renderNewFollowups(filter(this.props.newFollowups, { followupTo : issue.issueRef }));
		if (followups && followups.length)
			nestedItems = nestedItems.concat(followups);
		if (newFollowups && newFollowups.length)
			nestedItems = nestedItems.concat(newFollowups);
		nestedItems.push(
			<ListItem
				style={styles.addListItem}
				key={'addNewFollowup'}
				leftIcon={
					<FontIcon className="material-icons">note_add</FontIcon>
				}
				primaryText="新增跟進"
				onTouchTap={() => this.props.createFollowup(issue.issueRef)}
			/>
		);
		let backgroundColor;
		switch (issue.status) {
		case 'await_repair':
			backgroundColor = '#EF5350';
			break;
		case 'await_inspect':
			backgroundColor = '#FFCE56';
			break;
		case 'await_accept':
			backgroundColor = '#2196F3';
			break;
		case 'accepted':
			backgroundColor = '#4BC0C0';
			break;
		}
		return <ListItem
			key={i}
			style={{
				backgroundColor,
				color : 'rgba(255,255,255,0.7)'
			}}
			nestedItems={nestedItems}
			disabled={true}>
			<div style={styles.itemCtn}>
				<div style={styles.columnCtn}>{ issue._id }</div>
				<div style={styles.columnCtn}>
					<div>{ issue.sentence }</div>
				</div>
				<div style={styles.placeholder}/>
				<div style={styles.columnCtn}>{ new Date(issue.createdAt).toISOString().substring(0, 10) }</div>
			</div>
		</ListItem>;
	}

	renderNewFollowups(newFollowups) {
		return map(newFollowups, this.renderNewFollowup);
	}

	renderFollowups(followups) {
		return map(followups, this.renderFollowup);
	}

	renderNewFollowup = (newFollowup, i) => {
		return <ListItem
			style={styles.subListItem}
			key={'newFollowup'+i}
			disabled={true}
			rightIconButton={
				<IconButton
					style={styles.saveBtn}
					onTouchTap={() => this.props.uploadFollowup(newFollowup)}>
					<FontIcon className="material-icons">save</FontIcon>
				</IconButton>
			}>
			<div style={styles.itemCtn}>
				<div style={styles.columnCtn}>{ newFollowup._id }</div>
				<div style={styles.textFieldCtn}>
					<TextField
						id={new Date().getTime().toString()}
						fullWidth={true}
						multiLine={true}
						value={newFollowup.remark ? newFollowup.remark : ''}
						onChange={(e, v) => this.props.updateNewFollowup({ ...newFollowup, remark : v })}
					/>
				</div>
				<div style={styles.columnCtn}>
					<SelectField
						value={newFollowup.status}
						onChange={(e, k, v) => this.props.updateNewFollowup({ ...newFollowup, status : v })}
					>
						{
							this.props.availableStatus.map((status, i) => (
								<MenuItem key={i} value={status} primaryText={statusLabels[status]} />
							))
						}
					</SelectField>
				</div>
				<div style={styles.columnCtn}>{ new Date(newFollowup.createdAt).toISOString().substring(0, 10) }</div>
			</div>
			<div>
				<div style={styles.columnCtn}>{ this.renderFileUpload(newFollowup) }</div>
			</div>
		</ListItem>;
	}

	renderFollowup = (followup, i) => {

		return <ListItem
			style={styles.subListItem}
			key={'followup'+i}
			disabled={true}>

			<div>
				<div style={styles.itemCtn}>
					<div style={styles.columnCtn}>{ followup._id }</div>
					<div style={styles.columnCtn}>{ followup.remark }</div>
					<div style={styles.placeholder}/>
					<div style={styles.columnCtn}>{ new Date(followup.createdAt).toISOString().substring(0, 10) }</div>
					<div style={styles.statusCtn}>{ statusLabels[followup.status] }</div>
				</div>
				<div>
					{this.renderAttachments(followup.attachments)}
				</div>
			</div>
		</ListItem>;
	}

	renderIssues() {
		return map(this.props.issues, (issue, i) => {
			return this.renderIssue(issue, i);
		});
	}

	render() {
		return <Card style={styles.main} containerStyle={styles.mainContainer}>
			<CardTitle title={this.props.task.name}/>
			{ this.renderAttachments(this.props.attachments) }
			<CardText>
				<TextField
					id={new Date().getTime().toString()}
					fullWidth={true}
					hintText="輸入搜尋字"
					value={this.props.keyword}
					onChange={(e, v) => this.props.updateKeyword(v)}
				/>
			</CardText>
			<CardText style={styles.table}>
				<List>
					{ this.renderIssues() }
				</List>
			</CardText>
		</Card>;
	}
}
