import { put, call, takeLatest, cancelled } from 'redux-saga/effects';
import callAPI        from './callAPI';
// import catchError     from './catchError';

function* getSummary(action) {
	let { projectID, groupBy, date } = action.payload;
	try {
		let urlQuery = date ? { before : date.getTime() } : {};
		let url = `/api/projects/${projectID}/summary/${groupBy}`;
		let { data } = yield call(callAPI, 'GET', url, urlQuery);

		yield put(new action.SUCCESS({ projectID, groupBy, data, date }));
	} catch(err) {
		yield put(new action.FAILURE({ projectID, groupBy, date, err }));
		throw err;
	} finally {
		if (yield cancelled()) {
			yield put(new action.FAILURE({ projectID, groupBy, date }));
		}
	}
}

export default function* summarySaga() {
	yield takeLatest('GET_SUMMARY', getSummary);
}
