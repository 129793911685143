import { put, call, takeLatest, select, cancelled } from 'redux-saga/effects';
import callAPI from './callAPI';
import isString from 'lodash/isString';

import { SHOW_ERROR } from '../actions';

import { readPromise, saveBlob, base64toBlob } from 'utils/fileUploadHelper';

export default function* listenUploadFile() {
	yield takeLatest('UPLOAD_CSV', function*(action) {
		try {
			let state = yield select();
			let { currProject : projectID } = state.app;
			let { file } = action.payload;
			let { model } = action.meta;
			let {data} = yield call(readPromise, file);
			let response = yield call(callAPI, 'POST', `/api/projects/${projectID}/${model}/batch`, null, {data});
			let blob = yield call(base64toBlob, response, 'text/csv');
			yield call(saveBlob, blob, `${projectID}_${model}_${new Date().toISOString()}.csv`);
			yield put(new action.SUCCESS());
		} catch(err) {
			let _err = err;
			if (isString(err)) {
				_err = {
					message : err
				};
			}
			yield put(new SHOW_ERROR({ errMsg : _err.message || _err.name || 'UNEXPECTED ERROR'}));
			yield put(new action.FAILURE());
		} finally {
			if (yield cancelled()) {
				yield put(new action.FAILURE());
			}
		}


	});
}
