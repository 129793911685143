export default {
	root : {
		position : 'fixed',
		height : '100%',
		width: '100%',
		display : 'flex',
		flexDirection : 'column'
	},
	main : {
		flex : 1,
		display : 'flex',
		flexDirection : 'column',
		alignItems : 'stretch'
	}
};
