import { Map, fromJS } from 'immutable';
import moment from 'moment';

const initState = {
	date : moment().endOf('day'),
	groupBy : 'task',
	loading : new Map(),
	initialized : new Map(),
	data : new Map()
};

export default (state = initState, action) => {
	let { type, payload = {} } = action;
	let { data, projectID, date, groupBy } = payload;
	let currDate = moment(date).format('YYYYMMDD');
	switch (type) {
	case 'CHANGE_DATE':
		return { ...state, date : moment(date).endOf('day') };
	case 'UPDATE_GROUP':
		return { ...state, groupBy };
	case 'GET_SUMMARY':
		return { ...state, loading : state.loading.setIn([projectID, groupBy, currDate], true) };
	case 'GET_SUMMARY_SUCCESS':
		return {
			...state,
			data : state.data.setIn([projectID, groupBy, currDate], fromJS(data)),
			loading : state.loading.setIn([projectID, groupBy, currDate], false),
			initialized : state.initialized.setIn([projectID, groupBy, currDate], true),
		};
	case 'GET_SUMMARY_FAILURE':
		return { ...state, loading : state.loading.setIn([projectID, groupBy, currDate], false) };
	default:
		return state;
	}
};
