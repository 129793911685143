import {
	blueGrey100, teal300
} from 'material-ui/styles/colors';

export default {

	main : {
		margin : '16px',
		padding : '16px',
		display : 'flex',
		flexDirection : 'column',
		flex : 1,
		overflowY : 'auto'
	},
	mainContainer : {
		flex : 1,
		display : 'flex',
		flexDirection : 'column',
		marginBottom : '16px',
	},
	table : {
		flex : 1,
		display : 'flex',
		flexDirection : 'column'
	},

	itemCtn : {
		fontSize : '18px',
		display : 'flex',
		flexDirection : 'row',
		alignItems : 'center'
	},

	subListItem : {
		backgroundColor : blueGrey100
	},

	columnCtn : {
		marginRight : '32px'
	},

	statusCtn : {
		width : '160px'
	},

	textFieldCtn : {
		flex : 1,
		marginRight : '32px'
	},

	addListItem : {
		backgroundColor : blueGrey100,
		marginLeft : '18px'
	},

	placeholder : {
		flex : 1
	},

	saveBtn : {
		height : '100%'
	},

	approved : {
		color : teal300
	},

	fileInput: {
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		width: '100%',
		opacity: 0,
	},

	fileUploadCtn : {
		border : `5px dashed ${blueGrey100}`,
		height : '180px',
		width : '240px'
	},

	image : {
		// height : '180px',
		// width : '240px'
	},

	attachmentCtn : {
		display : 'flex',
		flexDirection : 'column',
		alignItems : 'center'
	},
	remark : {
		flex : 1,
		backgroundColor : 'rgba(0,0,0,.09)',
		borderRadius : '8px'
	}
};
