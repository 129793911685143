import React from 'react';
import styles from './styles';

import CollectionTable from '../collectionTable';
import {
	Card, CardTitle, CardText
} from 'material-ui/Card';

export default function ProjectManagement() {
	return (
		<Card style={styles.main} containerStyle={styles.mainContainer}>
			<CardTitle
				title="項目管理"
				subtitle="Project Management"
			/>
			<CardText style={styles.table}>
				<CollectionTable
					sceneName="projects"
					model="projects"
					projectSpecific={false}
				/>
			</CardText>
		</Card>
	);
}
