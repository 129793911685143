import { all, put, call, take, select, takeEvery, fork, cancelled } from 'redux-saga/effects';
import callAPI        from './callAPI';
// import { importData, getData, getMeta } from './database';
import catchError     from './catchError';
// import mergeCollection from 'utils/mergeCollection';
import pluralize from 'pluralize';

const create = function* create(action) {
	let { record, projectID, model } = action.payload;
	let url = `/api/${projectID ? `projects/${projectID}/` : ''}${model}`;
	if (model == 'followups')
		url = url + '/' + record.status;
	try {
		let res = yield call(callAPI, 'POST', url, null, record);
		let doc = res[pluralize.singular(model)];
		yield put(new action.SUCCESS({ doc, projectID, model, key : doc._id }));
	} catch(err) {
		yield put(new action.FAILURE({ projectID, model }));
		throw err;
	} finally {
		if (yield cancelled()) {
			yield put(new action.FAILURE({ projectID, model }));
		}
	}
};

const get = function* get(action) {
	let { withFile, taskID, models } = action.payload;
	let state = yield select();
	try {
		let updatedAt = new Date();
		let requests = models.map(({ model, projectID }) => {
			let lastUpdated = projectID ? state.data.getIn(['projectDB', 'lastUpdated', model, projectID ]) : state.data.getIn(['adminDB', 'lastUpdated', model]);
			let urlQuery;

			urlQuery = { lastUpdated : lastUpdated && !withFile ? lastUpdated.getTime() : null, withFile, taskID };
			let url = `/api/${projectID ? `projects/${projectID}/` : ''}${model}`;
			return call(callAPI, 'GET', url, urlQuery);
		});
		let payloads = yield all(requests);
		let data = models.reduce((result, { model, projectID }, i) => {
			if (projectID) {
				result.projectData[model] = result.projectData[model] || {};
				result.projectData[model][projectID] = payloads[i][model].reduce((docs, doc) => ({...docs, [doc._id] : doc}), {});
			}
			result.adminData[model] = payloads[i][model].reduce((docs, doc) => ({...docs, [doc._id] : doc}), {});
			return result;
		}, { projectData : {}, adminData : {} });

		yield put(new action.SUCCESS({ data, lastUpdated : updatedAt, models }));
	} catch(err) {
		yield put(new action.FAILURE({ models, err }));
		throw err;
	} finally {
		if (yield cancelled()) {
			yield put(new action.FAILURE({ models }));
		}
	}
};

const update = function* update(action) {
	let { model, projectID, key, record } = action.payload;
	let url = `/api/${projectID ? `projects/${projectID}/` : ''}${model}/${key}`;
	try {
		let res = yield call(callAPI, 'PUT', url, null, record);
		let doc = res[pluralize.singular(model)];
		yield put(new action.SUCCESS({ doc, projectID, model, key : doc._id }));
	} catch(err) {
		yield put(new action.FAILURE({ projectID, model, key, err }));
		throw err;
	} finally {
		if (yield cancelled()) {
			yield put(new action.FAILURE({ projectID, model, key }));
		}
	}

};

const remove = function* create(action) {
	let { model, projectID, key } = action.payload;
	let url = `/api/${projectID ? `projects/${projectID}/` : ''}${model}/${key}`;
	try {
		yield call(callAPI, 'DELETE', url);
		yield put(new action.SUCCESS({ key, model, projectID }));
	} catch(err) {
		yield put(new action.FAILURE({ projectID, model, key, err }));
		throw err;
	} finally {
		if (yield cancelled()) {
			yield put(new action.FAILURE({ projectID, model, key }));
		}
	}
};

export default function* modelSaga() {
	yield fork(function* () {
		let endless = true;
		while(endless) {
			let action = yield take('GET_DATA');
			yield fork(catchError(get), action);
		}
	});
	yield takeEvery('CREATE_DATA', catchError(create));
	yield takeEvery('UPDATE_DATA', catchError(update));
	yield takeEvery('REMOVE_DATA', catchError(remove));
}
