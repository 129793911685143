import { Map, Set } from 'immutable';
import {
	PASTE_TO_CELL,
	UPDATE_RECORD,
	COMMIT_CHANGES,
	CLICK_AWAY,
	ADD_RECORD,
	REMOVE_RECORD,
	EDIT_CELL,
	SELECT_CELL,
	UPDATE_CRITERIA,
	UPDATE_COLUMN_WIDTH,
	UPDATE_HEIGHT
} from './actions';

const initialState = new Map({
	changes : new Map(),
	widths : new Map(),
	heights : new Map(),
	criteria : '',
	selected : null,
	editing : null,
	errMsgs : new Map(),
	newRecords : new Set(),
	copyValue : ''
});

export default tableKey => (state = initialState, action) => {
	let { type, payload = {} } = action;
	if (payload.tableKey != tableKey)
		return state;
	switch (type) {
	case PASTE_TO_CELL:
		return state.withMutations(map => map.setIn(['changes', payload.key], payload.value));
	case ADD_RECORD:
		return state.withMutations(map => map.update('newRecords', list => list.add(payload.key)).setIn(['changes', payload.key], payload.newRecord));
	case UPDATE_RECORD:
		return state.setIn(['changes', payload.key], payload.record);
	case COMMIT_CHANGES:
	case REMOVE_RECORD:
		return state.withMutations(map => map.update('newRecords', list => list.remove(payload.key)).deleteIn(['changes', payload.key], payload.newRecord));
	case EDIT_CELL:
		return state.withMutations(map => map.set('editing', payload.cellID).set('selected', null).setIn(['errMsgs', payload.cellID], payload.errMsg));
	case SELECT_CELL:
		return state.withMutations(map => map.set('selected', payload.cellID).set('editing', null));
	case CLICK_AWAY:
		return state.withMutations(map => map.set('selected', null).set('editing', null).set('copyValue', ''));
	case UPDATE_CRITERIA:
		return state.set('criteria', payload.criteria);
	case UPDATE_COLUMN_WIDTH:
		return state.setIn(['widths', payload.key], payload.width);
	case UPDATE_HEIGHT:
		return state.setIn(['heights', payload.rowIndex.toString()], payload.height);
	default:
		return state;
	}
};
