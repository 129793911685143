import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Drawer, FontIcon, BottomNavigationItem } from 'material-ui';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import intersection from 'lodash/intersection';
import { push } from 'react-router-redux';
import { matchPath } from 'react-router';

import { TOGGLE_NAVBAR } from '../actions';
import { routes } from '../../routes';

const BREAK_POINT = 996;
const iconStyle = {
	fontSize : '56px'
};
const fontStyle = {
	fontSize : '18px'
};

const mapStateToProps = createSelector(
	state => state.app.currProject,
	state => state.ui.navBar.open,
	state => state.ui.general.width,
	state => state.router.location.pathname,
	state => state.app.user.roles,
	(projectID, open, width, path, roles) => ({ projectID, open, width, path, roles : roles.map(({role}) => role.name) })
);

const mapDispatchToProps = dispatch => ({
	toggleNavBar : () => dispatch(new TOGGLE_NAVBAR()),
	goTo : (path, toggle) => {
		dispatch(push(path));
		if (toggle)
			dispatch(new TOGGLE_NAVBAR());
	},
});

@connect(mapStateToProps, mapDispatchToProps)
export default class NavBar extends PureComponent {

	static propTypes = {
		projectID    : PropTypes.string.isRequired,
		open         : PropTypes.bool.isRequired,
		path         : PropTypes.string.isRequired,
		width        : PropTypes.number.isRequired,
		goTo         : PropTypes.func.isRequired,
		toggleNavBar : PropTypes.func.isRequired,
		roles        : PropTypes.arrayOf(PropTypes.string).isRequired
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.width <= BREAK_POINT && this.props.width > BREAK_POINT && this.props.open)
			this.props.toggleNavBar();
		if (nextProps.width > BREAK_POINT && this.props.width <= BREAK_POINT && !this.props.open)
			this.props.toggleNavBar();
	}

	renderItem(labelName, iconName, path, link, cb) {
		const icon = (
			<FontIcon
				className="material-icons">
				<div style={iconStyle}>{iconName}</div>
			</FontIcon>
		);
		const label = (
			<div style={fontStyle}>{labelName}</div>
		);
		return (
			<BottomNavigationItem
				selected={!!matchPath(this.props.path, { path })}
				key={`navItem:${path}`}
				label={label}
				icon={icon}
				onTouchTap={() => cb(link ? link(this.props.projectID != '' ? { projectID : this.props.projectID } : {}) : path, this.props.width <= BREAK_POINT)}
				style={{ marginBottom : '24px' }}
			/>
		);
	}

	renderItems() {
		const items = [];
		forEach(
			filter(routes, route => {
				return !route.allowRoles || !!intersection(route.allowRoles, this.props.roles).length;
			}),
			({ labelName, iconName, path, link }) => {
				if (labelName) items.push(this.renderItem(labelName, iconName, path, link, this.props.goTo));
			}
		);
		return items;
	}

	render() {
		return (
			<Drawer
				width={150}
				open={this.props.open}
				docked={this.props.width > BREAK_POINT}
				onRequestChange={this.props.toggleNavBar}
				swipeAreaWidth={100}
				disableSwipeToOpen={false}>
				<center style={{ marginTop : '64px' }}>
					{this.renderItems()}
				</center>
			</Drawer>
		);
	}
}
