//import schema from 'js-schema';
//import { red500, purple600, indigo500, lightBlue600, lightGreen500 } from 'material-ui/styles/colors';

import ObjectId from 'objectid';
import { ARRAY_TO_JSON_GETTER, NUMBER_GETTER } from 'commons/updatable/getters';
import { OBJECT_SETTER, NUMBER_SETTER } from 'commons/updatable/setters';
import { JSONEditor } from 'commons/updatable/editors';
import { JSONPresenter } from 'commons/updatable/presenters';

export default {
	paths : [{
		key         : '_id',
		name        : 'ID',
		type        : 'string',
		show        : true,
		initializer : () => ObjectId().toString()
	}, {
		key         : 'name',
		type        : 'string',
		name        : '項目名稱',
		editable    : true
	}, {
		key         : 'manager',
		type        : 'string',
		name        : '管理員',
		editable    : true
	}, {
		key       : 'monitors',
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		initializer : () => ([]),
		minWidth  : 300,
		type      : ['string'],
		name      : '監督',
		editable  : true
	}, {
		key       : 'members',
		type      : ['string'],
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		initializer : () => ([]),
		minWidth  : 300,
		name      : '成員',
		editable  : true
	}, {
		key       : 'inspectors',
		type      : ['string'],
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		initializer : () => ([]),
		minWidth  : 300,
		name      : '檢查員',
		editable  : true
	}, {
		key       : 'photographers',
		type      : ['string'],
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		initializer : () => ([]),
		minWidth  : 300,
		name      : '相片記錄',
		editable  : true
	}, {
		key       : 'contractors',
		type      : ['string'],
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		initializer : () => ([]),
		minWidth  : 300,
		name      : '承建商',
		editable  : true
	}, {
		key       : 'property_owners',
		type      : ['string'],
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		initializer : () => ([]),
		minWidth  : 300,
		name      : '業主',
		editable  : true
	}, {
		key         : 'database',
		type        : 'string',
		name        : '資料庫',
		editable    : true
	}, {
		key         : 'schemaVersion',
		type        : 'string',
		name        : '資料庫版本',
		editable    : false,
		creatable   : true
	}, {
		key : 'capacity',
		name : '容量限制',
		type : 'number',
		setter : NUMBER_SETTER,
		getter : NUMBER_GETTER,
		editable : true
	}, {
		key         : 'updatedAt',
		type        : 'string',
		name        : '上次修改',
		editable    : false
	}],
	keyPath : '_id'
};
