import ObjectId from 'objectid';

import { BOOL_GETTER, ARRAY_TO_JSON_GETTER } from 'commons/updatable/getters';
import { BooleanPresenter, JSONPresenter } from 'commons/updatable/presenters';
import { OBJECT_SETTER } from 'commons/updatable/setters';
import { CheckboxEditor, JSONEditor } from 'commons/updatable/editors';

export default {
	paths : [{
		key : '_id',
		type : 'string',
		name : 'ID',
		initializer : () => ObjectId().toString(),
		editable : false
	}, {
		key  : 'dropped',
		type : 'boolean',
		name : '已取消',
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter,
		editable : false
	}, {
		key : 'issueRef',
		name : '參考號碼',
		editable : false,
		creatable : true,
		initializer : () => ObjectId().toString()
	}, {
		key : 'addressTo',
		name : '工作'
	}, {
		key  : 'content',
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		name : '內容',
	}, {
		key  : 'urgent',
		type : 'boolean',
		name : '緊急',
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter
	}, {
		key  : 'remark',
		type : 'string',
		name : '備註'
	}, {
		key  : 'attachments',
		type : ['string'],
		name : '附件',
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
	}, {
		key  : 'updatedAt',
		type : 'string',
		name : '上次修改',
		editable : false
	}],
	keyPath : '_id'
};
