import { takeLatest, fork } from 'redux-saga/effects';
// import { browserHistory } from 'react-router';

import getToken         from './token';
import modelSaga        from './modelSaga';
import summarySaga      from './summarySaga';
import notificationSaga from './notificationSaga';
import taskDetailSaga   from './taskDetailSaga';
import logoutSaga       from './logout';
import uploadCSV        from './uploadCSV';
import exportReport     from './exportReport';

/*eslint no-console: ["error", { allow: ["log"] }] */

export default function* actionSaga() {

	yield takeLatest('GET_TOKEN', getToken);
	yield fork(modelSaga);
	yield fork(summarySaga);
	yield fork(notificationSaga);
	yield fork(taskDetailSaga);
	yield fork(uploadCSV);
	yield fork(exportReport);
	yield takeLatest('LOGOUT', logoutSaga);

	// yield importView('taskMgmt', 'tasks');
	// yield importView('memberMgmt', 'members');
	// yield importView('wordMgmt', 'words');
	// yield importView('reportMgmt', 'reports');
	// yield importView('issueMgmt', 'issues');
	// yield importView('followupMgmt', 'followups');
	// yield importView('attachmentMgmt', 'attachments');
	// yield importView('templateMgmt', 'templates');
}
