import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'fixed-data-table-2';
import onClickOutside from 'react-onclickoutside';
import SizeMe from 'react-sizeme';

import ConnectedMenu from './connectedMenu';
import { clickAway } from './actions';

@connect(null, { clickAway })
@onClickOutside
class ClickAwayTable extends PureComponent {
	static propTypes = {
		whitelist : PropTypes.arrayOf(PropTypes.string).isRequired,
		clickAway : PropTypes.func.isRequired,
		size : PropTypes.object.isRequired,
		tableKey : PropTypes.string.isRequired,
	}
	static defaultProps = {
		whitelist : []
	}

	handleClickOutside(e) {
		let whitelisted = this.props.whitelist.some(whitelistElmId => {
			let elm = document.getElementById(whitelistElmId);
			return elm && elm.contains(e.target);
		});
		if (whitelisted)
			return;
		this.props.clickAway(this.props.tableKey);
	}

	render() {
		return <div ref={container => this._container = container}>
			<Table height={this.props.size.height} width={this.props.size.width} {...this.props}/>
			<ConnectedMenu />
		</div>;
	}
}

@SizeMe({ monitorHeight : true })
export default class AutoSizeClickAwayTable extends PureComponent {
	static propTypes = {
		containerStyle : PropTypes.object,
		containerClass : PropTypes.string,
	}
	render() {
		let { containerStyle, containerClass, ...otherProps } = this.props;
		return (
			<div style={containerStyle} className={containerClass}>
				<ClickAwayTable {...otherProps} />
			</div>
		);
	}
}
