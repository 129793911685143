import _ from 'lodash';

export default v => {
	if (v === null)
		return '';
	if (typeof v == 'boolean')
		return v;
	let valueString = JSON.stringify(v);
	valueString = valueString
		? valueString.slice(1, valueString.length - 1)
		: '';
	return valueString;
};

export const NUMBER_GETTER = v => {
	return !v && v != 0 ? '' : v.toString();
};

export const SCRIPT_GETTER = v => {
	return v;
};

export const BOOL_GETTER = v => {
	return Boolean(v);
};

export const OBJECT_GETTER = v => {
	return _.isObject(v) ? v : {};
};

export const OBJECT_TO_JSON_GETTER = v => {
	return JSON.stringify(v || {}, null, 4);
};

export const ARRAY_TO_JSON_GETTER = v => {
	return JSON.stringify(v || [], null, 4);
};

export const JSON_GETTER = v => {
	return v;
};
