import React, { PureComponent } from 'react';
import { PropTypes }      from 'prop-types';
import { connect }        from 'react-redux';
import { createSelector } from 'reselect';
import _                  from 'lodash';
import { css }            from 'aphrodite-jss';
import { Cell }           from 'fixed-data-table-2';
import FontIcon           from 'material-ui/FontIcon';
import IconButton         from 'material-ui/IconButton';

import { commitChanges, removeRecord } from './actions';
import styles from './controlCell.styles';

const selectRecord = (state, { data, rowIndex }) => data[rowIndex];
const selectChanged = (state, { data, rowIndex, selector, keyPath }) => selector(state).getIn(['changes', data[rowIndex][keyPath]]);
const checkIfNew = (state, { data, rowIndex, selector, keyPath }) => selector(state).get('newRecords').includes(data[rowIndex][keyPath]);

const mapStateToProps = createSelector(
	selectRecord,
	selectChanged,
	checkIfNew,
	(record, changedRecord, isNew) => ({ record, changedRecord, isNew, isChanged : changedRecord && !_.isEqual(record, changedRecord) })
);

const mapDispatchToProps = (dispatch) => ({
	dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
	...ownProps,
	...stateProps,
	...dispatchProps,
	commitChanges : () => {
		let { record, changedRecord, isNew, isChanged } = stateProps;
		let { onUpdate, onCreate, keyPath, tableKey } = ownProps;
		let { dispatch } = dispatchProps;
		let currRecord = changedRecord ? changedRecord : record;
		dispatch(commitChanges(record[keyPath], tableKey));
		if (onCreate && isNew)
			onCreate(currRecord);
		if (onUpdate && !isNew && isChanged)
			onUpdate(currRecord[keyPath], currRecord);
	},
	removeRecord : () => {
		let { isNew } = stateProps;
		let { onRemove, keyPath, tableKey, data, rowIndex } = ownProps;
		let { dispatch } = dispatchProps;
		let key = data[rowIndex][keyPath];
		dispatch(removeRecord(key, tableKey));
		if (onRemove && !isNew)
			onRemove(key);
	}
});

@connect(mapStateToProps, mapDispatchToProps, mergeProps)
export default class ControlCell extends PureComponent {

	static propTypes = {
		commitChanges : PropTypes.func.isRequired,
		removeRecord : PropTypes.func.isRequired,
		isChanged : PropTypes.bool.isRequired,
		isNew : PropTypes.bool.isRequired,
		record : PropTypes.object.isRequired,
		changedRecord : PropTypes.object
	}

	static defaultProps = {
		isChanged : false
	}

	render() {
		let { commitChanges, removeRecord, isChanged, isNew } = this.props;
		return (
			<Cell className={css(styles.cell)}>
				<IconButton
					style={styles.icon}
					onTouchTap={removeRecord}>
					<FontIcon style={styles.icon} className="material-icons">delete</FontIcon>
				</IconButton>
				{
					isChanged || isNew
						? <IconButton
							style={styles.icon}
							onTouchTap={commitChanges}>
							<FontIcon style={styles.icon} className="material-icons">save</FontIcon>
						</IconButton>
						: null
				}
			</Cell>
		);
	}
}
