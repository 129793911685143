import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'react-router-redux';
import Tappable from 'react-tappable/lib/Tappable';


const mapStateToProps = state => ({ projectID : state.app.currProject });
const mapDispatchToProps = dispatch => ({ dispatch });
const mergeProps = (stateProps, dispatchProps = {}, ownProps = {}) => ({
	...ownProps,
	...stateProps,
	...dispatchProps,
	checkDetail: () => {
		let searchKey = ownProps.type == 'task' ? ownProps.record._id : ownProps.record.tray;
		let path = `detail/projects/${stateProps.projectID}/${ownProps.type}/${searchKey}`;
		return dispatchProps.dispatch(push(path));
	}
});

@connect(mapStateToProps, mapDispatchToProps, mergeProps)
export default class TaskDetailLink extends PureComponent {
	static propTypes = {
		projectID : PropTypes.string.isRequired,
		checkDetail : PropTypes.func.isRequired,
		record: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
		type: PropTypes.string.isRequired,
	}
	render() {
		return (
			<Tappable
				component="div"
				onTap={this.props.checkDetail}>
				{this.props.type == 'task' ? this.props.record.name : this.props.record.tray}
			</Tappable>
		);
	}
}
