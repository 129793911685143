import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ContextMenu, MenuItem, connectMenu  } from 'react-contextmenu';

@connectMenu('updatableContextMenu')
export default class Menu extends PureComponent {
	static propTypes = {
		trigger : PropTypes.object
	}

	static defaultProps = {
		trigger : {}
	}

	render() {
		let { trigger } = this.props;
		trigger = trigger ? trigger : {};
		return <ContextMenu id="updatableContextMenu">
			<MenuItem onClick={trigger.copyCell}>
				複製
			</MenuItem>
			<MenuItem divider />
			<MenuItem onClick={trigger.pasteToCell}>
				貼上
			</MenuItem>
		</ContextMenu>;
	}
}
