import { StyleSheet } from 'aphrodite-jss';

export default StyleSheet.create({
	copyArea :{
		position : 'fixed',
		overflow : 'hidden',
		clip     : 'rect(0 0 0 0)',
		height   : 1,
		width    : 1,
		margin   : -1,
		padding  : 0,
		border   : 0
	},
	cell : {
		height : '100%'
	}
});
