import ObjectId from 'objectid';

import { BOOL_GETTER, ARRAY_TO_JSON_GETTER, NUMBER_GETTER } from 'commons/updatable/getters';
import { BooleanPresenter, JSONPresenter } from 'commons/updatable/presenters';
import { OBJECT_SETTER, NUMBER_SETTER } from 'commons/updatable/setters';
import { CheckboxEditor, JSONEditor } from 'commons/updatable/editors';

export default {
	paths : [{
		key         : '_id',
		name        : 'ID',
		show        : true,
		type        : 'string',
		initializer : () => ObjectId().toString()
	}, {
		key : 'label',
		name : '顯示文字',
		type : 'string',
		editable : true
	}, {
		key : 'tags',
		name : '類別',
		type : ['string'],
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		editable : true
	}, {
		key : 'unit',
		name : '單位',
		type : 'string',
		editable : true
	}, {
		key : 'prefix',
		name : '前綴',
		type : 'string',
		editable : true
	}, {
		key : 'suffix',
		name : '後綴',
		type : 'string',
		editable : true
	}, {
		key : 'isRoot',
		name : '首字',
		type : 'boolean',
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter,
		alwaysEditing : true,
		editable : true
	}, {
		key : 'hidden',
		name : '隱藏',
		type : 'boolean',
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter,
		alwaysEditing : true,
		editable : true
	}, {
		key : 'disabled',
		name : '停用',
		type : 'boolean',
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter,
		alwaysEditing : true,
		editable : true
	}, {
		key : 'functional',
		name : '功能字',
		type : 'boolean',
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter,
		alwaysEditing : true,
		editable : true
	}, {
		key : 'order',
		name : '次序',
		type : 'number',
		setter : NUMBER_SETTER,
		getter : NUMBER_GETTER,
		editable : true
	}, {
		key : 'color',
		name : '字體顏色',
		type : 'string',
		editable : true
	}, {
		key : 'buttonColor',
		name : '按鈕顏色',
		type : 'string',
		editable : true,
	}, {
		key : 'presetGroup',
		name : '預設群組',
		type : 'string',
		editable : true
	}, {
		key : 'initPreset',
		name : '首個預設群組',
		type : 'boolean',
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter,
		alwaysEditing : true,
		editable : true
	}, {
		key : 'nextGroup',
		name : '下個預設群組',
		type : 'string',
		editable : true
	}, {
		key : 'nextWords',
		name : '關聯字',
		type : ['string'],
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		editable : true
	}, {
		key : 'deleted',
		name : '已刪除',
		show : false,
		type : 'boolean',
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter,
		index : true
	}, {
		key  : 'updatedAt',
		type : 'string',
		name : '上次修改',
		editable : false
	}],
	autoIncrement : false,
	keyPath : '_id'
};
