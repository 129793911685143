import { put, call, takeLatest, cancelled } from 'redux-saga/effects';
import callAPI        from './callAPI';

function* getDetail(action) {
	let { projectID, type, searchKey } = action.payload;
	try {
		let url = `/api/projects/${projectID}/summary/detail/${type}/${searchKey}`;
		let { data } = yield call(callAPI, 'GET', url, {});

		yield put(new action.SUCCESS({ projectID, data, type, searchKey }));
	} catch(err) {
		yield put(new action.FAILURE({ projectID, type, searchKey, err }));
		throw err;
	} finally {
		if (yield cancelled()) {
			yield put(new action.FAILURE({ projectID, type, searchKey }));
		}
	}
}

export default function* notificationSaga() {
	yield takeLatest('GET_DETAIL', getDetail);
}
