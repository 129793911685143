import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isImmutable, List } from 'immutable';
import { createSelector } from 'reselect';
import moment from 'moment';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { css } from 'aphrodite-jss';

import ResponsiveTable from './responsiveTable';
import { Column } from 'fixed-data-table-2';
import RaisedButton       from 'material-ui/RaisedButton';
import FontIcon           from 'material-ui/FontIcon';
import EditableCell from './editableCell';
import SearchBar from './searchBar';
import Header from './header';
import ControlCell from './controlCell';

import styles from './updatable.styles';

import immutableSelector from 'utils/immutableSelector';
import { updateColumnWidth, pasteToCell, addRecord } from './actions';

export { default as createReducer } from './reducer';

const selectData = (state, { data }) => data;
const selectNewRecords = (state, { selector }) => selector(state).get('newRecords').map(recordID => selector(state).getIn(['changes', recordID]));
const selectSearchKeys = (state, { defaultSearchKeys, paths }) => {
	return new List(defaultSearchKeys || paths.filter(path => path.type == 'string').map(path => path.key));
};
const selectCriteria = createSelector(
	(state, { selector }) => selector(state).get('criteria'),
	(criteria = '') => {
		let queryRegex = /\[(.*?)\]/g;
		let searchAtRegex = /@(.+?)(?=[\s.,:,]|$)/g;
		let queries = (criteria.match(queryRegex) || []).reduce((result, queries) => {
			return {
				...result,
				...queries.replace(/\s|\[|\]/g, '').split(',').map(item => ({
					target : item.split(/=|>|>=|<|<=|!=|\[\]|\[\)|\(\]|\(\)/g),
					comparer : (item.match(/=|>|>=|<|<=|!=|\[\]|\[\)|\(\]|\(\)/g) || ['='])[0]
				})).reduce((result, { comparer, target : [key, value = ''] }) => {
					return key ? { ...result, [key] : { comparer, value : JSON.parse(value) }} : result;
				}, {})
			};
		}, {});
		let keyword = criteria.replace(queryRegex, '');
		let customSearchKeys = (keyword.match(searchAtRegex) || []).map(field => field.slice(1));
		keyword = keyword.replace(searchAtRegex, '').replace(/\s/g, '');
		return {
			keyword,
			queries,
			customSearchKeys
		};
	}
);

const selectWidths = (state, { selector }) => selector(state).get('widths');
const selectHeights = (state, { selector }) => selector(state).get('heights');

const selectInitializer = (state, { paths, initializer }) => initializer
	? initializer
	: () => _.reduce(paths, (result, path) => ({
		...result,
		[path.key] : path.initializer
			? path.initializer()
			: path.defaultValue
				? path.defaultValue
				: null
	}), {});

const mapStateToProps = immutableSelector(
	selectData,
	selectNewRecords,
	selectSearchKeys,
	selectCriteria,
	selectWidths,
	selectHeights,
	selectInitializer,
	(data, newRecords, searchKeys, { keyword, queries, customSearchKeys }, widths, heights, initializer) => {
		let searchResult = data
			? isImmutable(data) ? data.toArray() : data
			: [];
		if (!~customSearchKeys.indexOf('deleted'))
			searchResult = searchResult.filter(record => record.deleted !== true);
		if (keyword != '') {
			searchResult = new Fuse(searchResult, {
				keys : customSearchKeys.length
					? customSearchKeys
					: searchKeys.toJS(),
				threshold : 0.1
			}).search(keyword);
		}

		searchResult = searchResult.filter(item => {
			return _.every(queries, ({ value : targetValue, comparer }, key) => {
				let value = _.get(item, key);
				let isDate = moment(value, moment.ISO_8601, true).isValid();
				switch (comparer) {
				case '>':
					return isDate ? moment(value).isAfter(moment(targetValue)) : value > targetValue;
				case '>=':
					return isDate ? moment(value).isSameOrAfter(moment(targetValue)) : value >= targetValue;
				case '<':
					return isDate ? moment(value).isBefore(moment(targetValue)) : value < targetValue;
				case '<=':
					return isDate ? moment(value).isSameOrBefore(moment(targetValue)) : value <= targetValue;
				case '=':
					return isDate ? moment(value).isSame(moment(targetValue), 'day') : value == targetValue;
				case '!=':
					return isDate ? !moment(value).isSame(moment(targetValue), 'day') : value != targetValue;
				case '[]':
					return isDate ? !moment(value).isSame(moment(targetValue), 'day') : value != targetValue;
				default:
					return true;
				}
			});
		});
		return { data : [...newRecords.toJS(), ...searchResult], widths, initializer, heights };
	}
);

const mapDispatchToProps = (dispatch, { tableKey, paths, keyPath }) => ({
	updateColumnWidth : (width, key) => dispatch(updateColumnWidth(key, width, tableKey)),
	// copyCell : value => dispatch(copyCell(value, tableKey)),
	pasteToCell : v => dispatch(pasteToCell(v, paths, keyPath, tableKey)),
	addRecord : (key, newRecord) => dispatch(addRecord(key, newRecord, tableKey))
});


@connect(mapStateToProps, mapDispatchToProps)
export default class Updatable extends PureComponent {
	static propTypes = {
		editable : PropTypes.bool.isRequired,
		selectable : PropTypes.bool.isRequired,
		clickAwayWhitelist : PropTypes.arrayOf(PropTypes.string).isRequired,
		keyPath : PropTypes.string.isRequired,
		widths : PropTypes.object.isRequired,
		heightEstimater : PropTypes.func.isRequired,
		paths    : PropTypes.arrayOf(PropTypes.object).isRequired,
		tableKey : PropTypes.string.isRequired,
		selector : PropTypes.func.isRequired,
		rowHeight : PropTypes.number,
		data : PropTypes.arrayOf(PropTypes.object).isRequired,
		updateColumnWidth : PropTypes.func.isRequired,
		// copyCell : PropTypes.func.isRequired,
		pasteToCell : PropTypes.func.isRequired,
		addRecord : PropTypes.func.isRequired,
		initializer : PropTypes.func.isRequired,
		maxHeight : PropTypes.number
	}

	static defaultProps = {
		editable : true,
		selectable : true,
		keyPath : '_id',
		clickAwayWhitelist : [],
		heightEstimater : (data, rowIndex, paths) => _.max([45, ...paths.map(path => {
			try {
				let value = data[rowIndex][path.key];
				try {
					value = JSON.parse(value);
				} catch(err) {
					value = data[rowIndex][path.key];
				}
				let stringData = JSON.stringify(value, null, 2);
				let numberOfRows = stringData.split(/\r\n|\n|\r/g).length;
				return numberOfRows*17 + 28;
			} catch(err) {
				return 45;
			}
		})])
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyDown, false);
		document.addEventListener('keyup', this.handleKeyUp, false);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown, false);
		document.removeEventListener('keyup', this.handleKeyUp, false);
	}

	render() {
		let { rowHeight, tableKey, paths, selector, widths, updateColumnWidth, data, addRecord, keyPath, initializer, heightEstimater, maxHeight, editable, selectable } = this.props;
		return (

			<div className={css(styles.container)}>
				<div className={css(styles.ctrlCtn)}>
					<SearchBar selector={selector} tableKey={tableKey}/>
					{
						editable
							? <div className={css(styles.addBtn)}>
								<RaisedButton
									className={css(styles.addButtonStyle)}
									label="新增"
									icon={<FontIcon className="material-icons">add</FontIcon>}
									onTouchTap={() => {
										let newRecord = initializer();
										addRecord(newRecord[keyPath], newRecord);
									}}
								/>
							</div>
							: null
					}
				</div>
				<ResponsiveTable
					whitelist={[...this.props.clickAwayWhitelist, 'updatable:jsoneditor']}
					tableKey={tableKey}
					containerClass={css(styles.tableCtn)}
					headerHeight={60}
					rowsCount={data.length}
					rowHeight={rowHeight || 40}
					rowHeightGetter={rowHeight ? undefined : rowIndex => heightEstimater(data, rowIndex, paths)}
					isColumnResizing={false}
					touchScrollEnabled={true}
					onColumnResizeEndCallback={updateColumnWidth}
					allowCellsRecycling={true}
					{...this.props}>
					{
						_.reduce(paths, (columns, path, i) => {
							let {
								name,
								key,
								show = true,
								defaultWidth = 120,
								width = 1,
								minWidth = 120
							} = path;

							if (show)
								columns.push(
									<Column
										key={i}
										columnKey={key}
										isResizable={true}
										flexGrow={width}
										header={<Header title={`${name}(${key})`}/>}
										width={Math.max(widths.get(key) || defaultWidth, minWidth)}
										minWidth={minWidth}
										pureRendering={true}
										cell={<EditableCell
											{...path}
											editable={editable}
											selectable={selectable}
											rowHeight={rowHeight}
											maxHeight={maxHeight}
											key={key}
											data={data}
											keyPath={keyPath}
											tableKey={tableKey}
											selector={selector}
										/>}
									/>
								);
							return columns;
						}, [])
					}
					{
						editable
							? <Column
								columnKey="__controls"
								isResizable={true}
								header={<Header title={''}/>}
								width={widths.get('__controls') || 150}
								cell={<ControlCell {...this.props} />}
							/>
							: null
					}
				</ResponsiveTable>
				{/* <Clipboard value={copyValue} onPaste={e => pasteToCell(e.clipboardData.getData('text'))}/> */}
			</div>
		);
	}
}
