import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RaisedButton, Card, CardActions, CardHeader } from 'material-ui';

import { GO_TO } from '../actions';

const NotFound = class NotFound extends PureComponent {
	static propTypes = {
		goTo : PropTypes.func.isRequired
	}
	render() {
		return (
			<Card>
				<CardHeader title="Page not found"/>
				<CardActions>
					<RaisedButton label="BACK TO HOME" primary={true} onClick={this.props.goTo} />
				</CardActions>
			</Card>
		);
	}
};

const mapDispatchToPureComponent = dispatch => ({
	goTo : () => {
		dispatch(new GO_TO({path : 'home'}));
	}
});

export default connect(
	() => ({}),
	mapDispatchToPureComponent
)(NotFound);
