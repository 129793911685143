import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { css } from 'aphrodite-jss';

import styles from './trafficLight.styles';

export default class TrafficLight extends Component {

	static propTypes = {
		currValue : PropTypes.object.isRequired
	}

	static defaultProps = {
		currValue : {}
	}

	shouldComponentUpdate(nextProps) {
		return !isEqual(this.props.currValue, nextProps.currValue);
	}

	render() {
		let { await_repair = 0, await_accept = 0, await_inspect = 0, accepted = 0, noOfIssues } = this.props.currValue;
		return <center className={css(styles.container)}>
			<Doughnut
				options={{ showTooltips : false, animation : false, }}
				height={60}
				width={60}
				data={[{
					value : await_repair,
					label : '未處理',
					color : '#EF5350'
				}, {
					value : await_inspect,
					label : '待檢查',
					color : '#FFCE56'
				}, {
					value : await_accept,
					label : '待接受',
					color : '#2196F3'
				}, {
					value : accepted,
					label : '已接受',
					color : '#4BC0C0'
				}, {
					value : noOfIssues == 0 || isEmpty(this.props.currValue) ? 1 : 0,
					label : '無資料',
					color : '#757575'
				}]}
			/>
			<center>{await_repair}</center>
		</center>;
	}

}
