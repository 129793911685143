import users         from './users';
import roles         from './roles';
import projects      from './projects';
import modules       from './modules';
import meta          from './meta';

export default {
	users,
	roles,
	projects,
	modules,
	meta
};
