const initState = {
	date : null,
	groupBy : 'task'
};

export default (state = initState, action) => {
	let { type, payload = {} } = action;
	switch (type) {
	case 'CHANGE_DATE':
		return { ...state, date : payload.date };
	case 'UPDATE_GROUP':
		return { ...state, groupBy : payload.groupBy };
	default:
		return state;
	}
};
