import ObjectId from 'objectid';

export default {
	paths : [{
		key : '_id',
		name : 'ID',
		type : 'string',
		show : true,
		initializer : () => ObjectId().toString()
	}, {
		key  : 'username',
		type : 'string',
		name : '帳號',
		editable : false,
		creatable : true
	}, {
		key  : 'name',
		type : 'string',
		name : '用戶名稱',
		editable : true
	}, {
		key  : 'newPassword',
		type : 'string',
		name : '新密碼',
		editable : true
	}, {
		key  : 'oldPassword',
		type : 'string',
		name : '舊密碼',
		editable : true
	}, {
		key  : 'updatedAt',
		type : 'string',
		name : '上次修改',
		editable : false
	}],
	keyPath : '_id'
};
