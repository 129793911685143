import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Route, Switch, Redirect, withRouter } from 'react-router';

import Dashboard from './modules/Dashboard';
import routes from './routes';

class Root extends PureComponent {
	render() {
		return (
			<Route path="/" render={() => {
				return (
					<Dashboard>
						<Switch>
							<Route exact path="/">
								<Redirect to="/home" />
							</Route>
							{
								_.map(routes, (route, i) => {
									return <Route exact key={i} path={route.path} component={route.component} />;
								})
							}
						</Switch>
					</Dashboard>
				);
			}} />
		);
	}
}

export default withRouter(Root);