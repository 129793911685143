import React, { PureComponent } from 'react';
// import { Route, Redirect, Switch }  from 'react-router';
import { Provider }         from 'react-redux';
import injectTapEventPlugin from 'react-tap-event-plugin';
import MuiThemeProvider     from 'material-ui/styles/MuiThemeProvider';
import muiTheme             from '../muiTheme';
import Promise              from 'bluebird';
import { ConnectedRouter }  from 'react-router-redux';
// import map                  from 'lodash/map';
import { hot } from 'react-hot-loader';

window.Promise = Promise;

import store  from './store';
import Root from './Root';

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
injectTapEventPlugin();

class App extends PureComponent {
	render() {
		return (
			<Provider store={store}>
				<MuiThemeProvider muiTheme={muiTheme}>
					<ConnectedRouter history={store.history}>
						<Root />
					</ConnectedRouter>
				</MuiThemeProvider>
			</Provider>
		);
	}
}

export default hot(module)(App);