import ObjectId from 'objectid';

import { BOOL_GETTER, JSON_GETTER, SCRIPT_GETTER } from 'commons/updatable/getters';
import { BooleanPresenter, ScriptPresenter, JSONPresenter, FilePresenter } from 'commons/updatable/presenters';
// import { SCRIPT_SETTER } from 'commons/updatable/setters';
import { CheckboxEditor, ScriptEditor, JSONEditor, FileEditor } from 'commons/updatable/editors';

import store  from '../../../dashboard/store';

export default {
	paths : [{
		key         : '_id',
		name        : 'ID',
		type        : 'string',
		show        : true,
		initializer : () => ObjectId().toString()
	}, {
		key  : 'name',
		type : 'string',
		name : '模板名稱',
		editable : true
	}, {
		key  : 'useCustom',
		type : 'boolean',
		name : '自訂',
		editable : true,
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter,
	}, {
		key  : 'includeDropped',
		type : 'boolean',
		name : '顯示已刪除',
		editable : true,
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter,
	}, {
		key : 'extractor',
		name : '提取標籤',
		type : 'json',
		minWidth : 350,
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : JSON_GETTER,
		editable : true,
		popup : true,
		initializer : () => '',
	}, {
		key : 'selector',
		name : '資料選取',
		type : 'json',
		minWidth : 350,
		initializer : () => '',
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : JSON_GETTER,
		editable : true,
		popup : true
	}, {
		key : 'grouper',
		name : '分組',
		type : 'json',
		minWidth : 350,
		initializer : () => '',
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : JSON_GETTER,
		editable : true,
		popup : true
	}, {
		key : 'groupSorter',
		name : '組別排序',
		type : 'json',
		initializer : () => '',
		minWidth : 350,
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : JSON_GETTER,
		editable : true,
		popup : true
	}, {
		key : 'reducer',
		name : '匯出方法',
		type : 'script',
		minWidth : 350,
		editor : ScriptEditor,
		presenter : ScriptPresenter,
		getter : SCRIPT_GETTER,
		editable : true,
		popup : true,
		initializer : () => '',
	}, {
		key  : 'file',
		type : 'file',
		name : '模板檔案',
		editable : true,
		setter : (v, record) => {
			return {...record, file : v.data, filename : v.name, mimeType : v.type };
		},
		getter : (v, record) => {
			let state = store.getState();
			// let urlparts = (record.uri || '').split('/');
			return {
				url : record.uri || '',
				name : record.filename,
				auth : {
					Authorization : 'Bearer '+ state.app.user.token
				},
				// mimeType : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
			};
		},
		presenter : FilePresenter,
		editor : FileEditor,
		// filenameField : 'filename',
		// fileDataField : 'file',
		// fileUriField : 'uri',
		// getFileName : record => `${record.filename}`,
		// getToken : user => user.token
	}, {
		key : 'filename',
		type : 'string',
		name : '模板檔案名稱',
		editable : true,
	}, {
		key  : 'extraFields',
		type : 'json',
		minWidth : 350,
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : JSON_GETTER,
		name : '補充資料',
		editable : true,
		initializer : () => '',
	}, {
		key  : 'includeTaskReference',
		type : 'boolean',
		name : '載入工作資料',
		editable : true,
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter,
		alwaysEditing : true
	}, {
		key  : 'includeAttachments',
		type : 'boolean',
		name : '載入附件',
		editable : true,
		getter : BOOL_GETTER,
		editor : CheckboxEditor,
		presenter : BooleanPresenter,
		alwaysEditing : true
	}, {
		key : 'exportExtension',
		type : 'string',
		name : '匯出檔案類型',
		editable : true,
	}, {
		key  : 'updatedAt',
		type : 'string',
		name : '上次修改',
		editable : false
	}],
	keyPath : '_id'
};
