import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';

const loadingContStyle = {
	flex : 1,
	display       : 'flex',
	flexDirection : 'row',
	alignItems    : 'center',
	justifyContent : 'center'
};

const loadingStyle = {
	margin : 'auto'
};

export default function LoadingIndicator() {
	return (
		<div style={loadingContStyle}>
			<CircularProgress style={loadingStyle} mode="indeterminate" />
		</div>
	);
}
