import ObjectId from 'objectid';

import { ARRAY_TO_JSON_GETTER } from 'commons/updatable/getters';
import { JSONPresenter } from 'commons/updatable/presenters';
import { OBJECT_SETTER } from 'commons/updatable/setters';
import { JSONEditor } from 'commons/updatable/editors';

export default {
	paths : [{
		key : '_id',
		type : 'string',
		name : 'ID',
		show : false,
		initializer : () => ObjectId().toString()
	}, {
		key  : 'followupTo',
		type : 'string',
		name : '跟進事故',
		editable : false
	}, {
		key  : 'attachments',
		type : ['string'],
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		name : '附件',
		editable : true
	}, {
		key  : 'remark',
		type : 'string',
		name : '備註',
		editable : false
	// }, {
	// 	key  : 'approved',
	// 	type : 'boolean',
	// 	name : '已確認',
	// 	getter : BOOL_GETTER,
	// 	editor : CheckboxEditor,
	// 	presenter : BooleanPresenter,
	// 	editable : false
	}, {
		key  : 'status',
		type : 'string',
		name : '狀況',
		editable : false
	}, {
		key  : 'updatedAt',
		type : 'string',
		name : '上次修改',
		editable : false
	}],
	keyPath : '_id'
};
