export default {
	main : {
		margin : '16px',
		display : 'flex',
		flexDirection : 'column',
		flex : 1,
	},
	mainContainer : {
		flex : 1,
		display : 'flex',
		flexDirection : 'column'
	},
	table : {
		flex : 1,
		display : 'flex',
		flexDirection : 'column'
	},
	ctrlCtn : {
		display : 'flex',
		flexDirection : 'row'
	},
	groupCtrl : {
		display : 'flex',
		flex : 1,
		flexDirection : 'row',
		justifyContent : 'flex-end'
	}
};
