import { blueGrey400 } from 'material-ui/styles/colors';

export default {
	main : {
		margin : '16px',
		display : 'flex',
		flexDirection : 'column',
		flex : 1,
	},
	mainContainer : {
		flex : 1,
		display : 'flex',
		flexDirection : 'column'
	},
	table : {
		flex : 1,
		display : 'flex',
		flexDirection : 'column'
	},
	notiItem : {
		display: 'flex',
		flex : 1,
		flexDirection : 'row',
		height : '36px',
		margin : '6px',
		fontSize : '18px',
		alignItems : 'center'
	},
	notiType : {
		width : '200px'
	},
	notiContent : {
		flex : 1
	},
	notiHeader : {
		display: 'flex',
		flex : 1,
		flexDirection : 'row'
	},
	notiTypeHeader : {
		width : '200px',
		fontSize : '22px',
		color : blueGrey400
	},
	notiContentHeader : {
		flex : 1,
		fontSize : '22px',
		color : blueGrey400
	},
};
