import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

import {
	Card, CardTitle
} from 'material-ui/Card';

import Template from './template';

import styles from './styles';
import ProjectEnsured from '../projectEnsurer';
import Loadable from 'commons/loadable';
import { GET_DATA } from '../../actions';


const mapLoadableState = createSelector(
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'loading', 'templates', projectID]),
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'initialized', 'templates', projectID]),
	(loading, initialized) => ({ loading, initialized })
);

const selectTemplates = (state, { projectID }) => state.data.getIn(['projectDB', 'templates', projectID]) || new Map();

const mapStateToProps = createSelector(
	selectTemplates,
	(templates) => ({
		templates : templates.filter(template => template.get('deleted') != true),
	})
);

const mapDispatchToGetData = dispatch => ({
	getData : ({ projectID }) => dispatch(
		new GET_DATA({
			models : [{ model : 'templates', projectID}]
		})
	),
});

@ProjectEnsured
@connect(mapLoadableState, mapDispatchToGetData)
@Loadable
@connect(mapStateToProps)
class ReportExportContent extends PureComponent {
	static propTypes = {
		projectID        : PropTypes.string.isRequired,
		templates        : PropTypes.object.isRequired
	}

	renderTemplates() {
		return this.props.templates.map((template, _id) => (
			<Template
				key={_id}
				projectID={this.props.projectID}
				template={template}
			/>
		)).toArray();
	}

	render() {
		return (
			<div style={styles.menu}>
				{this.renderTemplates()}
			</div>
		);
	}
}

export default class ReportExport extends PureComponent {
	render() {
		return <div style={styles.container}>
			<div style={styles.headerCtn}>
				<Card style={styles.header}>
					<CardTitle
						title="匯出報告"
						subtitle="Report Export"
					/>
				</Card>
			</div>
			<div style={styles.main}>
				<ReportExportContent />
			</div>
		</div>;
	}
}
