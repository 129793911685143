import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import TextField from 'material-ui/TextField';

import { UPDATE_EXTRA_FIELD } from '../actions';
import styles from './styles';

const mapStateToProps = createSelector(
	(state, { projectID, templateID, fieldKey }) => state.ui.reportExport.getIn([projectID, templateID, 'extraFields', fieldKey]),
	value => ({ value })
);

const mapDispatchToProps = (dispatch, { projectID, templateID, fieldKey }) => ({
	updateExtraField : ({ target : { value } }) => dispatch(new UPDATE_EXTRA_FIELD({ projectID, templateID, fieldKey, value })),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class ExtraFieldInput extends PureComponent {
	static propTypes = {
		projectID        : PropTypes.string.isRequired,
		templateID       : PropTypes.string.isRequired,
		value            : PropTypes.any.isRequired,
		fieldKey         : PropTypes.string.isRequired,
		label            : PropTypes.string.isRequired,
		inputType        : PropTypes.string.isRequired,
		updateExtraField : PropTypes.func.isRequired
	}

	static defaultProps = {
		value : '',
		inputType : 'text'
	}

	renderTextInput() {
		return <TextField
			id={new Date().getTime().toString()}
			style={styles.textInput}
			value={this.props.value}
			onChange={this.props.updateExtraField}
		/>;
	}

	render() {
		let { fieldKey, label, inputType } = this.props;
		let input;
		switch (inputType) {
		case 'text':
			input = this.renderTextInput(fieldKey);
			break;
		case 'number':
			input = this.renderNumberInput(fieldKey);
			break;
		case 'select':
			input = this.renderSelectInput(fieldKey);
			break;
		case 'datePicker':
			input = this.renderDatePicker(fieldKey);
			break;
		default:
			input = this.renderTextInput(fieldKey);
		}
		return <div style={styles.extraFieldCtn}>
			<div style={styles.menuItemLabel}>{label}</div>
			<div style={styles.menuItemPlaceholder}></div>
			<div style={styles.menuItemCtrl}>
				{input}
			</div>
		</div>;
	}

}
