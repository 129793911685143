import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WidthProvider }  from 'react-grid-layout';
import Snackbar           from 'material-ui/Snackbar';
import { connect }        from 'react-redux';
import { createSelector } from 'reselect';

import NavBar  from './UIs/navBar';
import ToolBar from './UIs/toolBar';
import Loading from 'commons/loadingIndicator';
// import Grid    from 'commons/grid/autoResizeGrid';
import styles from './app.styles';

import {
	GET_TOKEN, UPDATE_WIDTH,
	HIDE_ERROR
} from '../actions';

class Dashbaord extends Component {
	static propTypes = {
		errMsg      : PropTypes.string,
		children    : PropTypes.element,
		loading     : PropTypes.bool.isRequired,
		width       : PropTypes.number.isRequired,
		getToken    : PropTypes.func.isRequired,
		updateWidth : PropTypes.func.isRequired,
		showError   : PropTypes.bool.isRequired,
		hideError   : PropTypes.func.isRequired,
		errCallback : PropTypes.func
	}

	componentWillMount() {
		this.props.getToken();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.width != this.props.width)
			this.props.updateWidth(nextProps.width);
	}

	render() {
		const marginLeft = this.props.width > 996 ? '150px' : '0';
		return (
			<div style={styles.root}>
				{
					this.props.loading
						? <Loading />
						: <div style={styles.root}>
							<NavBar />
							<div
								style={{ ...styles.main, marginLeft}}>
								<ToolBar />
								{this.props.children}
							</div>

						</div>
				}
				<Snackbar
					open={this.props.showError}
					onActionTouchTap={this.props.errCallback}
					message={this.props.errMsg}
					autoHideDuration={this.props.errCallback ? undefined : 30000}
					onRequestClose={this.props.hideError}
					action="Confirm"
				/>
			</div>
		);
	}

}

const mapStateToPureComponent = createSelector(
	state => state.app.user.loading,
	state => state.ui.general.showError,
	state => state.ui.general.errMsg,
	state => state.ui.general.errCallback,
	(loading, showError, errMsg, errCallback) => ({ loading, showError, errMsg, errCallback })
);

const mapDispatchToPureComponent = dispatch => ({
	getToken : () => dispatch(new GET_TOKEN()),
	updateWidth : width => dispatch(new UPDATE_WIDTH({ width })),
	hideError : () => dispatch(new HIDE_ERROR())
});

export default connect(
	mapStateToPureComponent,
	mapDispatchToPureComponent
)(WidthProvider(Dashbaord));
