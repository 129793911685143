const initState = {
	width : 900,
	showError : false,
	errMsg : '',
	errCallback : null
};

export default (state = initState, action) => {
	switch (action.type) {
	case 'UPDATE_WIDTH':
		return { ...state, width : action.payload.width };
	case 'HIDE_ERROR':
		return { ...state, showError : false, errMsg : '' };
	case 'SHOW_ERROR':
		return { ...state, showError : true, errMsg : action.payload.errMsg, errCallback : action.payload.errCallback };
	default:
		return state;
	}
};
