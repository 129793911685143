import React, { PureComponent} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import _ from 'lodash';

import TextField from 'material-ui/TextField';

import { updateCriteria } from './actions';
import styles from './searchBar.styles';

const mapStateToProps = createSelector(
	(state, { selector }) => selector(state).get('criteria'),
	(criteria = '') => ({ criteria })
);

const mapDispatchtoProps = dispatch => ({
	updateCriteria :  _.debounce((criteria, tableKey) =>dispatch(updateCriteria(criteria, tableKey)), 500)
});

@connect(mapStateToProps, mapDispatchtoProps)
export default class SearchBar extends PureComponent {
	static displayName = 'SearchBar'
	static propTypes = {
		tableKey : PropTypes.string.isRequired,
		criteria : PropTypes.string.isRequired,
		updateCriteria : PropTypes.func.isRequired
	}
	render() {
		return <div style={styles.container}>
			<TextField
				inputStyle={styles.textInput}
				name="searchBar"
				floatingLabelText="搜尋"
				onChange={(e, v) => this.props.updateCriteria(v, this.props.tableKey)}
			/>
		</div>;
	}
}
