import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';


import Loading from 'commons/loadingIndicator';

export default ScenePureComponent => {

	const ProjectIDEnsured = class ProjectIDEnsured extends PureComponent {
		static displayName = `ProjectEnsured(${ScenePureComponent.displayName})`
		static propTypes = {
			loading     : PropTypes.bool.isRequired,
			initialized : PropTypes.bool.isRequired,
			projectID   : PropTypes.string,
		}

		render() {
			let { projectID, loading, initialized, ...otherProps } = this.props;
			return loading || !initialized
				? <Loading />
				: projectID != ''
					? <ScenePureComponent projectID={projectID} {...otherProps}/>
					: <h2>請選先擇項目</h2>;
		}
	};

	const mapStateToProps = createSelector(
		state => state.data.getIn(['adminDB', 'loading', 'projects']),
		state => state.data.getIn(['adminDB', 'initialized', 'projects']),
		state => state.app.currProject,
		(loading, initialized, projectID) => ({ loading, initialized, projectID })
	);

	return connect(mapStateToProps)(ProjectIDEnsured);
};
