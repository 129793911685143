const initState = 14;

export default (state = initState, action) => {
	let { type, payload = {} } = action;
	switch (type) {
	case 'UPDATE_NOTI_LIMIT':
		return payload.dateLimit;
	default:
		return state;
	}
};
