import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ReactList from 'react-list';
import { push } from 'react-router-redux/actions';
import { List } from 'immutable';
import TextField from 'material-ui/TextField';

import ProjectEnsured from './projectEnsurer';
import Loadable from 'commons/loadable';
import immutableSelector from 'utils/immutableSelector';

import {
	Card, CardTitle, CardText
} from 'material-ui/Card';
import Divider from 'material-ui/Divider';
import Tappable from 'react-tappable/lib/Tappable';

import styles from './notification.styles';

import { GET_NOTIFICATION, UPDATE_NOTI_LIMIT } from './actions';

const selectLoading = (state, { projectID }) => state.app.notification.loading.getIn([projectID, state.app.notification.dateLimit]);
const selectInitized = (state, { projectID }) => state.app.notification.initialized.getIn([projectID, state.app.notification.dateLimit]);

const mapLoadableState = createSelector(
	selectLoading,
	selectInitized,
	state => state.app.notification.dateLimit,
	(loading = false, initialized = false, dateLimit) => ({ loading, initialized, dateLimit })
);

const mapDispatchToGetData = dispatch => ({
	getData : ({ projectID, dateLimit }) => dispatch(
		new GET_NOTIFICATION({ projectID, dateLimit })
	)
});

const mapDateListToProps = immutableSelector(
	state => state.app.notification.dateLimit,
	(dateLimit) => ({ dateLimit })
);

const mapStateToProps = immutableSelector(
	(state, { projectID }) => (state.app.notification.data.getIn([projectID, state.app.notification.dateLimit]) || new List()).toJS(),
	state => state.app.notification.dateLimit,
	(notifications, dateLimit) => ({ notifications, dateLimit })
);

const mapDispatchToProps = (dispatch, { projectID }) => ({
	checkDetail : taskID => dispatch(push(`newTaskDetail/projects/${projectID}/tasks/${taskID}`)),
	updateNotiLimit : dateLimit => dispatch(new UPDATE_NOTI_LIMIT({ dateLimit }))
});

@ProjectEnsured
@connect(mapLoadableState, mapDispatchToGetData)
@Loadable
@connect(mapStateToProps, mapDispatchToProps)
class NotiContent extends PureComponent {
	static propTypes = {
		dateLimit       : PropTypes.number.isRequired,
		projectID       : PropTypes.string.isRequired,
		notifications   : PropTypes.arrayOf(PropTypes.object).isRequired,
		getData         : PropTypes.func.isRequired,
		checkDetail     : PropTypes.func.isRequired,
		updateNotiLimit : PropTypes.func.isRequired,
	}

	static defaultProps = {
		projectID : '',
		notifications : [],
		getData : () => {},
		checkDetail : () => {}
	}

	renderItem = (i, key) => {
		let { content, _id } = this.props.notifications[i];
		let isLastItem = this.props.notifications.length-1 == i;
		return (
			<Tappable
				component="div"
				key={key}
				onTap={() => this.props.checkDetail(_id)}>
				<div style={styles.notiItem}>
					<div style={styles.notiType}>過期通知</div>
					<div style={styles.notiContent}>{ content }</div>
				</div>
				{
					isLastItem
						? null
						: <Divider />
				}
			</Tappable>
		);
	}

	render() {
		return (
			<div>
				<div style={styles.notiHeader}>
					<div style={styles.notiTypeHeader}>類型</div>
					<div style={styles.notiContentHeader}>內容</div>
				</div>
				<ReactList
					type='uniform'
					itemRenderer={this.renderItem}
					length={this.props.notifications.length}
				/>
			</div>
		);
	}
}

@connect(mapDateListToProps, mapDispatchToProps)
export default class Notification extends PureComponent {

	static propTypes = {
		dateLimit : PropTypes.number.isRequired,
		updateNotiLimit : PropTypes.func.isRequired,
	}

	render() {
		return (
			<Card
				style={styles.main} containerStyle={styles.mainContainer}>
				<CardTitle
					title="通知"
					subtitle="Notifications"
				/>
				<CardText
					style={{
						height : '900px',
						overflow: 'auto'
					}}>
					<TextField
						floatingLabelText="限期"
						step={1}
						min={1}
						type="number"
						value={this.props.dateLimit.toString()}
						onChange={(e, v) => this.props.updateNotiLimit(parseInt(v))}
					/>
					<NotiContent {...this.props}/>
				</CardText>
			</Card>
		);
	}
}
