export const readPromise = function(file) {
	return new Promise((resolve, reject) => {
		try {
			let reader = new FileReader();

			reader.onload = () => {
				resolve({
					name : file.name,
					type : file.type,
					data : reader.result.split(',')[1]
				});
			};
			reader.readAsDataURL(file);

		} catch(err) {
			reject(err);
		}
	});
};

const dummyLinkTag = document.createElement('a');
dummyLinkTag.style = 'display: none';

export function saveBlob(data, fileName) {
	let url = window.URL.createObjectURL(data);
	dummyLinkTag.href = url;
	dummyLinkTag.download = fileName;
	dummyLinkTag.click();
	window.URL.revokeObjectURL(url);
}

export function base64toBlob(base64Data, contentType) {
	contentType = contentType || '';
	var sliceSize = 1024;
	var byteCharacters = atob(base64Data);
	var bytesLength = byteCharacters.length;
	var slicesCount = Math.ceil(bytesLength / sliceSize);
	var byteArrays = new Array(slicesCount);

	for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
		var begin = sliceIndex * sliceSize;
		var end = Math.min(begin + sliceSize, bytesLength);

		var bytes = new Array(end - begin);
		for (var offset = begin, i = 0 ; offset < end; ++i, ++offset) {
			bytes[i] = byteCharacters[offset].charCodeAt(0);
		}
		byteArrays[sliceIndex] = new Uint8Array(bytes);
	}
	return new Blob(byteArrays, { type: contentType });
}


export function arrayBuffertoBlob(arrayBuffer, contentType) {
	contentType = contentType || '';
	return new Blob([arrayBuffer], { type: contentType });
}
