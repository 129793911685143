import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect }        from 'react-redux';
import { createSelector } from 'reselect';
import _ from 'lodash';
import mustache from 'mustache';

import {
	Card, CardHeader, CardText,
} from 'material-ui/Card';


import Loading       from 'commons/loadingIndicator';
import styles from './home.styles';

let Home = class Home extends PureComponent {
	static propTypes = {
		user     : PropTypes.object.isRequired,
		loading  : PropTypes.bool.isRequired
	}

	render() {
		return this.props.loading
			? <Loading />
			: <Card
				style={styles.container}>
				<CardHeader
					title={this.props.user.name}
					subtitle={this.props.user.username}
					titleStyle={styles.title}
					subtitleStyle={styles.subTitle}
				/>
				<CardHeader
					title="身份"
					titleStyle={styles.title}
				/>
				<CardText expandable={false}>
					<div style={styles.rolesCtn}>
						{
							this.props.user.roles.map(({ role : { name, label }, scope = {} }, i) => (
								<div style={styles.roleCtn} key={i}>
									{
										label
											? mustache.render(label, scope)
											: `${_.reduce(scope, (result, data, category) => `${result}${category}/${data}/`, '/')}${name}`
									}
								</div>
							))
						}
						<div style={styles.placeholder}/>
					</div>
				</CardText>
			</Card>;
	}
};

const mapStateToPureComponent = createSelector(
	state => state.app.user,
	state => state.app.user.loading,
	(user, loading) => ({ user, loading })
);



export default connect(
	mapStateToPureComponent
)(Home);
