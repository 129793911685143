import { put, apply } from 'redux-saga/effects';
import { SHOW_ERROR } from '../actions';
import isString from 'lodash/isString';

import configs from '../../configs';


export default function* logout() {
	try {
		yield apply(localStorage, localStorage.removeItem, ['token']);
		console.log(configs.URL_ROOT, process.env.NODE_ENV);
		window.location.assign(`${configs.URL_ROOT}/login`);
	} catch(err) {
		let _err = err;
		if (isString(err)) {
			_err = {
				message : err
			};
		}
		yield put(new SHOW_ERROR({ errMsg : _err.message || _err.name || 'UNEXPECTED ERROR'}));
	}
}
