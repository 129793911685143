import ObjectId from 'objectid';

export default {
	paths : [{
		key : '_id',
		name : 'ID',
		type : 'string',
		show : false,
		initializer : () => ObjectId().toString()
	}, {
		key  : 'name',
		type : 'string',
		name : '名稱',
		editable : true
	}, {
		key  : 'kind',
		type : 'string',
		name : '類型',
		editable : true
	}, {
		key  : 'script',
		type : 'script',
		name : '程式碼',
		editable : false
	}],
	keyPath : '_id'
};
