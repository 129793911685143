import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/mode/javascript';
import 'brace/mode/json';
import 'brace/theme/github';
import 'brace/ext/searchbox';

import Dialog     from 'material-ui/Dialog';

import Checkbox  from 'material-ui/Checkbox';
import FlatButton from 'material-ui/FlatButton';
import FontIcon from 'material-ui/FontIcon';

import styles from './editors.styles';

import { readPromise } from 'utils/fileUploadHelper';

export default class DefaultEditor extends PureComponent {

	static propTypes = {
		currValue : PropTypes.string.isRequired,
		origValue : PropTypes.string.isRequired,
		onChange : PropTypes.func.isRequired
	}

	componentWillUnmount() {
		this.props.onChange(this._input.value);
	}

	render() {
		return (
			<div style={styles.inputCtn}>
				<input style={styles.input} ref={input => this._input = input} type="text" defaultValue={this.props.currValue} />
			</div>
		);
	}
}

export class JSONEditor extends PureComponent {
	static propTypes = {
		currValue : PropTypes.string.isRequired,
		origValue : PropTypes.string.isRequired,
		onChange : PropTypes.func.isRequired,
		isEditing : PropTypes.bool.isRequired,
		// finishEdiiting : PropTypes.func.isRequired
	}

	constructor(props) {
		super(props);
		this.state = {
			value : props.currValue
		};
	}

	componentWillUnmount() {
		this.props.onChange(this.state.value);
	}

	render() {
		// console.log('rerender editor', this.props.currValue);
		return (
			<Dialog
				contentStyle={styles.modal}
				modal={false}
				open={this.props.isEditing}>
				<AceEditor
					value={this.state.value}
					mode="json"
					theme="github"
					width={'100%'}
					maxLines={30}
					minLines={30}
					name="updatable:jsoneditor"
					editorProps={{$blockScrolling: true}}
					onChange={value => this.setState({ value })}
					onLoad={(editor) => {
						editor.getSession().setUseWorker(false);
					}}
				/>
			</Dialog>
		);
	}
}

export class ScriptEditor extends PureComponent {
	static propTypes = {
		currValue : PropTypes.string.isRequired,
		origValue : PropTypes.string.isRequired,
		onChange : PropTypes.func.isRequired,
		isEditing : PropTypes.bool.isRequired
	}

	constructor(props) {
		super(props);
		this.state = {
			value : props.currValue
		};
	}

	componentWillUnmount() {
		this.props.onChange(this.state.value);
	}

	render() {
		return (
			<Dialog
				contentStyle={styles.modal}
				modal={false}
				open={this.props.isEditing}>
				<AceEditor
					value={this.state.value}
					mode="javascript"
					theme="github"
					width={'100%'}
					maxLines={30}
					minLines={30}
					name="updatable:jsoneditor"
					editorProps={{$blockScrolling: true}}
					onChange={value => this.setState({ value })}
					onLoad={(editor) => {
						editor.getSession().setUseWorker(false);
					}}
				/>
			</Dialog>
		);
	}
}

export class CheckboxEditor extends PureComponent {

	static propTypes = {
		currValue : PropTypes.bool.isRequired,
		origValue : PropTypes.bool.isRequired,
		onChange : PropTypes.func.isRequired,
		isEditing : PropTypes.bool.isRequired
	}

	render() {
		let { currValue, onChange } = this.props;
		return (
			<Checkbox
				disabled={false}
				checked={currValue}
				onCheck={(e, checked) => onChange(checked)}
			/>
		);
	}
}

export class FileEditor extends PureComponent {
	static propTypes = {
		currValue : PropTypes.object.isRequired,
		origValue : PropTypes.object.isRequired,
		onChange : PropTypes.func.isRequired,
		isEditing : PropTypes.bool.isRequired
	}

	render() {
		return (
			<FlatButton
				labelPosition="before"
				icon={<FontIcon className="material-icons" style={styles.ctrlStyle}>file_upload</FontIcon>}>
				<input style={styles.fileInput} type="file" onChange={e => {
					let file = e.target.files[0];
					readPromise(file).then(v => this.props.onChange(v));
				}}/>
			</FlatButton>
		);
	}
}
