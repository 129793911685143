import { combineReducers } from 'redux';

// import adminModels       from './adminModels';
// import ProjectModel      from './projectModelCreator';
import { routerReducer } from 'react-router-redux';
import app               from './app/index';
import data              from './data';
import ui                from './ui';

export default combineReducers({
	app,
	ui,
	data,
	router : routerReducer
	// databases,
	// adminModels,
	// projectModels : new ProjectModel()
	// 	.model('members', true)
	// 	.model('words', true)
	// 	.model('tasks', true)
	// 	.model('reports', true)
	// 	.model('issues', true)
	// 	.model('followups', true)
	// 	.model('attachments', true)
	// 	.model('templates', true)
	// 	.build(),
});
