//import schema from 'js-schema';
//import { red500, purple600, indigo500, lightBlue600, lightGreen500 } from 'material-ui/styles/colors';
import ObjectId from 'objectid';
import { ARRAY_TO_JSON_GETTER } from 'commons/updatable/getters';
import { JSONPresenter } from 'commons/updatable/presenters';
import { OBJECT_SETTER } from 'commons/updatable/setters';
import { JSONEditor } from 'commons/updatable/editors';

export default {
	paths : [{
		key         : '_id',
		name        : 'ID',
		type        : 'string',
		show        : true,
		initializer : () =>ObjectId().toString()
	}, {
		key  : 'username',
		type : 'string',
		name : '帳號',
		editable : false,
		creatable : true
	}, {
		key  : 'name',
		type : 'string',
		name : '用戶名稱',
		editable : true
	}, {
		key  : 'newPassword',
		type : 'string',
		icon : 'material-icons:lock',
		name : '新密碼',
		editable : true,
		autoSave : false
	}, {
		key  : 'oldPassword',
		type : 'string',
		icon : 'material-icons:lock',
		name : '舊密碼',
		editable : true,
		autoSave : false
	}, {
		key  : 'roles',
		width : 5,
		type : {
			format : [{
				paths : [{
					key : 'role',
					name : '用戶組',
					type : 'string'
				}, {
					key : 'scope',
					name : '有效範圍',
					type : 'object'
				}]
			}],
			edit : 'object'
		},
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		initializer : () => ([]),
		isCollection : true,
		popup : true,
		name : '身份',
		editable : true,
	}, {
		key  : 'updatedAt',
		type : 'string',
		name : '上次修改',
		editable : false
	}],
	keyPath : '_id'
};
