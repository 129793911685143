const defaultFactory = (payload, meta) => ({ payload, meta });

const ActionCreatorBuilder =  class ActionCreatorBuilder {
	constructor(configs = {}) {
		this.configs = {
			factory : defaultFactory,
			isAsync : false,
			...configs
		};
	}

	type(type) {
		this.configs.type = type;
		return this;
	}

	factory(fn) {
		this.configs.factory = fn;
		return this;
	}

	isAsync(...args) {

		if (!args.length) {
			this.configs.isAsync = { successFactory : defaultFactory, failureFactory : defaultFactory };
			return this;
		}

		if (typeof args[0] == 'boolean') {
			this.configs.isAsync = this.configs.isAsync ? { successFactory : defaultFactory, failureFactory : defaultFactory } : false;
			return this;
		}

		let [ successFactory = defaultFactory, failureFactory = defaultFactory ] = args;

		if (!(successFactory instanceof Function) || !(failureFactory instanceof Function))
			throw new TypeError('Invalid factory');

		this.configs.isAsync = { successFactory, failureFactory };

		return this;
	}

	build() {
		let { configs : { type, factory, isAsync } } = this;
		let successCreator, failureCreator;

		if (isAsync) {
			let { successFactory, failureFactory } = isAsync;

			successCreator = new ActionCreatorBuilder()
				.type(`${type}_SUCCESS`)
				.factory(successFactory)
				.build();

			failureCreator = new ActionCreatorBuilder()
				.type(`${type}_FAILURE`)
				.factory(failureFactory)
				.build();
		}

		let creator = (...args) => {
			let action = {
				type,
				...factory(...args),
			};
			if (isAsync) {
				action.SUCCESS = successCreator;
				action.FAILURE = failureCreator;
			}
			return action;
		};
		if (isAsync) {
			creator.SUCCESS = successCreator;
			creator.FAILURE = failureCreator;
		}

		return creator;
	}
};

export default ActionCreatorBuilder;
