import ObjectId from 'objectid';

export default {
	paths : [{
		key         : '_id',
		name        : 'ID',
		type        : 'string',
		show        : false,
		initializer : () => ObjectId().toString()
	}, {
		key         : 'author',
		type        : 'string',
		name        : '報告人',
		editable    : false
	}, {
		key         : 'addressTo',
		type        : 'string',
		name        : '工作',
		editable    : false
	}, {
		key         : 'changeType',
		type        : 'string',
		name        : '新增/刪除',
		editable    : false
	}, {
		key         : 'issue',
		type        : 'string',
		name        : '問題',
		editable    : false
	}, {
		key         : 'updatedAt',
		type        : 'string',
		name        : '上次修改',
		editable    : false
	}],
	keyPath : '_id'
};
