import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import CollectionTable from '../collectionTable';
import {
	Card, CardTitle, CardText
} from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import { UPLOAD_CSV } from '../actions';

import styles from './styles';

let WordManagement = class WordManagement extends PureComponent {
	static propTypes = {
		projectID     : PropTypes.string.isRequired,
		loading       : PropTypes.bool.isRequired,
		uploadCSV     : PropTypes.func.isRequired
	}

	render() {
		return (
			<Card style={styles.main} containerStyle={styles.mainContainer}>
				<CardTitle
					title="字庫管理"
					subtitle="Word Management"
				/>
				<CardText style={styles.table}>
					{
						this.props.projectID
							? (
								<RaisedButton
									label="匯入CSV檔"
									labelPosition="before"
									containerElement="label"
									disabled={this.props.loading}
									labelColor="#FFFFFF"
									backgroundColor="#2979FF">
									<input style={styles.importBtn} type="file" onChange={this.props.uploadCSV}/>
								</RaisedButton>
							) : null
					}

					<CollectionTable
						sceneName="words"
						model="words"
						projectSpecific={true}
					/>
				</CardText>
			</Card>
		);
	}
};

const mapStateToProps = createSelector(
	state => state.app.currProject,
	state => state.app.importState.loading,
	(projectID, loading) => ({ projectID, loading })
);

const mapDispatchtoProps = dispatch => ({
	uploadCSV : e => dispatch(new UPLOAD_CSV({ file : e.target.files[0] }, { model : 'words' }))
});

export default connect(
	mapStateToProps,
	mapDispatchtoProps
)(WordManagement);
