import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';

import styles from './header.styles';

export default class Header extends PureComponent {
	static propTypes = {
		title : PropTypes.string.isRequired
	}

	render() {
		let { title, ...otherProps } = this.props;
		return <Cell style={styles.container} {...otherProps}>
			{title}
		</Cell>;
	}
}
