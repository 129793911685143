import { Map } from 'immutable';

const initState = new Map();

export default (state = initState, action) => {
	let { type, payload = {} } = action;
	switch (type) {
	case 'EXPAND_TEMPLATE':
		return state.setIn([payload.projectID, payload.templateID, 'expanded'], true);
	case 'CLOSE_TEMPLATE':
		return state.setIn([payload.projectID, payload.templateID, 'expanded'], false);
	case 'ENTER_FILENAME':
		return state.setIn([payload.projectID, payload.templateID, 'filename'], payload.filename);
	case 'UPDATE_EXTRA_FIELD':
		return state.setIn([payload.projectID, payload.templateID, 'extraFields', payload.fieldKey], payload.value);
	case 'EXPORT_REPORT':
		return state.setIn([payload.projectID, payload.templateID, 'loading'], true);
	case 'EXPORT_REPORT_FAILURE':
		return state.setIn([payload.projectID, payload.templateID, 'loading'], false);
	case 'EXPORT_REPORT_SUCCESS':
		return state.setIn([payload.projectID, payload.templateID, 'loading'], false);
	case 'REHYDRATE_TEMPLATE':
		return state.setIn([payload.projectID, 'rehydrating'], true);
	case 'REHYDRATE_TEMPLATE_SUCCESS':
		return state.mergeIn([payload.projectID], {
			rehydrating : false,
			cached : true
		});
	case 'REHYDRATE_TEMPLATE_FAILURE':
		return state.setIn([payload.projectID, 'rehydrating'], false);
	default:
		return state;
	}
};
