import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware             from 'redux-saga';
import createHistory        from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import { fromJS }            from 'immutable';

import reducer              from './reducers/index';
import actionSaga           from './sagas/index';
import { createLogger }     from 'redux-logger';

const history = createHistory();
const router = routerMiddleware(history);

const sagaMiddleware = createSagaMiddleware();

let middlewares = [ sagaMiddleware, router ];

if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') {
	const logger = createLogger({
		collapsed: true,
		stateTransformer: (state) => {
			return fromJS(state).toJS();
		}
	});
	middlewares = [...middlewares, logger];
}

function configureStore(initialState = {}) {
	const store = createStore(
		reducer,
		initialState,
		applyMiddleware(...middlewares)
	);

	sagaMiddleware.run(actionSaga);

	store.history = history;
	return store;
}

export default configureStore();
