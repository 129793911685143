import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import immutableSelector from 'utils/immutableSelector';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { List } from 'immutable';

import Updatable from 'commons/updatable';
import { OBJECT_GETTER, NUMBER_GETTER } from 'commons/updatable/getters';

import {
	Card, CardTitle, CardText, CardActions, CardHeader
} from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import DatePicker from 'material-ui/DatePicker';
import FontIcon           from 'material-ui/FontIcon';
import IconButton         from 'material-ui/IconButton';
import RaisedButton       from 'material-ui/RaisedButton';

import _ from 'lodash';

import ProjectIDEnsured from './projectEnsurer';
import TrafficLight     from './trafficLight';
import DetailLink   from './DetailLink';
import Loadable from 'commons/loadable';

import { GET_SUMMARY, CHANGE_DATE, GO_TO, UPDATE_GROUP } from './actions';
import styles from './projectSummary.styles';

const checkRole = (state, { projectID }) => _.some(state.app.user.roles, ({ role : { name }, scope }) => {
	return ((name == 'monitor' || name == 'manager') && scope.projectID == projectID) || name == 'system_admin';
});

const selectLoading = (state, { projectID }) => state.app.projectSummaryNew.loading.getIn([projectID, state.app.projectSummaryNew.groupBy, state.app.projectSummaryNew.date.format('YYYYMMDD')]);
const selectInitized = (state, { projectID }) => state.app.projectSummaryNew.initialized.getIn([projectID, state.app.projectSummaryNew.groupBy, state.app.projectSummaryNew.date.format('YYYYMMDD')]);

const mapLoadableState = createSelector(
	selectLoading,
	selectInitized,
	(loading = false, initialized = false) => ({ loading, initialized })
);

const mapDispatchToGetData = dispatch => ({
	getData : ({ projectID, date, groupBy  }) => dispatch(
		new GET_SUMMARY({ projectID, date, groupBy })
	),
});

const mapStateToProps = createSelector(
	checkRole,
	state                  => state.app.projectSummaryNew.groupBy,
	state                  => state.app.projectSummaryNew.date.toDate(),
	(state, { projectID }) => (state.app.projectSummaryNew.data.getIn([projectID, state.app.projectSummaryNew.groupBy, state.app.projectSummaryNew.date.format('YYYYMMDD')]) || new List()).toJS(),
	state                  => state.app.user,
	(isMonitor, groupBy, date, records, user) => {

		let paths = ~_.indexOf(['tray', 'task'], groupBy) ? [{
			key : 'name',
			name : '單位',
			editable : false,
			type : 'string',
			presenter : function presenter(props) {
				return <DetailLink {...props} type="task" />;
			},
		}] : [{
			key: 'tray',
			name: '工項',
			editable: false,
			type: 'string',
			presenter: function presenter(props) {
				return <DetailLink {...props} type="tray" />;
			},
		}];

		if (groupBy == 'tray') {
			paths = records[0] ? paths.concat(_.map(records[0].trays, ((__, trayName) => ({
				key : 'trays.'+trayName,
				name : trayName,
				type : 'object',
				editable : false,
				presenter : TrafficLight,
				getter : OBJECT_GETTER
			})))) : paths;
		}
		if (groupBy == 'task') {
			paths = paths.concat([{
				key : 'issueStatusCounts',
				name : '問題',
				type : 'object',
				editable : false,
				presenter : TrafficLight,
				getter : OBJECT_GETTER
			}, {
				key : 'overall',
				name : '狀況',
				type : 'string',
				editable : false,
				getter : statusCode => ({
					noReport : '未交執修單',
					noDefect : '無須執修',
					await_repair : '待執修',
					await_inspect : '待檢查',
					await_accept : '待接受',
					accepted : '已接受'
				}[statusCode])
			}]);
		}
		if (groupBy == 'project') {

			paths = _.map({
				noReport : '未交執修單',
				noDefect : '無須執修',
				await_repair : '待執修',
				await_inspect : '待檢查',
				await_accept : '待接受',
				accepted : '已接受'
			}, (status, key) => ({
				key,
				name : status,
				type : 'number',
				getter : NUMBER_GETTER,
				editable : false
			}));
		}
		if (groupBy == 'floor') {
			paths = paths.concat(_.map(_.filter(_.keys(records[0]), key => key != 'tray').sort(), key => ({
				key,
				name: key,
				type: 'object',
				editable: false,
				presenter: TrafficLight,
				getter: OBJECT_GETTER
			})));
		}
		let schema = {
			keyPath : '_id',
			paths
		};
		return {
			isMonitor,
			user,
			groupBy,
			records,
			date,
			schema
		};
	}
);


const mapDispatchtoProps = (dispatch, { projectID }) => ({
	changeDate : (e, date) =>  dispatch(new CHANGE_DATE({ date }, { projectID })),
	goTo : () => dispatch(new GO_TO({ path : 'home' })),
	updateGroup : groupBy => dispatch(new UPDATE_GROUP({ groupBy })),
});

@connect(mapLoadableState, mapDispatchToGetData)
@Loadable
class LoadableTable extends PureComponent {
	render() {
		return <Updatable {...this.props}/>;
	}
}

@ProjectIDEnsured
@connect(mapStateToProps, mapDispatchtoProps)
class ProjectSummaryTable extends PureComponent {
	static propTypes = {
		isMonitor        : PropTypes.bool.isRequired,
		projectID        : PropTypes.string.isRequired,
		user             : PropTypes.object.isRequired,
		date             : PropTypes.object,
		schema           : PropTypes.object.isRequired,
		records          : PropTypes.arrayOf(PropTypes.object).isRequired,
		// loading          : PropTypes.bool.isRequired,
		needUpdateModels : PropTypes.arrayOf(PropTypes.string),
		changeDate       : PropTypes.func.isRequired,
		goTo             : PropTypes.func.isRequired,
		updateGroup      : PropTypes.func.isRequired,
		groupBy          : PropTypes.string.isRequired
	}

	render() {
		return this.props.isMonitor
			? <div style={styles.table}>
				<div style={styles.ctrlCtn}>
					<DatePicker
						autoOk={true}
						onChange={this.props.changeDate}
						hintText="選擇日期"
						mode="landscape"
						style={{ display : 'inline-block' }}
						value={this.props.date}
					/>
					<IconButton
						onTouchTap={() => this.props.changeDate({}, null)}>
						<FontIcon className="material-icons">cancel</FontIcon>
					</IconButton>
					<div style={styles.groupCtrl}>
						<FlatButton
							backgroundColor={this.props.groupBy == 'tray' ? 'rgba(0,0,0,.17)' : 'rgba(0,0,0,0)'}
							label="按工項"
							icon={<FontIcon className="material-icons">build</FontIcon>}
							onTouchTap={() => this.props.updateGroup('tray')}
						/>
						<FlatButton
							backgroundColor={this.props.groupBy == 'task' ? 'rgba(0,0,0,.17)' : 'rgba(0,0,0,0)'}
							label="按單位"
							icon={<FontIcon className="material-icons">home</FontIcon>}
							onTouchTap={() => this.props.updateGroup('task')}
						/>
						<FlatButton
							backgroundColor={this.props.groupBy == 'project' ? 'rgba(0,0,0,.17)' : 'rgba(0,0,0,0)'}
							label="按屋苑"
							icon={<FontIcon className="material-icons">location_city</FontIcon>}
							onTouchTap={() => this.props.updateGroup('project')}
						/>
						<FlatButton
							backgroundColor={this.props.groupBy == 'floor' ? 'rgba(0,0,0,.17)' : 'rgba(0,0,0,0)'}
							label="按樓層"
							icon={<FontIcon className="material-icons">location_city</FontIcon>}
							onTouchTap={() => this.props.updateGroup('floor')}
						/>
					</div>
				</div>
				<LoadableTable
					projectID={this.props.projectID}
					date={this.props.date}
					groupBy={this.props.groupBy}
					rowHeight={90}
					showSearchBar={false}
					user={this.props.user}
					selector={state => state.app.scenes.projectSummaryNew}
					tableKey="projectSummaryNew"
					data={this.props.records}
					schema={this.props.schema}
					{...this.props.schema}
					editable={false}
					selectable={false}
				/>
			</div>
			: <div>
				<CardHeader title="沒有存取權限"/>
				<CardActions>
					<RaisedButton label="回到主頁" primary={true} onClick={this.props.goTo} />
				</CardActions>
			</div>;
	}
}

export default class ProjectSummary extends PureComponent {

	render() {
		return (
			<Card style={styles.main} containerStyle={styles.mainContainer}>
				<CardTitle
					title="項目監察"
					subtitle="Project Summary"
				/>
				<CardText style={styles.table}>
					<ProjectSummaryTable />
				</CardText>
			</Card>
		);
	}
}
