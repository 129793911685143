import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import immutableSelector from 'utils/immutableSelector';
import { css } from 'aphrodite-jss';
import copyToClipboard   from 'copy-to-clipboard';

import { ContextMenuTrigger } from 'react-contextmenu';
import { Cell } from 'fixed-data-table-2';
import Tappable from 'react-tappable/lib/Tappable';

import DefaultPresenter from './presenters';
import DefaultEditor from './editors';
import SelectedIndicator from './selectedIndicator';
import { selectCell, editCell, pasteToCell, updateRecord } from './actions';
import styles from './editableCell.styles';

import defaultGetter from './getters';
import defaultSetter from './setters';

// import AutoSizeContainer from './autoSizeContainer';

const generateCellID = (state, { keyPath, data, rowIndex, columnKey }) => `${data[rowIndex][keyPath]}:${columnKey}`;
const selectRecord = (state, { data, rowIndex }) => data[rowIndex];
const selectChanged = (state, { data, rowIndex, selector, keyPath }) => selector(state).getIn(['changes', data[rowIndex][keyPath]]);
const selectErrMsgs = (state, { selector }) => selector(state).get('errMsgs');

const mapStateToProps = immutableSelector(
	(state, { selector }) => selector(state).get('editing'),
	(state, { selector }) => selector(state).get('selected'),
	generateCellID,
	selectRecord,
	selectChanged,
	selectErrMsgs,
	(editing, selected, cellID, record, changedRecord, errMsgs) => ({
		isEditing : editing == cellID,
		isSelected : selected == cellID,
		cellID,
		record,
		changedRecord,
		errMsg : errMsgs.get(cellID) || ''
	})
);

const mapDispatchToProps = (dispatch, { tableKey }) => ({
	selectCell : (cellID, copyArea) => {
		// copyArea.focus();
		copyArea.select();
		dispatch(selectCell(cellID, tableKey));
	},
	pasteToCell : (cellID, value) => dispatch(pasteToCell(cellID, value, tableKey)),
	editCell : (cellID, errMsg) => dispatch(editCell(cellID, errMsg, tableKey)),
	updateRecord : (key, record) => dispatch(updateRecord(key, record, tableKey)),
	// updateHeight : (rowIndex, columnKey, height) => dispatch(updateHeight(rowIndex, columnKey, height, tableKey))
});


@connect(mapStateToProps, mapDispatchToProps)
export default class EditableCell extends PureComponent {
	static propTypes = {
		selector : PropTypes.func.isRequired,
		keyPath : PropTypes.string.isRequired,
		cellID : PropTypes.string.isRequired,
		tableKey : PropTypes.string.isRequired,
		record : PropTypes.object.isRequired,
		changedRecord : PropTypes.object,
		isSelected : PropTypes.bool.isRequired,
		isEditing : PropTypes.bool.isRequired,
		selectCell : PropTypes.func.isRequired,
		editCell : PropTypes.func.isRequired,
		pasteToCell : PropTypes.func.isRequired,
		updateRecord : PropTypes.func.isRequired,
		getter : PropTypes.func,
		setter : PropTypes.func,
		copier : PropTypes.func,
		presenter : PropTypes.func.isRequired,
		editor : PropTypes.func.isRequired,
		columnKey : PropTypes.string.isRequired,
		width : PropTypes.number.isRequired,
		height : PropTypes.number.isRequired,
		rowIndex : PropTypes.number.isRequired,
		editable : PropTypes.bool.isRequired,
		selectable : PropTypes.bool.isRequired,
		maxHeight : PropTypes.number,
	}

	static defaultProps = {
		presenter : DefaultPresenter,
		editor : DefaultEditor,
		getter : defaultGetter,
		setter : defaultSetter,
		editable : true
	}

	// componentWillUpdate(nextProps) {
	// 	if (this.props.columnKey == 'name') console.log(this.props.record[this.props.columnKey], this.props, nextProps);
	// }

	toggleMenu = e => {
		if (this._menuTrigger)
			this._menuTrigger.handleContextClick(e);
	}

	formatValue(v) {
		let { setter, columnKey, record, changedRecord } = this.props;
		let currRecord = changedRecord ? changedRecord : record;
		return setter(v, currRecord, columnKey);
	}

	onChange = v => {
		let { updateRecord, record, keyPath, cellID, editCell } = this.props;
		try {
			updateRecord(record[keyPath], this.formatValue(v));
		} catch(err) {
			editCell(cellID, err);
		}
	}

	render() {
		let {
			keyPath,
			cellID,
			record,
			changedRecord,
			height,
			width,
			columnKey,
			rowIndex,
			isSelected,
			isEditing,
			presenter : Presenter,
			editor : Editor,
			copier,
			getter,
			selectCell,
			editCell,
			pasteToCell,
			editable,
			selectable,
		} = this.props;
		let currRecord = changedRecord ? changedRecord : record;
		let copy = copier ? copier(_.get(currRecord, columnKey), record, changedRecord) : getter(_.get(currRecord, columnKey), record, changedRecord);
		return (
			<ContextMenuTrigger
				collect={() => ({
					copyCell : () => copyToClipboard(copy),
					pasteToCell : () => {
						this._copyArea.focus();
						let success = document.execCommand('paste');
						if (!success)
							window.alert('Your browser not support click-to-paste. Please use ctrl + V');
					}
				})}
				ref={menuTrigger => this._menuTrigger = menuTrigger}
				id="updatableContextMenu">
				<Tappable
					component={Cell}
					className={css(styles.cell)}
					columnKey={columnKey}
					width={width}
					height={height}
					rowIndex={rowIndex}
					onDoubleClick={() => {
						if (editable)
							editCell(cellID);
					}}
					onPress={() => {
						if (editable)
							editCell(cellID);
					}}
					onContextMenu={this.toggleMenu}
					onTap={() => {
						if (!isSelected && !isEditing && selectable)
							selectCell(cellID, this._copyArea);
					}}>
					{/* <AutoSizeContainer
						currValue={getter(currRecord[columnKey], currRecord, columnKey)}
						origValue={getter(record[columnKey], record, columnKey)}
						{...this.props}> */}
					{
						isEditing
							? <Editor
								onChange={this.onChange}
								currValue={getter(_.get(currRecord, columnKey), currRecord, columnKey)}
								origValue={getter(_.get(currRecord, columnKey), record, columnKey)}
								{...this.props}
							/>
							: <Presenter
								currValue={getter(_.get(currRecord, columnKey), currRecord, columnKey)}
								origValue={getter(_.get(currRecord, columnKey), record, columnKey)}
								{...this.props}
							/>
					}
					{/* </AutoSizeContainer> */}
					{
						isSelected
							? ['top', 'bottom', 'right', 'left'].map((position, i) => (
								<SelectedIndicator
									key={'indicator'+i}
									height={height}
									width={width}
									position={position}
								/>
							))
							: null
					}
				</Tappable>
				<textarea
					id={cellID+':copyArea'}
					className={css(styles.copyArea)}
					readOnly
					ref={copyArea => this._copyArea = copyArea}
					value={copy}
					onCopy={e => {
						if (typeof copy == 'string')
							e.clipboardData.setData('text/plain', copy);
						else alert('The data type is not copyable');
					}}
					onPaste={e => {
						pasteToCell(record[keyPath], this.formatValue(e.clipboardData.getData('text')));
					}}/>
			</ContextMenuTrigger>
		);
	}
}
