import filter   from 'lodash/filter';
import map      from 'lodash/map';
import ObjectId from 'objectid';
import { Map } from 'immutable';

const initialState = {
	keyword      : '',
	newFollowups : [],
	newAttachments : [],
	data : new Map(),
	loading : new Map(),
	initialized : new Map()
};

export default (state = initialState, action) => {
	let { type, payload = {} } = action;
	let { projectID, taskID, data } = payload;
	var timestamp;
	switch (type) {
	case 'GET_DETAIL':
		return { ...state, loading : state.loading.setIn([projectID, taskID], true) };
	case 'GET_DETAIL_SUCCESS':
		return {
			...state,
			loading : state.loading.setIn([projectID, taskID], false),
			initialized : state.initialized.setIn([projectID, taskID], true),
			data : state.initialized.setIn([projectID, taskID], data)
		};
	case 'GET_DETAIL_FAILURE':
		return { ...state, loading : state.loading.setIn([projectID, taskID], false) };
	case 'UPDATE_FOLLOWUP_KEYWORD':
		return { ...state, keyword : payload.keyword };
	case 'CREATE_FOLLOWUP':
		var newFollowupID = ObjectId().toString();
		timestamp = new Date(parseInt(newFollowupID.substring(0, 8), 16) * 1000);
		return { ...state, newFollowups : [...state.newFollowups, {
			_id : newFollowupID.toString(),
			followupTo : payload.issueRef,
			createdAt : timestamp,
			status : 'await_inspect',
			approved : false
		}]};
	case 'CREATE_ATTACHMENTS':
		var newAttachmentID = ObjectId().toString();
		timestamp = new Date(parseInt(newAttachmentID.substring(0, 8), 16) * 1000);
		return {
			...state,
			newAttachments : [
				...state.newAttachments,
				...map(payload.attachments, attachment => ({
					_id : newAttachmentID.toString(),
					createdAt : timestamp,
					data : attachment.data,
					mimeType : attachment.type,
					name : attachment.name,
					followupID : payload.followupID
				}))
			]
		};
	case 'UPDATE_FOLLOWUP':
		return { ...state, newFollowups : map(state.newFollowups, followup => {
			if (followup._id != payload.followup._id)
				return followup;
			return payload.followup;
		})};
	case 'SUBMIT_FOLLOWUP':
		return { ...state, newFollowups : filter(state.newFollowups, { _id : payload.followupID })};
	default:
		return state;
	}
};
