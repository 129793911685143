export default {
	URL_ROOT : process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test'
		? 'https://buildinpro.com'
		: 'http://localhost:8080',
	DATABASE_NAME     : 'bip_db',
	DATABASE_VERSION  : 2,
	ADMIN_SCHEMA_VERSION : '1.0',
	PERSISTENT_MODELS : [{
		modelName : 'meta',
		schemaName : 'admin'
	}, {
		modelName : 'tasks',
		schemaName : 'project'
	}, {
		modelName : 'words',
		schemaName : 'project'
	}, {
		modelName : 'reports',
		schemaName : 'project'
	}, {
		modelName : 'issues',
		schemaName : 'project'
	}, {
		modelName : 'followups',
		schemaName : 'project'
	}, {
		modelName : 'members',
		schemaName : 'project'
	}, {
		modelName : 'attachments',
		schemaName : 'project'
	}, {
		modelName : 'templates',
		schemaName : 'project'
	}],
	MANAGEMENT_SCENES : [
		'users',
		'roles',
		'projects',
		'modules',
		'tasks',
		'words',
		'reports',
		'issues',
		'followups',
		'members',
		'attachments',
		'templates',
		'projectSummary',
		'projectSummaryNew'
	]
};
