import { StyleSheet } from 'aphrodite-jss';

export default StyleSheet.create({
	container : {
		flex : 1,
		display : 'flex',
		flexDirection : 'column',
		alignItems : 'stretch'
	},
	ctrlCtn : {
		display : 'flex',
		flexDirection : 'row',
	},
	tableCtn : {
		flex : 1,
		display : 'flex',
		alignItems : 'stretch'
	},
	addBtn : {
		display : 'flex',
		flexDirection : 'row',
		alignItems : 'center'
	}
});
