import _ from 'lodash';

export default (v, record, key) => {
	let result = {...record, [key] : v };
	return result;
};

export const JSON_SETTER = (v, record, key) => {
	try {
		if (!_.isObject(JSON.parse(v)))
			throw 'Invalid format';
	} catch(err) {
		throw 'Invalid format';
	}
	return {...record, [key] : v};
};

export const OBJECT_SETTER = (v, record, key) => {
	let obj;
	try {
		obj = JSON.parse(v);
		if (!_.isObject(obj))
			throw 'Invalid format';
	} catch(err) {
		throw 'Invalid format';
	}
	return {...record, [key] : obj};
};

export const NUMBER_SETTER = (v, record, key) => {
	let number = parseInt(v);
	if (!_.isNumber(number))
		throw 'Invalid format';
	return {...record, [key] : number };
};
