import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import FlatButton from 'material-ui/FlatButton';
import FontIcon from 'material-ui/FontIcon';
import styles from './presenters.styles';
import { saveBlob, base64toBlob } from 'utils/fileUploadHelper';

function syntaxHighlight(json) {
	if (typeof json != 'string') {
		json = JSON.stringify(json, null, 2);
	}
	json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
	return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function(match) {
		var cls = 'number';
		if (/^"/.test(match)) {
			if (/:$/.test(match)) {
				cls = 'key';
			} else {
				cls = 'string';
			}
		} else if (/true|false/.test(match)) {
			cls = 'boolean';
		} else if (/null/.test(match)) {
			cls = 'null';
		}
		return '<span class="' + cls + '">' + match + '</span>';
	});
}

export default class DefaultPresenter extends PureComponent {

	static propTypes = {
		currValue : PropTypes.string.isRequired,
		origValue : PropTypes.string.isRequired
	}

	componentDidMount() {
		this._container.innerHTML = this.props.currValue;
	}

	componentDidUpdate() {
		this._container.innerHTML = this.props.currValue;
	}

	render() {
		return (
			<pre ref={container => this._container = container} />
		);
	}
}

export class JSONPresenter extends PureComponent {

	static propTypes = {
		currValue : PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
		origValue : PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
	}

	componentDidMount() {
		this._container.innerHTML = syntaxHighlight(this.props.currValue);
	}

	componentDidUpdate() {
		this._container.innerHTML = syntaxHighlight(this.props.currValue);
	}

	render() {
		return (
			<pre ref={container => this._container = container} />
		);
	}
}

export class BooleanPresenter extends PureComponent {

	static propTypes = {
		currValue : PropTypes.bool.isRequired,
		origValue : PropTypes.bool.isRequired
	}

	render() {
		return (
			<div>
				<Checkbox checked={this.props.currValue} disabled={true}/>
			</div>
		);
	}
}

export class FilePresenter extends PureComponent {
	static propTypes = {
		currValue : PropTypes.object.isRequired,
		origValue : PropTypes.object.isRequired,
		isEditing : PropTypes.bool.isRequired
	}

	getFile = async (value) => {
		let response = await fetch(value.url, {
			method : 'GET',
			headers : {
				...value.auth
			}
		});
		let data = await response.text();
		let blob = base64toBlob(data, value.mimeType);
		saveBlob(blob, value.name);
	}

	render() {
		return (
			<FlatButton
				onTouchTap={() => this.getFile(this.props.currValue)}
				icon={<FontIcon className="material-icons" style={styles.ctrlStyle}>file_download</FontIcon>}>
			</FlatButton>
		);
	}
}

export class ScriptPresenter extends PureComponent {
	render() {
		return <FontIcon className="devicon-javascript-plain" style={styles.ctrlStyle} />;
	}
}
