import { call, put } from 'redux-saga/effects';
import isObjectLike from 'lodash/isObjectLike';

import { SHOW_ERROR, LOGOUT } from '../actions';

import store from '../store';

export default function catchError(service) {
	return function* (...args) {
		try {
			return yield call(service, ...args);
		} catch(err) {
			let status = isObjectLike(err) ? err.message : 500;
			let shownError;
			switch(status) {
			case '401':
				shownError = {
					errMsg : '請重新登入',
					errCallback : () => store.dispatch(new LOGOUT())
				};
				break;
			case '403':
				shownError = {
					errMsg : '用戶沒有存取權限'
				};
				break;
			default:
				shownError = {
					errMsg : '出現未確認錯誤, 請聯絡系統管理員'
				};
			}

			yield put(new SHOW_ERROR(shownError));
		}
	};
}
