import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Card
} from 'material-ui/Card';

import RaisedButton from 'material-ui/RaisedButton';

import { EXPORT_REPORT, EXPAND_TEMPLATE, CLOSE_TEMPLATE } from '../../actions';
import styles from './styles';

import FilenameInput from './filenameInput';
import ExtraFieldInput from './extraFieldInput';

const mapStateToProps = (state, { projectID, template }) => ({
	expanded : !!state.ui.reportExport.getIn([projectID, template.get('_id'), 'expanded']),
	loading : !!state.ui.reportExport.getIn([projectID, template.get('_id'), 'loading']),
	extraFields : template.get('extraFields') ? JSON.parse(template.get('extraFields')) : []
});

const mapDispatchToProps = (dispatch, { projectID, template }) => ({
	expandTemplate : () => dispatch(new EXPAND_TEMPLATE({ projectID, templateID : template.get('_id') })),
	exportReport : () => dispatch(new EXPORT_REPORT({ projectID, template })),
	closeTemplate : ()  => dispatch(new CLOSE_TEMPLATE({ projectID, templateID : template.get('_id') }))
});

@connect(mapStateToProps, mapDispatchToProps)
export default class ReportExport extends PureComponent {
	static propTypes = {
		projectID      : PropTypes.string.isRequired,
		loading        : PropTypes.bool.isRequired,
		template       : PropTypes.object.isRequired,
		extraFields    : PropTypes.array.isRequired,
		expanded       : PropTypes.bool.isRequired,
		exportReport   : PropTypes.func.isRequired,
		expandTemplate : PropTypes.func.isRequired,
		closeTemplate  : PropTypes.func.isRequired
	}

	renderExtraFields() {
		return this.props.extraFields.map((extraField, i) => (
			<div key={i} style={styles.extraFieldsCtn}>
				<ExtraFieldInput
					projectID={this.props.projectID}
					templateID={this.props.template.get('_id')}
					fieldKey={extraField.key}
					inputType={extraField.inputType}
					label={extraField.label}
				/>
			</div>
		));
	}

	render() {
		return (
			<Card style={styles.menuItem}>
				<div style={styles.menuItemHeader}>
					<div style={styles.menuHeaderLabel}>{this.props.template.get('name')}</div>
					<div style={styles.menuItemPlaceholder}></div>
					{
						!this.props.expanded && this.props.extraFields.length
							? <div style={styles.menuItemCtrl}>
								<RaisedButton
									disabled={this.props.loading}
									primary={true}
									label="選擇"
									onTouchTap={this.props.expandTemplate}
								/>
							</div>
							: null
					}
					{
						this.props.expanded
							? <div style={styles.menuItemCtrl}>
								<RaisedButton
									disabled={this.props.loading}
									primary={true}
									label="收起"
									onTouchTap={this.props.closeTemplate}
								/>
							</div>
							: null
					}
					<div style={styles.menuItemCtrl}>
						<RaisedButton
							primary={true}
							label="匯出"
							onTouchTap={this.props.exportReport}
						/>
					</div>
				</div>
				{
					this.props.expanded
						? this.renderExtraFields()
						: null
				}
				<FilenameInput
					templateID={this.props.template.get('_id')}
					projectID={this.props.projectID}
				/>
			</Card>
		);
	}
}
