import ObjectId from 'objectid';

import { OBJECT_TO_JSON_GETTER, ARRAY_TO_JSON_GETTER } from 'commons/updatable/getters';
import {  JSONPresenter } from 'commons/updatable/presenters';
import { OBJECT_SETTER } from 'commons/updatable/setters';
import { JSONEditor } from 'commons/updatable/editors';

export default {
	paths : [{
		key         : '_id',
		name        : 'ID',
		type        : 'string',
		show        : true,
		initializer : () => ObjectId().toString()
	}, {
		key  : 'name',
		type : 'string',
		name : '工作名稱',
		editable : true
	}, {
		key  : 'detail',
		type : 'object',
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : OBJECT_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		name : '詳情',
		editable : true
	}, {
		key  : 'kind',
		type : 'string',
		name : '單位類型',
		editable : true
	}, {
		key  : 'attachments',
		type : ['string'],
		presenter : JSONPresenter,
		editor : JSONEditor,
		getter : ARRAY_TO_JSON_GETTER,
		setter : OBJECT_SETTER,
		name : '附件',
		editable : true
	}, {
		key  : 'updatedAt',
		type : 'date',
		name : '上次修改',
		editable : false
	}],
	keyPath : '_id'
};
