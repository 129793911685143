import { Map, fromJS } from 'immutable';

const initialState = new Map({
	loading : new Map({
		users : 0,
		roles : 0,
		projects : 0
	}),
	initialized : new Map({
		users : false,
		roles : false,
		projects : false
	}),
	lastUpdated : new Map({
		users : null,
		roles : null,
		projects : null
	}),
	users : new Map(),
	roles : new Map(),
	projects : new Map()
});

export default (state = initialState, action) => {
	let { type, payload = {} } = action;
	let { model, key, models, projectID } = payload;
	if (type == 'GET_DATA') {
		return state
			.update('loading', loadingMap => models.reduce((result, { model }) => {
				return result.set(model, true);
			}, loadingMap));
	}
	if (type == 'GET_DATA_SUCCESS') {
		return state
			.mergeDeep(payload.data.adminData)
			.update('loading', loadingMap => models.reduce((result, { model }) => {
				return result.set(model, false);
			}, loadingMap))
			.update('initialized', initializedMap => models.reduce((result, { model }) => {
				return result.set(model, true);
			}, initializedMap))
			.update('lastUpdated', lastUpdatedMap => models.reduce((result, { model }) => {
				return result.set(model, payload.lastUpdated);
			}, lastUpdatedMap));
	}
	if (projectID)
		return state;
	switch (type) {
	case 'CREATE_DATA':
	case 'UPDATE_DATA':
	case 'REMOVE_DATA':
		return state.updateIn(['loading', model], 0, n => n+1);
	case 'UPDATE_DATA_SUCCESS':
	case 'CREATE_DATA_SUCCESS':
		return state
			.update(model, model => model.set(key, fromJS(payload.doc)))
			.updateIn(['loading', model], 0, n => n-1);
	case 'REMOVE_DATA_SUCCESS':
		return state
			.setIn([model, key, 'deleted'], true)
			.updateIn(['loading', model], 0, n => n-1);
	case 'GET_DATA_FAILURE':
	case 'CREATE_DATA_FAILURE':
	case 'UPDATE_DATA_FAILURE':
	case 'REMOVE_DATA_FAILURE':
		return state.updateIn(['loading', model], 0, n => n-1);
	default:
		return state;
	}
};
