import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ReactList from 'react-list';
import moment from 'moment';
import _ from 'lodash';
import { push } from 'react-router-redux';
import { fromJS, List } from 'immutable';
import TextField from 'material-ui/TextField';

import ProjectEnsured from './projectEnsurer';
import Loadable from 'commons/loadable';
import immutableSelector from 'utils/immutableSelector';

import {
	Card, CardTitle, CardText
} from 'material-ui/Card';
import Divider from 'material-ui/Divider';
import Tappable from 'react-tappable/lib/Tappable';

import styles from './notification.styles';

import { GET_DATA, UPDATE_NOTI_LIMIT } from './actions';

const selectLoading = createSelector(
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'loading', 'tasks', projectID]),
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'loading', 'issues', projectID]),
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'loading', 'followups', projectID]),
	(...loading) => _.some(loading, Boolean)
);

const selectInitized = createSelector(
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'initialized', 'tasks', projectID]),
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'initialized', 'issues', projectID]),
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'initialized', 'followups', projectID]),
	(...initialized) => _.every(initialized, Boolean)
);

const mapLoadableState = createSelector(
	selectLoading,
	selectInitized,
	(loading, initialized) => ({ loading, initialized })
);

const mapDispatchToGetData = dispatch => ({
	getData : ({ projectID }) => dispatch(
		new GET_DATA({
			models : ['words', 'issues', 'followups', 'tasks', 'attachments'].reduce((result, model) => ([...result, { model, projectID}]), [])
		})
	)
});

const selectIssues = (state, { projectID }) => state.data.getIn(['projectDB', 'issues', projectID]);
const selectTasks = (state, { projectID }) => state.data.getIn(['projectDB', 'tasks', projectID]);
const selectFollowups = (state, { projectID }) => state.data.getIn(['projectDB', 'followups', projectID]);

const selectLoadingState = createSelector(
	(state, { projectID }) => state.data.getIn(['projectDB', 'loading', 'tasks', projectID]),
	(state, { projectID }) => state.data.getIn(['projectDB', 'loading', 'issues', projectID]),
	(state, { projectID }) => state.data.getIn(['projectDB', 'loading', 'followups', projectID]),
	(...loadingStates) => _.some(loadingStates, Boolean)
);

const selectDateLimit = state => state.ui.notificationDate;

const mapStateToProps = immutableSelector(
	selectIssues,
	selectTasks,
	selectFollowups,
	selectLoadingState,
	selectDateLimit,
	(issues, tasks, followups, loading, dateLimit) => {
		if (!_.every([tasks, issues, followups], Boolean)) {
			return {
				loading,
				notifications : []
			};
		}
		return {
			loading,
			dateLimit,
			notifications : issues
				.groupBy(issue => issue.get('issueRef'))
				.toList()
				.map(issueVersions => issueVersions.sortBy(issue => -(new Date(issue.get('createdAt')).getTime())).toList().get(0))
				.filter(issue => issue.get('dropped') != true && moment().diff(moment(issue.get('createdAt')), 'days') > dateLimit)
				.map(issue => {
					let issueFollowups = followups
						.filter(followup => followup.get('followupTo') == issue.get('issueRef') && followup.get('deleted') != true)
						.sortBy(followup => -(new Date(followup.get('createdAt')).getTime()))
						.toList();
					return issue.merge({
						status : issueFollowups.size
							? issueFollowups.getIn([0, 'status']) || 'await_repair'
							: 'await_repair'
					});
				})
				.filter(issue => !~_.indexOf(['await_accept', 'accepted'], issue.get('status')))
				.groupBy(issue => issue.get('addressTo'))
				.map((issues, taskID) => fromJS({ issues : issues.groupBy(issue => issue.get('status')), task : tasks.get(taskID)}))
				.toList()
				.map(taskIssues => {
					let await_repair = taskIssues.getIn(['issues', 'await_repair']) || new List();
					let await_inspect = taskIssues.getIn(['issues', 'await_inspect']) || new List();
					let result = {
						taskID : taskIssues.getIn(['task', '_id']),
						type : '過期通知',
						content : `${taskIssues.getIn(['task', 'name'])}尚有${await_repair.size ? await_repair.size + '個待執修項目' : ''}${await_repair.size && await_inspect.size ? '和' : ''}${await_inspect.size ? await_inspect.size + '個待檢查項目' : ''}超過${dateLimit}日未處理`
					};
					return result;
				}).toList().toJS()
		};
	}
);

const mapDispatchToProps = (dispatch, { projectID }) => ({
	checkDetail : taskID => dispatch(push(`taskDetail/projects/${projectID}/tasks/${taskID}`)),
	updateNotiLimit : dateLimit => dispatch(new UPDATE_NOTI_LIMIT({ dateLimit }))
});

@ProjectEnsured
@connect(mapLoadableState, mapDispatchToGetData)
@Loadable
@connect(mapStateToProps, mapDispatchToProps)
class NotiContent extends PureComponent {
	static propTypes = {
		dateLimit       : PropTypes.number.isRequired,
		projectID       : PropTypes.string.isRequired,
		notifications   : PropTypes.arrayOf(PropTypes.object).isRequired,
		getData         : PropTypes.func.isRequired,
		checkDetail     : PropTypes.func.isRequired,
		updateNotiLimit : PropTypes.func.isRequired,
	}

	static defaultProps = {
		projectID : '',
		notifications : [],
		getData : () => {},
		checkDetail : () => {}
	}

	renderItem = (i, key) => {
		let { type, content, taskID } = this.props.notifications[i];
		let isLastItem = this.props.notifications.length-1 == i;
		return (
			<Tappable
				component="div"
				key={key}
				onTap={() => this.props.checkDetail(taskID)}>
				<div style={styles.notiItem}>
					<div style={styles.notiType}>{ type }</div>
					<div style={styles.notiContent}>{ content }</div>
				</div>
				{
					isLastItem
						? null
						: <Divider />
				}
			</Tappable>
		);
	}

	render() {
		return (
			<div>
				<TextField
					floatingLabelText="限期"
					step={1}
					min={1}
					type="number"
					value={this.props.dateLimit.toString()}
					onChange={(e, v) => this.props.updateNotiLimit(parseInt(v))}
				/>
				<div style={styles.notiHeader}>
					<div style={styles.notiTypeHeader}>類型</div>
					<div style={styles.notiContentHeader}>內容</div>
				</div>
				<ReactList
					type='uniform'
					itemRenderer={this.renderItem}
					length={this.props.notifications.length}
				/>
			</div>
		);
	}
}

export default class Notification extends PureComponent {
	render() {
		return (
			<Card
				style={styles.main} containerStyle={styles.mainContainer}>
				<CardTitle
					title="通知"
					subtitle="Notifications"
				/>
				<CardText
					style={{
						height : '900px',
						overflow: 'auto'
					}}>
					<NotiContent {...this.props}/>
				</CardText>
			</Card>
		);
	}
}
