import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import immutableSelector from 'utils/immutableSelector';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

import Updatable from 'commons/updatable';
import { OBJECT_GETTER, NUMBER_GETTER } from 'commons/updatable/getters';

import {
	Card, CardTitle, CardText, CardActions, CardHeader
} from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import DatePicker from 'material-ui/DatePicker';
import FontIcon           from 'material-ui/FontIcon';
import IconButton         from 'material-ui/IconButton';
import RaisedButton       from 'material-ui/RaisedButton';

import _ from 'lodash';

import ProjectIDEnsured from './projectEnsurer';
import TrafficLight     from './trafficLight';
import DetailLink   from './DetailLink';
import Loadable from 'commons/loadable';

import { GET_DATA, CHANGE_DATE, GO_TO, UPDATE_GROUP } from './actions';
import styles from './projectSummary.styles';

const checkRole = (state, { projectID }) => _.some(state.app.user.roles, ({ role : { name }, scope }) => {
	return ((name == 'monitor' || name == 'manager') && scope.projectID == projectID) || name == 'system_admin';
});

const selectLoading = createSelector(
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'loading', 'tasks', projectID]),
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'loading', 'issues', projectID]),
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'loading', 'followups', projectID]),
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'loading', 'words', projectID]),
	(...loading) => _.some(loading, Boolean)
);

const selectInitized = createSelector(
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'initialized', 'tasks', projectID]),
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'initialized', 'issues', projectID]),
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'initialized', 'followups', projectID]),
	(state, { projectID }) => !!state.data.getIn(['projectDB', 'initialized', 'words', projectID]),
	(...initialized) => _.every(initialized, Boolean)
);

const mapLoadableState = createSelector(
	selectLoading,
	selectInitized,
	(loading, initialized) => ({ loading, initialized })
);

const mapDispatchToGetData = dispatch => ({
	getData : ({ projectID }) => dispatch(
		new GET_DATA({
			models : ['words', 'issues', 'followups', 'tasks'].reduce((result, model) => ([...result, { model, projectID}]), [])
		})
	),
});

const mapStateToProps = immutableSelector(
	checkRole,
	(state, { projectID }) => projectID,
	state                  => state.app.projectSummary.groupBy,
	state                  => state.app.projectSummary.date,
	state                  => state.app.user,
	(state, { projectID }) => state.data.getIn(['projectDB', 'tasks', projectID]),
	(state, { projectID }) => state.data.getIn(['projectDB', 'issues', projectID]),
	(state, { projectID }) => state.data.getIn(['projectDB', 'followups', projectID]),
	(state, { projectID }) => state.data.getIn(['projectDB', 'words', projectID]),
	(isMonitor, projectID, groupBy, date, user, tasks, issues, followups, words) => {
		if (!date)
			date = new Date();

		let trays = words
			.filter(word => word.get('tags').includes('tray') && word.get('deleted') != true)
			.sortBy(word => word.get('label'));

		let paths = [{
			key : 'name',
			name : '單位',
			editable : false,
			type : 'string',
			presenter: function presenter(props) {
				return <DetailLink {...props} type="task" />;
			},
		}];
		if (groupBy == 'tray') {
			paths = paths.concat(trays.map(tray => ({
				key : tray.get('label'),
				name : tray.get('label'),
				type : 'object',
				editable : false,
				presenter : TrafficLight,
				getter : OBJECT_GETTER
			})).toList().toJS());
		}
		if (groupBy == 'task') {
			paths = paths.concat([{
				key : 'issues',
				name : '問題',
				type : 'object',
				editable : false,
				presenter : TrafficLight,
				getter : OBJECT_GETTER
			}, {
				key : 'unitStatus',
				name : '狀況',
				type : 'string',
				editable : false
			}]);
		}
		if (groupBy == 'project') {

			paths = ['未交執修單', '無須執修', '待執修', '待檢查', '待接受', '已接受'].map(unitStatus => ({
				key : unitStatus,
				name : unitStatus,
				type : 'number',
				getter : NUMBER_GETTER,
				editable : false
			}));
		}
		let schema = {
			keyPath : '_id',
			paths
		};

		let issuesByTask = issues.filter(issue => {
			return (new Date(issue.get('createdAt')).getTime() <= date.getTime()) && issue.get('deleted') != true;
		}).groupBy(issue => issue.get('addressTo'));

		let followupsByIssue = followups.filter(followup => {
			return new Date(followup.get('createdAt')).getTime() <= date.getTime();
		}).groupBy(followup => followup.get('followupTo'));

		let records = tasks.map(task => {
			let taskIssues = (issuesByTask.get(task.get('_id')) || new Map())
				.groupBy(issue => issue.get('issueRef'))
				.map(issueVersions => issueVersions.sortBy(issue => -(new Date(issue.get('createdAt')).getTime())).toList().get(0))
				.filter(issue => issue.get('dropped') != true);

			taskIssues = taskIssues.map(issue => {
				let issuefollowups = (followupsByIssue.get(issue.get('issueRef')) || new Map())
					.sortBy(followup => -(new Date(followup.get('createdAt')).getTime()))
					.toList();
				let status = issuefollowups.size == 0
					? 'await_repair'
					: issuefollowups.getIn([0, 'status']);
				let trayWord = issue.get('tray');
				if (!trayWord)
					issue.get('content').some(content => {
						let word = words.get(content.get('word'));
						if (word && word.get('tags').includes('tray')) {
							trayWord = word;
							return true;
						}
						return false;
					});
				return issue.merge({
					tray :  trayWord ? trayWord.get('label') : '其他',
					followups : issuefollowups,
					status
				});
			});
			if (groupBy == 'tray') {
				let groupedIssues = taskIssues.groupBy(issue => issue.get('tray'));

				let issuesStats = groupedIssues.reduce((result, issues, tray) => {
					let status = _.reduce(['await_repair', 'await_inspect', 'await_accept', 'accepted'], (result, status) => {
						return {
							...result,
							[status] : issues.filter(issue => issue.get('status') == status).size
						};
					}, {
						noOfIssues : issues.filter(issue => issue.get('tray') == tray).size
					});
					return result.merge({
						[tray] : status
					});
				}, task);
				return issuesStats;
			}
			if (groupBy == 'task' || groupBy == 'project') {
				let statuses = _.reduce(['await_repair', 'await_inspect', 'await_accept', 'accepted'], (result, status) => {
					return {
						...result,
						[status] : taskIssues.filter(issue => issue.get('status') == status).size
					};
				}, {
					noOfIssues : taskIssues.size
				});
				let noDefect = !!taskIssues.filter(issue => issue.get('tray') == '無須執修').size;
				return task.merge({
					issues : statuses,
					unitStatus : !taskIssues.size
						? '未交執修單'
						: noDefect
							? '無須執修'
							: statuses['await_repair']
								? '待執修'
								: statuses['await_inspect']
									? '待檢查'
									: statuses['await_accept'] && statuses['accepted'] != taskIssues.size
										? '待接受'
										: '已接受'
				});
			}
		}).sortBy(task => task.get('name'));
		if (groupBy == 'project') {
			records = [records.reduce((result, record) => {
				_.forEach(['未交執修單', '無須執修', '待執修', '待檢查', '待接受', '已接受'], unitStatus => {
					if (record.get('unitStatus') == unitStatus)
						result[unitStatus]++;
				});
				return result;
			}, {
				'未交執修單': 0,
				'無須執修': 0,
				'待執修': 0,
				'待檢查': 0,
				'待接受': 0,
				'已接受': 0,
			})];
		} else {
			records = records.toList().toJS();
		}
		return {
			isMonitor,
			user,
			groupBy,
			records,
			date,
			schema
		};
	}
);

const mapDispatchtoProps = (dispatch, { projectID }) => ({
	changeDate : (e, date) =>  dispatch(new CHANGE_DATE({ date }, { projectID })),
	goTo : () => dispatch(new GO_TO({ path : 'home' })),
	updateGroup : groupBy => dispatch(new UPDATE_GROUP({ groupBy })),
});

@ProjectIDEnsured
@connect(mapLoadableState, mapDispatchToGetData)
@Loadable
@connect(mapStateToProps, mapDispatchtoProps)
class ProjectSummaryTable extends PureComponent {
	static propTypes = {
		isMonitor        : PropTypes.bool.isRequired,
		projectID        : PropTypes.string.isRequired,
		user             : PropTypes.object.isRequired,
		date             : PropTypes.object.isRequired,
		schema           : PropTypes.object.isRequired,
		records          : PropTypes.arrayOf(PropTypes.object).isRequired,
		// loading          : PropTypes.bool.isRequired,
		needUpdateModels : PropTypes.arrayOf(PropTypes.string),
		getData          : PropTypes.func.isRequired,
		changeDate       : PropTypes.func.isRequired,
		goTo             : PropTypes.func.isRequired,
		updateGroup      : PropTypes.func.isRequired,
		groupBy          : PropTypes.string.isRequired
	}

	render() {
		return this.props.isMonitor
			? <div style={styles.table}>
				<div style={styles.ctrlCtn}>
					<DatePicker
						autoOk={true}
						onChange={this.props.changeDate}
						hintText="選擇日期"
						mode="landscape"
						style={{ display : 'inline-block' }}
						value={this.props.date || new Date()}
					/>
					<IconButton
						onTouchTap={() => this.props.changeDate({}, null)}>
						<FontIcon className="material-icons">cancel</FontIcon>
					</IconButton>
					<div style={styles.groupCtrl}>
						<FlatButton
							backgroundColor={this.props.groupBy == 'tray' ? 'rgba(0,0,0,.17)' : 'rgba(0,0,0,0)'}
							label="按工項"
							icon={<FontIcon className="material-icons">build</FontIcon>}
							onTouchTap={() => this.props.updateGroup('tray')}
						/>
						<FlatButton
							backgroundColor={this.props.groupBy == 'task' ? 'rgba(0,0,0,.17)' : 'rgba(0,0,0,0)'}
							label="按單位"
							icon={<FontIcon className="material-icons">home</FontIcon>}
							onTouchTap={() => this.props.updateGroup('task')}
						/>
						<FlatButton
							backgroundColor={this.props.groupBy == 'project' ? 'rgba(0,0,0,.17)' : 'rgba(0,0,0,0)'}
							label="按屋苑"
							icon={<FontIcon className="material-icons">location_city</FontIcon>}
							onTouchTap={() => this.props.updateGroup('project')}
						/>
					</div>
				</div>
				<Updatable
					rowHeight={90}
					showSearchBar={false}
					user={this.props.user}
					selector={state => state.app.scenes.projectSummary}
					tableKey="projectSummary"
					data={this.props.records}
					schema={this.props.schema}
					{...this.props.schema}
					editable={false}
					selectable={false}
				/>
			</div>
			: <div>
				<CardHeader title="沒有存取權限"/>
				<CardActions>
					<RaisedButton label="回到主頁" primary={true} onClick={this.props.goTo} />
				</CardActions>
			</div>;
	}
}

export default class ProjectSummary extends PureComponent {

	render() {
		return (
			<Card style={styles.main} containerStyle={styles.mainContainer}>
				<CardTitle
					title="項目監察"
					subtitle="Project Summary"
				/>
				<CardText style={styles.table}>
					<ProjectSummaryTable />
				</CardText>
			</Card>
		);
	}
}
