const initState = '';

export default (state = initState, action) => {
	switch (action.type) {
	case 'SELECT_PROJECT':
		return action.payload.projectID;
	default:
		return state;
	}
};
