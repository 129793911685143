import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect }        from 'react-redux';
import { Map } from 'immutable';

import Updatable from 'commons/updatable';
import immutableSelector from 'utils/immutableSelector';
import schemas   from 'schemas';
import configs   from '../../configs';

import {
	GET_DATA,
	UPDATE_DATA,
	CREATE_DATA,
	REMOVE_DATA
} from '../actions';

import ProjectEnsured from './projectEnsurer';

const mapStateToProps = immutableSelector(
	state => state.app.user,
	(state, { projectSpecific, projectID, model }) => (
		projectSpecific
			? state.data.getIn(['projectDB', model, projectID])
			: state.data.getIn(['adminDB', model])
	),
	(state, { projectSpecific, projectID, model }) => (
		projectSpecific
			? !!state.data.getIn(['projectDB', 'loading', model, projectID])
			: !!state.data.getIn(['adminDB', 'loading', model])
	),
	(state, { projectSpecific }) => projectSpecific
		? 'project'
		: 'admin',
	(state, { projectSpecific, projectID }) => projectSpecific
		? state.data.getIn(['adminDB', 'projects', projectID, 'schemaVersion'])
		: configs.ADMIN_SCHEMA_VERSION,
	(user, data = new Map(), loading = false, schemaName, schemaVersion) => {
		return {
			user,
			data : data.sortBy(item => -new Date(item.get('createdAt') || 0)).toList().toJS(),
			loading,
			schemaName,
			schemaVersion
		};
	}
);

const mapDispatchtoProps = (dispatch, { projectID, model }) => {
	return {
		dispatch,
		updateData : (key, record) => dispatch(new UPDATE_DATA({ key, record, projectID, model })),
		createData : record => dispatch(new CREATE_DATA({ record, projectID, model })),
		removeData : key => dispatch(new REMOVE_DATA({ key, projectID, model })),
	};
};

@connect(mapStateToProps, mapDispatchtoProps)
class CollectionTable extends PureComponent {
	static propTypes = {
		interval      : PropTypes.number.isRequired,
		user          : PropTypes.object.isRequired,
		model         : PropTypes.string.isRequired,
		sceneName     : PropTypes.string.isRequired,
		projectID     : PropTypes.string,
		schemaName    : PropTypes.string.isRequired,
		schemaVersion : PropTypes.string.isRequired,
		loading       : PropTypes.bool.isRequired,
		data          : PropTypes.arrayOf(PropTypes.object),
		updateData    : PropTypes.func.isRequired,
		createData    : PropTypes.func.isRequired,
		removeData    : PropTypes.func.isRequired,
		getData       : PropTypes.func.isRequired
	}

	static defaultProps = {
		interval : 30000,
		getData : ({ projectID, dispatch, model }) => dispatch(new GET_DATA({ projectID, model }))
	}

	constructor(props) {
		super(props);
		this.props.getData(this.props);
		this.__timer = setInterval(() => {
			this.props.getData(this.props);
		}, this.props.interval);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.projectID != this.props.projectID)
			this.props.getData(nextProps);
	}

	componentWillUnmount() {
		clearInterval(this.__timer);
	}

	render() {
		let schema = schemas[this.props.schemaName][this.props.schemaVersion][this.props.model];
		return (
			<Updatable
				user={this.props.user}
				loading={this.props.loading}
				selector={state => state.app.scenes[this.props.sceneName]}
				tableKey={this.props.sceneName}
				data={this.props.data}
				{...schema}
				onUpdate={this.props.updateData}
				onCreate={this.props.createData}
				onRemove={this.props.removeData}
			/>
		);
	}
}

export default class MaybeEnsuredCollectionTable extends PureComponent {
	static propTypes = {
		projectSpecific : PropTypes.bool.isRequired
	}

	render() {
		let { projectSpecific } = this.props;
		let Table = projectSpecific ? ProjectEnsured(CollectionTable) : CollectionTable;
		return <Table {...this.props} />;
	}
}
