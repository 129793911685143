import { Map, fromJS } from 'immutable';

const initialState = new Map({
	loading : new Map({
		words : new Map(),
		issues : new Map(),
		followups : new Map(),
		attachments : new Map(),
		members : new Map(),
		templates : new Map(),
		tasks : new Map(),
	}),
	initialized : new Map({
		words : new Map(),
		issues : new Map(),
		followups : new Map(),
		attachments : new Map(),
		members : new Map(),
		templates : new Map(),
		tasks : new Map(),
	}),
	lastUpdated : new Map({
		words : new Map(),
		issues : new Map(),
		followups : new Map(),
		attachments : new Map(),
		members : new Map(),
		templates : new Map(),
		tasks : new Map(),
	}),
	words : new Map(),
	issues : new Map(),
	followups : new Map(),
	attachments : new Map(),
	members : new Map(),
	templates : new Map(),
	tasks : new Map()
});

export default (state = initialState, action) => {
	let { type, payload = {} } = action;
	let { model, projectID, key, models } = payload;
	if (type == 'GET_DATA') {
		return state
			.update('loading', loadingMap => models.reduce((result, { model, projectID }) => {
				if (!projectID)
					return result;
				return result.updateIn([model, projectID], 0, n => n+1);
			}, loadingMap));
	}
	if (type == 'GET_DATA_SUCCESS') {
		return state
			.mergeDeep(payload.data.projectData)
			.update('loading', loadingMap => models.reduce((result, { model, projectID }) => {
				if (!projectID)
					return result;
				return result.updateIn([model, projectID], 0, n => n-1);
			}, loadingMap))
			.update('initialized', initializedMap => models.reduce((result, { model, projectID }) => {
				if (!projectID)
					return result;
				return result.setIn([model, projectID], true);
			}, initializedMap))
			.update('lastUpdated', lastUpdatedMap => models.reduce((result, { model, projectID }) => {
				if (!projectID)
					return result;
				return result.setIn([model, projectID], payload.lastUpdated);
			}, lastUpdatedMap));
	}
	if (!projectID)
		return state;
	switch (type) {
	case 'CREATE_DATA':
	case 'UPDATE_DATA':
	case 'REMOVE_DATA':
		return state.updateIn(['loading', model, projectID], 0, n => n+1);
	case 'UPDATE_DATA_SUCCESS':
	case 'CREATE_DATA_SUCCESS':
		return state
			.updateIn([model, projectID], new Map(), model => model.set(key, fromJS(payload.doc)))
			.updateIn(['loading', model, projectID], 0,  n => n-1);
	case 'REMOVE_DATA_SUCCESS':
		return state
			.setIn([model, projectID, key, 'deleted'], true)
			.updateIn(['loading', model, projectID], 0, n => n-1);
	case 'GET_DATA_FAILURE':
	case 'CREATE_DATA_FAILURE':
	case 'UPDATE_DATA_FAILURE':
	case 'REMOVE_DATA_FAILURE':
		return state.updateIn(['loading', model, projectID], 0,  n => n-1);
	default:
		return state;
	}
};
