function actionCreatorBuilder(type, argNames) {
	return (...args) => ({
		type,
		payload :  argNames && argNames.length
			? args.reduce((result, arg, i) => ({ ...result, [argNames[i]] : arg }), {})
			: args[0]
	});
}

export const UPDATE_CRITERIA = 'UPDATE_CRITERIA';
export const updateCriteria = actionCreatorBuilder(UPDATE_CRITERIA, ['criteria', 'tableKey']);

export const CLICK_AWAY = 'CLICK_AWAY';
export const clickAway = actionCreatorBuilder(CLICK_AWAY, ['tableKey']);

// export const COPY_CELL = 'COPY_CELL';
// export const copyCell = actionCreatorBuilder(COPY_CELL, ['data', 'tableKey']);

export const PASTE_TO_CELL = 'PASTE_TO_CELL';
export const pasteToCell = actionCreatorBuilder(PASTE_TO_CELL, ['key', 'value', 'tableKey']);

export const ADD_RECORD = 'ADD_RECORD';
export const addRecord = actionCreatorBuilder(ADD_RECORD, ['key', 'newRecord', 'tableKey']);

export const REMOVE_RECORD = 'REMOVE_RECORD';
export const removeRecord = actionCreatorBuilder(REMOVE_RECORD, ['key', 'tableKey']);

export const SELECT_CELL = 'SELECT_CELL';
export const selectCell = actionCreatorBuilder(SELECT_CELL, ['cellID', 'tableKey']);

export const EDIT_CELL = 'EDIT_CELL';
export const editCell = actionCreatorBuilder(EDIT_CELL, ['cellID', 'errMsg', 'tableKey']);

export const UPDATE_RECORD = 'UPDATE_RECORD';
export const updateRecord = actionCreatorBuilder(UPDATE_RECORD, ['key', 'record', 'tableKey']);

export const COMMIT_CHANGES = 'COMMIT_CHANGES';
export const commitChanges = actionCreatorBuilder(COMMIT_CHANGES, ['key', 'tableKey']);

export const UPDATE_COLUMN_WIDTH = 'UPDATE_COLUMN_WIDTH';
export const updateColumnWidth = actionCreatorBuilder(UPDATE_COLUMN_WIDTH, ['key', 'width', 'tableKey']);

export const UPDATE_HEIGHT = 'UPDATE_HEIGHT';
export const updateHeight = actionCreatorBuilder(UPDATE_HEIGHT, ['rowIndex', 'height', 'tableKey']);
