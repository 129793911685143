import { combineReducers } from 'redux';
import reduce from 'lodash/reduce';

import configs from '../../../configs';
import { createReducer } from 'commons/updatable';

let sceneReducers = reduce(configs.MANAGEMENT_SCENES, (result, sceneName) => {
	return { ...result, [sceneName] : createReducer(sceneName) };
}, {});

export default combineReducers({
	...sceneReducers
});
