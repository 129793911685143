import { StyleSheet } from 'aphrodite-jss';

export default StyleSheet.create({
	icon : {
		padding : '0',
		height : 'auto',
		width : 'auto'
	},
	cell : {
		height : '100%'
	},
	innerCtn : {
		width : '100%',
		display : 'flex',
		flexDirection : 'row',
		alignItems : 'center',
		justifyContent : 'flex-start',
	}
});
