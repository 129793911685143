import { select, call, takeLatest } from 'redux-saga/effects';
import callAPI from './callAPI';
import catchError from './catchError';
import { saveBlob, base64toBlob, arrayBuffertoBlob } from 'utils/fileUploadHelper';

function* exportReportService(action) {
	let { payload = {} } = action;
	let { projectID, template } = payload;
	let filename = yield select(state => state.ui.reportExport.getIn([ projectID, template.get('_id'), 'filename']));
	let extraFields = yield select(state => state.ui.reportExport.getIn([projectID, template.get('_id'), 'extraFields']));
	extraFields = extraFields ? extraFields.toJS() : {};
	let projectName = yield select(state => state.data.getIn(['adminDB', 'projects', projectID, 'name']));

	filename = filename ? filename : `${projectName}-${template.get('name')}-${new Date().toISOString().substring(0, 10)}`;
	let url = `/api/projects/${projectID}/templates/${template.get('_id')}/report`;
	let response = yield call(callAPI, 'POST', url, null, { extraFields });
	// let reportPath = `${configs.REPORT_DIR}/${projectName}/${filename}.${}`;
	let blob;
	if (response instanceof ArrayBuffer) {
		blob = yield call(arrayBuffertoBlob, response);
	} else {
		let { data } = response;
		blob = yield call(base64toBlob, data);
	}
	yield call(saveBlob, blob, `${filename}.${template.get('exportExtension')}`);
}

export default function* exportReportSaga() {
	yield takeLatest('EXPORT_REPORT', catchError(exportReportService, true));
}
