const initState = window.location.pathname.substring(1);

export default (state = initState, action) => {
	let { payload } = action;
	switch (action.type) {
	case 'GO_TO':
		return payload.path;
	default:
		return state;
	}
};
