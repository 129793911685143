import members     from './members';
import tasks       from './tasks';
import words       from './words';
import reports     from './reports';
import issues      from './issues';
import followups   from './followups';
import attachments from './attachments';
import templates   from './templates';

export default {
	members,
	tasks,
	words,
	reports,
	issues,
	followups,
	attachments,
	templates
};
